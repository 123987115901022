import React, { useContext } from 'react';
import styles from './termos-politicas.module.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { LocalizeContext } from '../localizeContext';

const politicasTexts = {
    section_title: {
        pt: `POLÍTICA DE PRIVACIDADE`,
        en: `PRIVACY POLICY`,
        es: `POLÍTICA DE PRIVACIDAD`
      },
      blockt1: {
        pt: `SEÇÃO 1 - INFORMAÇÕES GERAIS`,
        en: `SECTION 1 - GENERAL INFORMATION`,
        es: `SECCIÓN 1 - INFORMACIÓN GENERAL`
      },
      blockp1: {
        pt: `A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e visitantes do site ou aplicativo.`,
        en: `This Privacy Policy contains information about the collection, use, storage, processing, and protection of personal data of users and visitors of the website or application.`,
        es: `Esta Política de Privacidad contiene información sobre la recopilación, uso, almacenamiento, procesamiento y protección de los datos personales de los usuarios y visitantes del sitio web o aplicación.`
      },
      blockp2: {
        pt: `O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser atualizado em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.`,
        en: `This document has been prepared in accordance with the General Data Protection Law (Law 13.709/18), the Internet Civil Rights Framework (Law 12.965/14), and EU Regulation No. 2016/679. Furthermore, this document may be updated due to regulatory changes, so users are invited to periodically review this section.`,
        es: `Este documento ha sido elaborado de acuerdo con la Ley General de Protección de Datos Personales (Ley 13.709/18), el Marco Civil de Internet (Ley 12.965/14) y el Reglamento de la UE N.º 2016/679. Además, este documento puede actualizarse debido a cambios normativos, por lo que se invita a los usuarios a revisar periódicamente esta sección.`
      },
      blockt2: {
        pt: `SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE?`,
        en: `SECTION 2 - HOW DO WE COLLECT PERSONAL DATA FROM THE USER AND VISITOR?`,
        es: `SECCIÓN 2 - ¿CÓMO RECOLECTAMOS DATOS PERSONALES DEL USUARIO Y VISITANTE?`
      },
      blockp3: {
        pt: `Os dados pessoais do usuário e visitante são recolhidos pela plataforma da seguinte forma:`,
        en: `The personal data of the user and visitor are collected by the platform in the following ways:`,
        es: `Los datos personales del usuario y visitante son recopilados por la plataforma de la siguiente manera:`
      },
      blockp4: {
        pt: `a) Quando o usuário criar uma conta/perfil na plataforma: esses dados são os dados de identificação básicos, como e-mail, nome completo, cidade de residência e profissão. A partir deles, podemos identificar o usuário e o visitante, além de garantir uma maior segurança e bem-estar às suas necessidades. Ficam cientes os usuários e visitantes de que seu perfil na plataforma estará acessível a todos demais usuários e visitantes da plataforma.`,
        en: `a) When the user creates an account/profile on the platform: these are basic identification data such as email, full name, city of residence, and profession. From these, we can identify the user and the visitor, as well as ensure greater security and well-being for their needs. Users and visitors are aware that their profile on the platform will be accessible to all other users and visitors of the platform.`,
        es: `a) Cuando el usuario crea una cuenta/perfil en la plataforma: estos son datos básicos de identificación como correo electrónico, nombre completo, ciudad de residencia y profesión. A partir de estos datos, podemos identificar al usuario y al visitante, además de garantizar una mayor seguridad y bienestar para sus necesidades. Los usuarios y visitantes son conscientes de que su perfil en la plataforma estará accesible para todos los demás usuarios y visitantes de la plataforma.`
      },
      blockp5: {
        pt: `b) Quando um usuário e visitante acessar o site ou o aplicativo: as informações sobre interação e acesso são coletadas pela empresa para garantir uma melhor experiência ao usuário e visitante. Estes dados podem tratar sobre as palavras-chaves utilizadas em uma busca, o compartilhamento de um documento específico, comentários, visualizações de páginas, perfis, a URL de onde o usuário e visitante provêm, o navegador que utilizam e seus IPs de acesso, dentre outras que poderão ser armazenadas e retidas.`,
        en: `b) When a user and visitor access the website or the application: information about interaction and access is collected by the company to ensure a better experience for the user and visitor. These data may include keywords used in a search, sharing of a specific document, comments, page views, profiles, the URL from which the user and visitor came, the browser they use, and their IP addresses, among others that may be stored and retained.`,
        es: `b) Cuando un usuario y visitante acceden al sitio web o la aplicación: la empresa recopila información sobre la interacción y el acceso para garantizar una mejor experiencia al usuario y visitante. Estos datos pueden incluir palabras clave utilizadas en una búsqueda, compartir un documento específico, comentarios, vistas de páginas, perfiles, la URL desde la que provienen el usuario y el visitante, el navegador que utilizan y sus direcciones IP, entre otros datos que pueden almacenarse y conservarse.`
      },
      blockp6: {
        pt: `c) Por intermédio de terceiro: a plataforma recebe dados de terceiros, como, por exemplo, Facebook e Google, quando um usuário faz login com o seu perfil de um desses sites. A utilização desses dados é autorizada previamente pelos usuários junto ao terceiro em questão.`,
        en: `c) Through third parties: the platform receives data from third parties, such as Facebook and Google, when a user logs in with their profile from one of these sites. The use of this data is authorized by users in advance with the respective third party.`,
        es: `c) A través de terceros: la plataforma recibe datos de terceros, como Facebook y Google, cuando un usuario inicia sesión con su perfil en uno de estos sitios. El uso de estos datos está autorizado previamente por los usuarios con el tercer tercero correspondiente.`
      },
      blockt3: {
        pt: `SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE?`,
        en: `SECTION 3 - WHAT PERSONAL DATA DO WE COLLECT ABOUT THE USER AND VISITOR?`,
        es: `SECCIÓN 3 - ¿QUÉ DATOS PERSONALES RECOLECTAMOS SOBRE EL USUARIO Y EL VISITANTE?`
      },
      blockp7: {
        pt: `Os dados pessoais do usuário e visitante recolhidos são os seguintes:`,
        en: `The personal data of the user and visitor collected are as follows:`,
        es: `Los datos personales del usuario y visitante recopilados son los siguientes:`
      },
      blockp8: {
        pt: `a) Dados para a criação da conta/perfil na plataforma: e-mail, nome completo, cidade de residência e profissão.`,
        en: `a) Data for creating an account/profile on the platform: email, full name, city of residence, and profession.`,
        es: `a) Datos para crear una cuenta/perfil en la plataforma: correo electrónico, nombre completo, ciudad de residencia y profesión.`
      },
      blockp9: {
        pt: `b) Dados para otimização da navegação: acesso a páginas, palavras-chave utilizadas na busca, recomendações, comentários, interação com outros perfis e usuários, perfis seguidos, endereço de IP.`,
        en: `b) Data for optimizing navigation: access to pages, keywords used in searches, recommendations, comments, interaction with other profiles and users, followed profiles, IP address.`,
        es: `b) Datos para optimizar la navegación: acceso a páginas, palabras clave utilizadas en búsquedas, recomendaciones, comentarios, interacción con otros perfiles y usuarios, perfiles seguidos, dirección IP.`
      },
      blockp10: {
        pt: `c) Dados para concretizar transações: dados referentes ao pagamento e transações, tais como, número do cartão de crédito e outras informações sobre o cartão, além dos pagamentos efetuados.`,
        en: `c) Data to complete transactions: data related to payments and transactions, such as credit card number and other card information, as well as payments made.`,
        es: `c) Datos para completar transacciones: datos relacionados con pagos y transacciones, como el número de tarjeta de crédito y otra información de la tarjeta, así como los pagos realizados.`
      },
      blockp11: {
        pt: `d) Newsletter: o e-mail cadastrado pelo visitante que optar por se inscrever na Newsletter será coletado e armazenado até que o usuário solicite o descadastro.`,
        en: `d) Newsletter: the email registered by the visitor who chooses to subscribe to the Newsletter will be collected and stored until the user requests to unsubscribe.`,
        es: `d) Boletín informativo: el correo electrónico registrado por el visitante que elige suscribirse al boletín informativo se recopilará y almacenará hasta que el usuario solicite darse de baja.`
      },
      blockp12: {
        pt: `e) Dados sensíveis: a plataforma poderá coletar os seguintes dados sensíveis do usuário: origem étnica ou racial, opinião política, convicção religiosa, dados genéticos, dados relativos à saúde, orientação sexual).`,
        en: `e) Sensitive data: the platform may collect the following sensitive data from the user: ethnic or racial origin, political opinion, religious belief, genetic data, health data, sexual orientation).`,
        es: `e) Datos sensibles: la plataforma puede recopilar los siguientes datos sensibles del usuario: origen étnico o racial, opinión política, creencias religiosas, datos genéticos, datos de salud, orientación sexual).`
      },
      blockp13: {
        pt: `f) Dados relacionados a contratos: diante da formalização do contrato de compra e venda ou de prestação de serviços entre a plataforma e o usuário e visitante poderão ser coletados e armazenados dados relativos à execução contratual, inclusive as comunicações realizada entre a empresa e o usuário.`,
        en: `f) Data related to contracts: upon formalizing the purchase and sale contract or provision of services between the platform and the user and visitor, data related to the contractual performance may be collected and stored, including communications made between the company and the user.`,
        es: `f) Datos relacionados con contratos: al formalizar el contrato de compra y venta o la prestación de servicios entre la plataforma y el usuario y visitante, se pueden recopilar y almacenar datos relacionados con el cumplimiento contractual, incluidas las comunicaciones realizadas entre la empresa y el usuario.`
      },
      blockt4: {
        pt: `SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO E VISITANTE?`,
        en: `SECTION 4 - FOR WHAT PURPOSES DO WE USE THE PERSONAL DATA OF THE USER AND VISITOR?`,
        es: `SECCIÓN 4 - ¿PARA QUÉ FINES UTILIZAMOS LOS DATOS PERSONALES DEL USUARIO Y VISITANTE?`
      },
      blockp14: {
        pt: `Os dados pessoais do usuário e do visitante coletados e armazenados pela plataforma, tem por finalidade:`,
        en: `The personal data of the user and visitor collected and stored by the platform are used for the following purposes:`,
        es: `Los datos personales del usuario y visitante recopilados y almacenados por la plataforma se utilizan con los siguientes fines:`
      },
      blockp15: {
        pt: `a) Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a empresa, melhorar a experiência dos usuários e fornecer funcionalidades específicas a depender das características básicas do usuário.`,
        en: `a) User and visitor well-being: improve the product and/or service offered, facilitate, streamline, and fulfill the commitments established between the user and the company, enhance user experience, and provide specific functionalities depending on the user's basic characteristics.`,
        es: `a) Bienestar del usuario y visitante: mejorar el producto y/o servicio ofrecido, facilitar, agilizar y cumplir con los compromisos establecidos entre el usuario y la empresa, mejorar la experiencia del usuario y proporcionar funcionalidades específicas según las características básicas del usuario.`
      },
      blockp16: {
        pt: `b) Melhorias da plataforma: compreender como o usuário utiliza os serviços da plataforma, para ajudar no desenvolvimento de negócios e técnicas.`,
        en: `b) Platform improvements: understand how the user uses the platform's services to assist in business and technical development.`,
        es: `b) Mejoras en la plataforma: comprender cómo el usuario utiliza los servicios de la plataforma para ayudar en el desarrollo empresarial y técnico.`
      },
      blockp17: {
        pt: `c) Anúncios: apresentar anúncios personalizados para o usuário com base nos dados fornecidos.`,
        en: `c) Ads: present personalized ads to the user based on the provided data.`,
        es: `c) Anuncios: presentar anuncios personalizados al usuario basados en los datos proporcionados.`
      },
      blockp18: {
        pt: `d) Comercial: os dados são usados para personalizar o conteúdo oferecido e gerar subsídio à plataforma para a melhora da qualidade no funcionamento dos serviços.`,
        en: `d) Commercial: data is used to personalize the content offered and provide support to the platform for improving the quality of service operation.`,
        es: `d) Comercial: se utilizan datos para personalizar el contenido ofrecido y proporcionar apoyo a la plataforma para mejorar la calidad del funcionamiento del servicio.`
      },
      blockp19: {
        pt: `e) Previsão do perfil do usuário: tratamento automatizado de dados pessoais para avaliar o uso na plataforma.`,
        en: `e) User profile prediction: automated processing of personal data to assess usage on the platform.`,
        es: `e) Predicción del perfil del usuario: procesamiento automatizado de datos personales para evaluar el uso en la plataforma.`
      },
      blockp20: {
        pt: `f) Dados de cadastro: para permitir o acesso do usuário a determinados conteúdos da plataforma, exclusivo para usuários cadastrados.`,
        en: `f) Registration data: to allow user access to certain platform content, exclusive to registered users.`,
        es: `f) Datos de registro: permitir el acceso del usuario a ciertos contenidos de la plataforma, exclusivo para usuarios registrados.`
      },
      blockp21: {
        pt: `g) Dados de contrato: conferir às partes segurança jurídica e facilitar a conclusão do negócio.`,
        en: `g) Contract data: provide legal security to the parties and facilitate the completion of the transaction.`,
        es: `g) Datos del contrato: proporcionar seguridad jurídica a las partes y facilitar la finalización de la transacción.`
      },
      blockp22: {
        pt: `O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis.`,
        en: `The processing of personal data for purposes not provided for in this Privacy Policy will only occur after prior communication to the user, so the rights and obligations provided herein remain applicable.`,
        es: `El procesamiento de datos personales con fines no previstos en esta Política de Privacidad solo se llevará a cabo después de una comunicación previa al usuario, por lo que los derechos y obligaciones establecidos en este documento siguen siendo aplicables.`
      },
      blockt5: {
        pt: `SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?`,
        en: `SECTION 5 - HOW LONG ARE PERSONAL DATA STORED?`,
        es: `SECCIÓN 5 - ¿CUÁNTO TIEMPO SE ALMACENAN LOS DATOS PERSONALES?`
      },
      blockp23: {
        pt: `Os dados pessoais do usuário e visitante são armazenados pela plataforma durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.`,
        en: `The personal data of the user and visitor are stored by the platform for the period necessary to provide the service or fulfill the purposes set forth in this document, as provided for in item I of article 15 of Law 13.709/18.`,
        es: `Los datos personales del usuario y visitante se almacenan en la plataforma durante el período necesario para proporcionar el servicio o cumplir con los fines establecidos en este documento, según lo dispuesto en el inciso I del artículo 15 de la Ley 13.709/18.`
      },
      blockp24: {
        pt: `Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei oferecer outro tratamento.`,
        en: `Data can be removed or anonymized at the user's request, except in cases where the law provides for another treatment.`,
        es: `Los datos pueden ser eliminados o anonimizados a pedido del usuario, excepto en los casos en que la ley disponga otro tratamiento.`
      },
      blockp25: {
        pt: `Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:`,
        en: `Furthermore, users' personal data can only be retained after the end of their processing in the following situations provided for in article 16 of the mentioned law:`,
        es: `Además, los datos personales de los usuarios solo pueden conservarse después del final de su procesamiento en las siguientes situaciones previstas en el artículo 16 de la ley mencionada:`
      },
      blockp26: {
        pt: `I - cumprimento de obrigação legal ou regulatória pelo controlador;`,
        en: `I - compliance with a legal or regulatory obligation by the controller;`,
        es: `I - cumplimiento de una obligación legal o reglamentaria por parte del responsable;`
      },
      blockp27: {
        pt: `II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;`,
        en: `II - study by a research body, with personal data anonymization whenever possible;`,
        es: `II - estudio por parte de un organismo de investigación, con la anonimización de los datos personales siempre que sea posible;`
      },
      blockp28: {
        pt: `III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;`,
        en: `III - transfer to a third party, provided that the data processing requirements set out in this Law are respected;`,
        es: `III - transferencia a un tercero, siempre que se respeten los requisitos de tratamiento de datos establecidos en esta Ley;`
      },
      blockp29: {
        pt: `IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.`,
        en: `IV - exclusive use by the controller, with no access by third parties, and with data anonymization.`,
        es: `IV - uso exclusivo por parte del responsable, sin acceso de terceros y con anonimización de datos.`
      },
      blockt6: {
        pt: `SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS`,
        en: `SECTION 6 - SECURITY OF STORED PERSONAL DATA`,
        es: `SECCIÓN 6 - SEGURIDAD DE LOS DATOS PERSONALES ALMACENADOS`
      },
      blockp30: {
        pt: `A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.`,
        en: `The platform commits to applying technical and organizational measures capable of protecting personal data from unauthorized access and situations of destruction, loss, alteration, communication, or dissemination of such data.`,
        es: `La plataforma se compromete a aplicar medidas técnicas y organizativas adecuadas para proteger los datos personales contra accesos no autorizados y situaciones de destrucción, pérdida, alteración, comunicación o difusión de dichos datos.`
      },
      blockp31: {
        pt: `Os dados relativos a cartões de crédito são criptografados usando a tecnologia "secure socket layer" (SSL) que garante a transmissão de dados de forma segura e confidencial, de modo que a transmissão dos dados entre o servidor e o usuário ocorre de maneira cifrada e encriptada.`,
        en: `Credit card data is encrypted using "secure socket layer" (SSL) technology, ensuring the secure and confidential transmission of data, so data transmission between the server and the user occurs in an encrypted and encrypted manner.`,
        es: `Los datos de tarjetas de crédito se cifran utilizando la tecnología "secure socket layer" (SSL), lo que garantiza la transmisión segura y confidencial de los datos, de modo que la transmisión de datos entre el servidor y el usuario se realiza de manera cifrada y encriptada.`
      },
      blockp32: {
        pt: `A plataforma não se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o usuário em caso de alguma violação de segurança dos seus dados pessoais.`,
        en: `The platform does not exempt itself from responsibility for the exclusive fault of third parties, as in the case of hacker or cracker attacks, or the exclusive fault of the user, as in the case where the user transfers their data to third parties. The website commits to informing the user in case of any breach of the security of their personal data.`,
        es: `La plataforma no se exime de responsabilidad por culpa exclusiva de terceros, como en caso de ataques de hackers o crackers, o por culpa exclusiva del usuario, como en el caso en que el usuario transfiera sus datos a terceros. El sitio web se compromete a informar al usuario en caso de cualquier violación de la seguridad de sus datos personales.`
      },
      blockp33: {
        pt: `Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo.`,
        en: `Stored personal data is treated with confidentiality, within legal limits. However, we may disclose your personal information if required by law.`,
        es: `Los datos personales almacenados se tratan con confidencialidad, dentro de los límites legales. Sin embargo, podemos divulgar su información personal si la ley lo requiere.`
      },
      blockt7: {
        pt: `SEÇÃO 7 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?`,
        en: `SECTION 7 - WILL STORED PERSONAL DATA BE TRANSFERRED TO THIRD PARTIES?`,
        es: `SECCIÓN 7 - ¿SE TRANSFERIRÁN DATOS PERSONALES ALMACENADOS A TERCEROS?`
      },
      blockp34: {
        pt: `Os dados pessoais podem ser compartilhados com terceiros, que recebem os dados na medida do necessário para permitir que estes realizem os serviços contratados.`,
        en: `Personal data may be shared with third parties, who receive the data to the extent necessary to enable them to perform the contracted services.`,
        es: `Los datos personales pueden compartirse con terceros, quienes reciben los datos en la medida necesaria para permitirles realizar los servicios contratados.`
      },
      blockp35: {
        pt: `Com relação aos fornecedores de serviços terceirizados como processadores de transação de pagamento, informamos que cada qual tem sua própria política de privacidade. Desse modo, recomendamos a leitura das suas políticas de privacidade para compreensão de quais informações pessoais serão usadas por esses fornecedores.`,
        en: `Regarding third-party service providers such as payment transaction processors, we inform you that each one has its own privacy policy. Therefore, we recommend reading their privacy policies to understand what personal information will be used by these providers.`,
        es: `En cuanto a los proveedores de servicios de terceros, como procesadores de transacciones de pago, le informamos que cada uno tiene su propia política de privacidad. Por lo tanto, recomendamos leer sus políticas de privacidad para comprender qué información personal utilizarán estos proveedores.`
      },
      blockt8: {
        pt: `SEÇÃO 8 – COOKIES OU DADOS DE NAVEGAÇÃO`,
        en: `SECTION 8 - COOKIES OR BROWSING DATA`,
        es: `SECCIÓN 8 - COOKIES O DATOS DE NAVEGACIÓN`
      },
      blockt9: {
        pt: `SEÇÃO 9 - CONSENTIMENTO`,
        en: `SECTION 9 - CONSENT`,
        es: `SECCIÓN 9 - CONSENTIMIENTO`
      },
      blockp36: {
        pt: `Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário e visitante e que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do usuário e visitante para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da plataforma.`,
        en: `Cookies refer to text files sent by the platform to the user and visitor's computer and stored on it, with information related to browsing on the site. Such information is related to access data such as location and access time and is stored by the user and visitor's browser so that the platform's server can read them later to personalize the platform's services.`,
        es: `Las cookies se refieren a archivos de texto enviados por la plataforma a la computadora del usuario y visitante y almacenados en ella, con información relacionada con la navegación en el sitio. Esta información está relacionada con los datos de acceso, como la ubicación y la hora de acceso, y es almacenada por el navegador del usuario y visitante para que el servidor de la plataforma pueda leerla más tarde y personalizar los servicios de la plataforma.`
      },
      blockp37: {
        pt: `O usuário e o visitante da plataforma manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante à utilização de cookies.`,
        en: `The user and visitor of the platform acknowledge and accept that a data collection system through the use of cookies may be used.`,
        es: `El usuario y visitante de la plataforma reconoce y acepta que se puede utilizar un sistema de recopilación de datos mediante el uso de cookies.`
      },
      blockp38: {
        pt: `O cookie persistente permanece no disco rígido do usuário e visitante depois que o navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.`,
        en: `The persistent cookie remains on the user and visitor's hard drive after the browser is closed and will be used by the browser on subsequent visits to the site. Persistent cookies can be removed following your browser's instructions. Session cookies, on the other hand, are temporary and disappear after the browser is closed. It is possible to reset your web browser to reject all cookies, but some features of the platform may not work properly if cookie acceptance is disabled.`,
        es: `La cookie persistente permanece en el disco duro del usuario y visitante después de que se cierra el navegador y será utilizada por el navegador en visitas posteriores al sitio. Las cookies persistentes se pueden eliminar siguiendo las instrucciones de su navegador. Las cookies de sesión, por otro lado, son temporales y desaparecen después de que se cierra el navegador. Es posible restablecer su navegador web para rechazar todas las cookies, pero algunas funciones de la plataforma pueden no funcionar correctamente si se deshabilita la aceptación de cookies.`
      },
      blockp39: {
        pt: `Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está consentindo com a presente Política de Privacidade. O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas.`,
        en: `By using the services and providing personal information on the platform, the user is consenting to this Privacy Policy. The user, when registering, acknowledges and can exercise their rights to cancel their registration, access, and update their personal data, and guarantees the accuracy of the information provided by them.`,
        es: `Al utilizar los servicios y proporcionar información personal en la plataforma, el usuario está dando su consentimiento a esta Política de Privacidad. El usuario, al registrarse, reconoce y puede ejercer sus derechos de cancelar su registro, acceder y actualizar sus datos personales, y garantiza la veracidad de la información proporcionada por él.`
      },
      blockp40: {
        pt: `O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em contato através do e-mail comercial@romper.com.br ou por correio enviado ao seguinte endereço: R. Maringá, 2076 - São Cristóvão, Cascavel - PR, 85816-000.`,
        en: `The user has the right to withdraw their consent at any time, for this purpose, they must contact us via email at comercial@romper.com.br or by mail sent to the following address: R. Maringá, 2076 - São Cristóvão, Cascavel - PR, 85816-000.`,
        es: `El usuario tiene derecho a retirar su consentimiento en cualquier momento, para ello debe ponerse en contacto a través del correo electrónico comercial@romper.com.br o por correo enviado a la siguiente dirección: R. Maringá, 2076 - São Cristóvão, Cascavel - PR, 85816-000.`
      },
      blockt10: {
        pt: `SEÇÃO 10 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE`,
        en: `SECTION 10 - CHANGES TO THIS PRIVACY POLICY`,
        es: `SECCIÓN 10 - CAMBIOS EN ESTA POLÍTICA DE PRIVACIDAD`
      },
      blockp41: {
        pt: `Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é recomendável que o usuário e visitante a revise com frequência.`,
        en: `We reserve the right to modify this Privacy Policy at any time, so it is recommended that the user and visitor review it frequently.`,
        es: `Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento, por lo que se recomienda que el usuario y visitante la revise con frecuencia.`
      },
      blockp42: {
        pt: `As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na plataforma. Quando realizadas alterações, os usuários serão notificados. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o usuário e visitante demonstram sua concordância com as novas normas.`,
        en: `Changes and clarifications will take effect immediately upon their publication on the platform. Users will be notified of any changes. By using the service or providing personal information after any modifications, the user and visitor demonstrate their agreement with the new rules.`,
        es: `Los cambios y aclaraciones entrarán en vigencia inmediatamente después de su publicación en la plataforma. Los usuarios serán notificados de cualquier cambio. Al utilizar el servicio o proporcionar información personal después de cualquier modificación, el usuario y visitante demuestran su acuerdo con las nuevas normas.`
      },
      blockt11: {
        pt: `SEÇÃO 11 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS`,
        en: `SECTION 11 - JURISDICTION FOR DISPUTE RESOLUTION`,
        es: `SECCIÓN 11 - JURISDICCIÓN PARA LA RESOLUCIÓN DE DISPUTAS`
      },
      blockp43: {
        pt: `Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro. Outrossim, os eventuais litígios deverão ser apresentados no foro da comarca de Cascavel/PR.`,
        en: `For the resolution of disputes arising from this instrument, Brazilian law will be fully applied. Furthermore, any disputes should be submitted to the court of Cascavel/PR.`,
        es: `Para la resolución de disputas derivadas de este instrumento, se aplicará plenamente la ley brasileña. Además, cualquier disputa debe presentarse ante el tribunal de Cascavel/PR.`
      }    
};

const PoliticaDePrivacidade = () => {
  const { language } = useContext(LocalizeContext);

  return (
    <div className={styles['container']}>
        <Header TipoHeader={1} />
        <div className={styles['conteudo']}>

            <div className={styles['row']}>
                <div className={styles['text-center']}>
                    <h2 className={styles['section_title']}>{politicasTexts['section_title'][language]}</h2>
                </div>
            </div>

            <div className={styles['row']}>

                <div className={styles['col-12']}>

                    <div className={styles['block']}>

                        <h3>{politicasTexts['blockt1'][language]}</h3>
                        <p>{politicasTexts['blockp1'][language]}</p>
                        <p>{politicasTexts['blockp2'][language]}</p>

                        <h3>{politicasTexts['blockt2'][language]}</h3>
                        <p>{politicasTexts['blockp3'][language]}</p>
                        <p>{politicasTexts['blockp4'][language]}</p>

                        <p>{politicasTexts['blockp5'][language]}</p>

                        <p>{politicasTexts['blockp6'][language]}</p>

                        <h3>{politicasTexts['blockt3'][language]}</h3>
                        <p>{politicasTexts['blockp7'][language]}</p>

                        <p>{politicasTexts['blockp8'][language]}</p>
                        <p>{politicasTexts['blockp9'][language]}</p>
                        <p>{politicasTexts['blockp10'][language]}</p>
                        <p>{politicasTexts['blockp11'][language]}</p>
                        <p>{politicasTexts['blockp12'][language]}</p>
                        <p>{politicasTexts['blockp13'][language]}</p>

                        <h3>{politicasTexts['blockt4'][language]}</h3>

                        <p>{politicasTexts['blockp14'][language]}</p>
                        <p>{politicasTexts['blockp15'][language]}</p>
                        <p>{politicasTexts['blockp16'][language]}</p>
                        <p>{politicasTexts['blockp17'][language]}</p>
                        <p>{politicasTexts['blockp18'][language]}</p>
                        <p>{politicasTexts['blockp19'][language]}</p>
                        <p>{politicasTexts['blockp20'][language]}</p>
                        <p>{politicasTexts['blockp21'][language]}</p>
                        <p>{politicasTexts['blockp22'][language]}</p>

                        <h3>{politicasTexts['blockt5'][language]}</h3>
                        <p>{politicasTexts['blockp23'][language]}</p>
                        <p>{politicasTexts['blockp24'][language]}</p>
                        <p>{politicasTexts['blockp25'][language]}</p>
                        <p>{politicasTexts['blockp26'][language]}</p>
                        <p>{politicasTexts['blockp27'][language]}</p>
                        <p>{politicasTexts['blockp28'][language]}</p>
                        <p>{politicasTexts['blockp29'][language]}</p>

                        <h3>{politicasTexts['blockt6'][language]}</h3>
                        <p>{politicasTexts['blockp30'][language]}</p>
                        <p>{politicasTexts['blockp31'][language]}</p>
                        <p>{politicasTexts['blockp32'][language]}</p>
                        <p>{politicasTexts['blockp33'][language]}</p>

                        <h3>{politicasTexts['blockt7'][language]}</h3>
                        <p>{politicasTexts['blockp34'][language]}</p>
                        <p>{politicasTexts['blockp35'][language]}</p>

                        <h3>{politicasTexts['blockt8'][language]}</h3>
                        <p>{politicasTexts['blockp36'][language]}</p>
                        <p>{politicasTexts['blockp37'][language]}</p>
                        <p>{politicasTexts['blockp38'][language]}</p>

                        <h3>{politicasTexts['blockt9'][language]}</h3>
                        <p>{politicasTexts['blockp39'][language]}</p>
                        <p>{politicasTexts['blockp40'][language]}</p>

                        <h3>{politicasTexts['blockt10'][language]}</h3>
                        <p>{politicasTexts['blockp41'][language]}</p>
                        <p>{politicasTexts['blockp42'][language]}</p>

                        <h3>{politicasTexts['blockt11'][language]}</h3>
                        <p>{politicasTexts['blockp43'][language]}</p>


                    </div>

                </div>

            </div>

        </div>
        <Footer />
    </div>
  );
};

export default PoliticaDePrivacidade;
