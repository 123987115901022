import React, { useContext } from 'react'
import { LocalizeContext } from '../localizeContext';

import { Link } from 'react-router-dom'
import styles from './footer.module.css'

const routesLang = {
  'canais-atendimento': {
    pt:'/canais-atendimento',
    es:'/canales-de-atencion',
    en:'/customer-service-channels'
  },
  'sobre-nos': {
    pt:'/sobre-nos',
    es:'/sobre-nosotros',
    en:'/about-us'
  },
  'termos-de-uso': {
    pt:'/termos-de-uso',
    es:'/terminos-de-uso',
    en:'/terms-of-use'
  },
  'politica-de-privacidade': {
    pt:'/politica-de-privacidade',
    es:'/politica-de-privacidad',
    en:'/privacy-policy'
  },
  'romper-mkt-avancado': {
    pt:'/romper-mkt-avancado',
    es:'/romper-marketing-avanzado',
    en:'/romper-advanced-marketing'
  },
  'compliance': {
    pt:'/compliance',
    es:'/cumplimiento',
    en:'/compliance'
  },
}

const footerTexts = {
  'botao-nossas-marcas': {
    pt: `Nossas marcas`,
    en: `Our brands`,
    es: `Nuestras marcas`
  },
  'botao-a-romper': {
    pt: `A Romper`,
    en: `About Romper`,
    es: `Acerca de Romper`
  },
  'botao-compliance': {
    pt: `Compliance`,
    en: `Compliance`,
    es: `Cumplimiento`
  },
  'botao-canais-atendimento': {
    pt: `Canais de atendimento`,
    en: `Customer service channels`,
    es: `Canales de atención al cliente`
  },
  'botao-termos': {
    pt: `Termos e Condições Gerais de Uso`,
    en: `Terms and Conditions of Use`,
    es: `Términos y condiciones de uso`
  },
  'botao-privacidade': {
    pt: `Política de Privacidade`,
    en: `Privacy Policy`,
    es: `Política de privacidad`
  },
  'botao-termos1': {
    pt: `Termos e Condições de Uso`,
    en: `Terms and Conditions of Use`,
    es: `Términos y condiciones de uso`
  },
  'botao-privacidade1': {
    pt: `Política de Privacidade`,
    en: `Privacy Policy`,
    es: `Política de privacidad`
  },
  'botao-nossas-marcas2': {
    pt: `Nossas marcas`,
    en: `Our brands`,
    es: `Nuestras marcas`
  },
  'botao-nossas-marcas3': {
    pt: `Nossas marcas`,
    en: `Our brands`,
    es: `Nuestras marcas`
  },
};

const Footer = (props) => {
  const { language, setLanguage } = useContext(LocalizeContext);

  const redes = {
    instagram: "https://www.instagram.com/rompermarcas/",
    facebook: "https://www.facebook.com/romper.com.br",
    linkedin: "https://www.linkedin.com/company/rompermarcas/",
    youtube: "https://www.youtube.com/@Registresuamarca",
  }


  return (
    <div className={` ${styles['container']}`}>
      <div className={styles['footer']}>
        <div className={styles['container1']}>
          <div className={styles['row']}>
            <Link to="/">
            <img alt="image" src="/logofooter.svg" className={styles['logo']} />

            </Link>
            <div className={styles['redes-sociais-mobile']}>
              <img
                onClick={() => window.open(redes.instagram)}
                alt="image"
                src="/redessociais/instagram.svg"
                className={styles['instagram']}
              />
              <img
                onClick={() => window.open(redes.facebook)}
                alt="image"
                src="/redessociais/facebook.svg"
                className={styles['facebook']}
              />
              <img
                alt="image"
                onClick={() => window.open(redes.linkedin)}
                src="/redessociais/linkedin.svg"
                className={styles['linkedin']}
              />
              <img
                alt="image"
                onClick={() => window.open(redes.youtube)}
                src="/redessociais/youtube.svg"
                className={styles['youtube']}
              />
            </div>
            <div className={styles['opcoes']}>
              <Link to="/" className={styles['botao-nossas-marcas']}>
                <span>{footerTexts['botao-nossas-marcas'][language]}</span>
              </Link>
              <Link to={routesLang['sobre-nos'][language]} className={styles['botao-a-romper']}>
                <span>A Romper</span>
              </Link>
              <Link to={routesLang['compliance'][language]} className={styles['botao-compliance']}>
                <span>Compliance</span>
              </Link>
              <Link
                to={routesLang['canais-atendimento'][language]}
                className={styles['botao-canais-atendimento']}
              >
                <span>{footerTexts['botao-canais-atendimento'][language]}</span>
              </Link>
            </div>
            <div className={styles['redes-sociais-desk']}>
              <img
                alt="image"
                onClick={() => window.open(redes.instagram)}
                src="/redessociais/instagram.svg"
                className={styles['instagram1']}
              />
              <img
                alt="image"
                onClick={() => window.open(redes.facebook)}
                src="/redessociais/facebook.svg"
                className={styles['facebook1']}
              />
              <img
                alt="image"
                onClick={() => window.open(redes.linkedin)}
                src="/redessociais/linkedin.svg"
                className={styles['linkedin1']}
              />
              <img
                alt="image"
                onClick={() => window.open(redes.youtube)}
                src="/redessociais/youtube.svg"
                className={styles['youtube1']}
              />
            </div>
          </div>
          <div className={styles['divisor']}></div>
          <div className={styles['row1']}>
            <div className={styles['termos-privacidade-mobile']}>
              <Link to={routesLang['termos-de-uso'][language]} className={styles['botao-termos']}>
              {footerTexts['botao-termos'][language]}
              </Link>
              <Link to={routesLang['politica-de-privacidade'][language]} className={styles['botao-privacidade']}>
                <span>{footerTexts['botao-privacidade'][language]}</span>
              </Link>
            </div>
            <span className={styles['text05']}>
              <span>
                <span>
                  © 2023 ROMPER Administradora de marcas EIRELI
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>CNPJ: 18.461.120/0001-51</span>
              </span>
            </span>
            <div className={styles['termos-privacidade-desk']}>
              <Link to={routesLang['termos-de-uso'][language]} className={styles['botao-termos1']}>
                <span>{footerTexts['botao-termos'][language]}</span>
              </Link>
              <Link to={routesLang['politica-de-privacidade'][language]} className={styles['botao-privacidade1']}>
                <span>{footerTexts['botao-privacidade'][language]}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer