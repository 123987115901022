import React, { useState, useContext, useEffect } from 'react'
import { LocalizeContext } from '../localizeContext';
import { Link, useLocation, useHistory } from 'react-router-dom'
import styles from './header.module.css'

const headerTexts = {
  'botao-nossas-marcas': {
    pt: `Nossas marcas`,
    en: `Our brands`,
    es: `Nuestras marcas`
  },
  'botao-a-romper': {
    pt: `A Romper`,
    en: `About Romper`,
    es: `Acerca de Romper`
  },
  'botao-compliance': {
    pt: `Compliance`,
    en: `Compliance`,
    es: `Cumplimiento`
  },
  'botao-canais-atendimento': {
    pt: `Canais de atendimento`,
    en: `Customer service channels`,
    es: `Canales de atención al cliente`
  },
};

const routesLang = {
  'canais-atendimento': {
    pt:'/canais-atendimento',
    es:'/canales-de-atencion',
    en:'/customer-service-channels'
  },
  'sobre-nos': {
    pt:'/sobre-nos',
    es:'/sobre-nosotros',
    en:'/about-us'
  },
  'termos-de-uso': {
    pt:'/termos-de-uso',
    es:'/terminos-de-uso',
    en:'/terms-of-use'
  },
  'politica-de-privacidade': {
    pt:'/politica-de-privacidade',
    es:'/politica-de-privacidad',
    en:'/privacy-policy'
  },
  'romper-mkt-avancado': {
    pt:'/romper-mkt-avancado',
    es:'/romper-marketing-avanzado',
    en:'/romper-advanced-marketing'
  },
  'compliance': {
    pt:'/compliance',
    es:'/cumplimiento',
    en:'/compliance'
  },
}

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { language, setLanguage } = useContext(LocalizeContext);
  const [OpcoesLinguagemOpened, setOpcoesLinguagemOpened] = useState(false)
  const [MenuMobileOpened, setMenuMobileOpened] = useState(false)

  const changeLanguage = (lang) => {
    setLanguage(lang);
  
    setTimeout(() => {
      const tgb = routesLang[findCurrentRouteKey(location.pathname)];
      if(tgb) {
        const newRoute = tgb[lang];
        if (newRoute) {
          history.push(newRoute);
        }
      }
    }, 0);
  }
  

  const findCurrentRouteKey = (path) => {
    return Object.keys(routesLang).find(key =>
      Object.values(routesLang[key]).includes(path)
    );
  };

  useEffect(() => {
    if(OpcoesLinguagemOpened) setOpcoesLinguagemOpened(false)
  },[language])

  return (
    <div className={` ${styles['container']}`} style={props.fixed ? { position: 'fixed' } : props.sticky ? { position: 'sticky' } : {} }>
      {props.TipoHeader === 1 && (
        <div className={styles['header1-light']}>
          <div className={styles['espaco-branco']}></div>
          <div className={styles['container1']}>
            <Link to="/" className={styles['botao-nossas-marcas']}>
            <img alt="image" src="/logodark.svg" className={styles['logo']} />
            </Link>
            <div className={styles['menu']}>
              <img
                alt="image"
                src="/menudark.svg"
                onClick={() => setMenuMobileOpened(!MenuMobileOpened)}
                className={styles['botao-menu-mobile']}
              />
              <div className={styles['opcoes-desk']}>
                <Link to="/" className={styles['botao-nossas-marcas']}>
                  <span>{headerTexts['botao-nossas-marcas'][language]}</span>
                </Link>
                <Link to={routesLang['sobre-nos'][language]} className={styles['botao-a-romper']}>
                  <span>{headerTexts['botao-a-romper'][language]}</span>
                </Link>
                <Link to={routesLang['compliance'][language]} className={styles['botao-compliance']}>
                  <span>{headerTexts['botao-compliance'][language]}</span>
                </Link>
                <Link
                  to={routesLang['canais-atendimento'][language]}
                  className={styles['botao-canais-atendimento']}
                >
                  <span>{headerTexts['botao-canais-atendimento'][language]}</span>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles['linguagem']}               
          onClick={() => setOpcoesLinguagemOpened(!OpcoesLinguagemOpened)}>
            <div className={styles['bandeira-pais']}>
              <img
                alt="icons8brazil481I910"
                src={language === 'pt' ? '/bandeiras/bandeirabrasil.svg' : language === 'es' ? '/bandeiras/bandeiraespanha.svg' : '/bandeiras/bandeirareinounido.svg'}
                className={styles['brasil']}
              />
            </div>
            <span className={styles['text04']}>
              <span>{language.toUpperCase()}</span>
            </span>
            <img
              src="/external/arrowlinguagem.svg"
              style={ OpcoesLinguagemOpened ? { transform: 'rotate(180deg)' } : {} }
              className={styles['botao-escolher']}
            />
          </div>
        </div>
      )}
      {props.TipoHeader === 2 && (
        <div className={styles['header2-dark']}>
          <div className={styles['espaco-branco1']}></div>
          <div className={styles['container2']}>
          <Link to="/" className={styles['botao-nossas-marcas']}>
            <img alt="image" src="/logolight.svg" className={styles['logo1']} />
            </Link>
            <div className={styles['menu1']}>
              <img
                alt="image"
                src="/menulight.svg"
                onClick={() => setMenuMobileOpened(!MenuMobileOpened)}
                className={styles['botao-menu-mobile1']}
              />
              <div className={styles['opcoes-desk1']}>
                <Link to="/" className={styles['botao-nossas-marcas1']}>
                  <span>{headerTexts['botao-nossas-marcas'][language]}</span>
                </Link>
                <Link to={routesLang['sobre-nos'][language]} className={styles['botao-a-romper1']}>
                  <span>{headerTexts['botao-a-romper'][language]}</span>
                </Link>
                <Link to={routesLang['compliance'][language]} className={styles['botao-compliance1']}>
                  <span>{headerTexts['botao-compliance'][language]}</span>
                </Link>
                <Link
                  to={routesLang['canais-atendimento'][language]}
                  className={styles['botao-canais-atendimento1']}
                >
                  <span>{headerTexts['botao-canais-atendimento'][language]}</span>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles['linguagem1']}               
          onClick={() => setOpcoesLinguagemOpened(!OpcoesLinguagemOpened)}>
            <div className={styles['bandeira-pais1']}>
              <img
                alt="icons8brazil481I910"
                src={language === 'pt' ? '/bandeiras/bandeirabrasil.svg' : language === 'es' ? '/bandeiras/bandeiraespanha.svg' : '/bandeiras/bandeirareinounido.svg'}
                className={styles['brasil1']}
              />
            </div>
            <span className={styles['text10']}>
              <span>{language.toUpperCase()}</span>
            </span>
            <img
              alt="Group1171276758I910"
              src="/external/arrowlinguagem.svg"
              style={ OpcoesLinguagemOpened ? { transform: 'rotate(180deg)' } : {} }
              className={styles['botao-escolher1']}
            />
          </div>
        </div>
      )}
      {props.TipoHeader === 3 && (
        <div className={styles['header3-romper-mkt-avancado']}>
          <div className={styles['espaco-branco2']}></div>
          <div className={styles['container3']}>
            <img
              alt="image"
              src="/logorompermktavancadolight.svg"
              className={styles['logo2']}
            />
            <div className={styles['menu2']}>
              <img
                alt="image"
                src="/menulight.svg"
                onClick={() => setMenuMobileOpened(!MenuMobileOpened)}
                className={styles['botao-menu-mobile2']}
              />
              <div className={styles['opcoes-desk2']}>
                <span className={styles['botao-canais-atendimento2']}>
                  <span>comercial@romper.com.br</span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
          <div className={styles['linguagem2']}              
          onClick={() => setOpcoesLinguagemOpened(!OpcoesLinguagemOpened)}>
            <div className={styles['bandeira-pais2']}>
              <img
                alt="icons8brazil481I910"
                src={language === 'pt' ? '/bandeiras/bandeirabrasil.svg' : language === 'es' ? '/bandeiras/bandeiraespanha.svg' : '/bandeiras/bandeirareinounido.svg'}
                className={styles['brasil2']}
              />
            </div>
            <span className={styles['text14']}>
              <span>{language.toUpperCase()}</span>
            </span>
            <img
              alt="Group1171276758I910"
              src="/external/arrowlinguagem.svg"
              style={ OpcoesLinguagemOpened ? { transform: 'rotate(180deg)' } : {} }
              className={styles['botao-escolher2']}
            />
          </div>
        </div>
      )}
      {OpcoesLinguagemOpened && (
        <div className={styles['opcoes-linguagem-desk']}>
          <div className={styles['opcao-portugues']} onClick={() => changeLanguage('pt')}>
            <img
              alt="brazil19108"
              src="/bandeiras/bandeirabrasil.svg"
              className={styles['bandeirabrasil']}
            />
            <span className={styles['text16']}>
              <span>Português</span>
            </span>
          </div>
          <div className={styles['opcao-espanhol']} onClick={() => changeLanguage('es')}>
            <img
              alt="flag9108"
              src="/bandeiras/bandeiraespanha.svg"
              className={styles['bandeiraespanha']}
            />
            <span className={styles['text18']}>
              <span>Español</span>
            </span>
          </div>
          <div className={styles['opcao-ingles']} onClick={() => changeLanguage('en')}>
            <img
              alt="unitedkingdom9108"
              src="/bandeiras/bandeirareinounido.svg"
              className={styles['bandeirareinounido']}
            />
            <span className={styles['text20']}>
              <span>English</span>
            </span>
          </div>
        </div>
      )}
      {MenuMobileOpened && (
        <div className={styles['menu-aberto-mobile']}>
          <img
            alt="image"
            src="/iconfecharmenu.svg"
            onClick={() => setMenuMobileOpened(false)}
            className={styles['botao-fechar']}
          />
          <Link to="/">
            <div className={styles['botao-nossas-marcas2']}>
              <span className={styles['text22']}>
                <span>Nossas marcas</span>
              </span>
            </div>
          </Link>
          <Link to={routesLang['sobre-nos'][language]}>
            <div className={styles['botao-a-romper2']}>
              <span className={styles['text24']}>
                <span>A Romper</span>
              </span>
            </div>
          </Link>
          <Link to={routesLang['compliance'][language]}>
            <div className={styles['botao-compliance2']}>
              <span className={styles['text26']}>
                <span>Compliance</span>
              </span>
            </div>
          </Link>
          <Link to={routesLang['canais-atendimento'][language]}>
            <div className={styles['botao-canais-atendimento3']}>
              <span className={styles['text28']}>
                <span>Canais de atendimento</span>
              </span>
            </div>
          </Link>
          <div className={styles['linguagem3']}               
          onClick={() => setOpcoesLinguagemOpened(!OpcoesLinguagemOpened)}>
            <div className={styles['bandeira-pais3']}>
              <img
                alt="icons8brazil481I910"
                src={language === 'pt' ? '/bandeiras/bandeirabrasil.svg' : language === 'es' ? '/bandeiras/bandeiraespanha.svg' : '/bandeiras/bandeirareinounido.svg'}
                className={styles['brasil3']}
              />
            </div>
            <span className={styles['text30']}>
              <span>{language.toUpperCase()}</span>
            </span>
            <img
              alt="Group1171276758I910"
              src="/external/arrowlinguagem.svg"
              style={ OpcoesLinguagemOpened ? { transform: 'rotate(180deg)' } : {} }
              className={styles['botao-escolher3']}
            />
          </div>
        </div>
      )}
      {OpcoesLinguagemOpened && (
        <div className={styles['opcoes-linguagem-mobile']}>
          <div className={styles['opcao-portugues1']} onClick={() => changeLanguage('pt')}>
            <img
              alt="brazil19108"
              src="/bandeiras/bandeirabrasil.svg"
              className={styles['bandeirabrasil1']}
            />
            <span className={styles['text32']}>
              <span>Português</span>
            </span>
          </div>
          <div className={styles['opcao-espanhol1']} onClick={() => changeLanguage('es')}>
            <img
              alt="flag9108"
              src="/bandeiras/bandeiraespanha.svg"
              className={styles['bandeiraespanha1']}
            />
            <span className={styles['text34']}>
              <span>Español</span>
            </span>
          </div>
          <div className={styles['opcao-ingles1']} onClick={() => changeLanguage('en')}>
            <img
              alt="unitedkingdom9108"
              src="/bandeiras/bandeirareinounido.svg"
              className={styles['bandeirareinounido1']}
            />
            <span className={styles['text36']}>
              <span>English</span>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
