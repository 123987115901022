import React, { useContext} from 'react';
import styles from './termos-politicas.module.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { LocalizeContext } from '../localizeContext';

const termosDeUsoTexts = {
    'section_title': {
      pt: `TERMOS E CONDIÇÕES GERAIS DE USO`,
      en: `TERMS AND GENERAL CONDITIONS OF USE`,
      es: `TÉRMINOS Y CONDICIONES GENERALES DE USO`
    },
    'blockp1_p1': {
      pt: `Os serviços do`,
      en: `The services of`,
      es: `Los servicios de`
    },
    'blockp1_p2': {
      pt: `são fornecidos pela pessoa jurídica de direito privado`,
      en: `are provided by the legal entity`,
      es: `son proporcionados por la persona jurídica`
    },
    'blockp1_p3': {
        pt: `, neste ato denominada, somente, ROMPER, inscrita no CNPJ sob o nº 18.461.120/0001-51, titular da propriedade intelectual sobre software, website, aplicativos, conteúdos e demais ativos relacionados à plataforma.`,
        en: `, hereinafter referred to as ROMPER, registered under CNPJ number 18.461.120/0001-51, the holder of intellectual property rights over software, website, applications, content, and other assets related to the platform.`,
        es: `, en adelante denominada ROMPER, registrada bajo el número de CNPJ 18.461.120/0001-51, titular de los derechos de propiedad intelectual sobre software, sitio web, aplicaciones, contenido y otros activos relacionados con la plataforma.`
    },
    'blockp2': {
      pt: `Por meio destes termos e condições gerais de uso, a ROMPER, apresenta aos seus clientes, as condições essenciais para o uso dos serviços oferecidos na plataforma.`,
      en: `Through these terms and general conditions of use, ROMPER presents to its customers the essential conditions for the use of the services offered on the platform.`,
      es: `A través de estos términos y condiciones generales de uso, ROMPER presenta a sus clientes las condiciones esenciales para el uso de los servicios ofrecidos en la plataforma.`
    },
    'blockp3': {
      pt: `Ao utilizarem o sysRomper, ou os serviços ofertados pela ROMPER, os usuários se submetem às condições destes termos e condições gerais de uso, bem como, a todos os documentos anexos a estes.`,
      en: `By using sysRomper or the services offered by ROMPER, users are subject to the conditions of these terms and general conditions of use, as well as all documents attached to them.`,
      es: `Al utilizar sysRomper o los servicios ofrecidos por ROMPER, los usuarios se someten a las condiciones de estos términos y condiciones generales de uso, así como a todos los documentos adjuntos a los mismos.`
    },
    'blockt1': {
      pt: `1. DO OBJETO`,
      en: `1. SUBJECT MATTER`,
      es: `1. OBJETO`
    },
    'blockp4_p1': {
      pt: `Por meio destes`,
      en: `Through these`,
      es: `A través de estos`
    },
    'blockp4_p2': {
      pt: `termos e condições gerais de uso`,
      en: `terms and general conditions of use`,
      es: `términos y condiciones generales de uso`
    },
    'blockp4_p3': {
      pt: `, a ROMPER, apresenta aos seus clientes, as condições essenciais para o uso dos serviços oferecidos na plataforma.`,
      en: `, ROMPER presents to its customers the essential conditions for the use of the services offered on the platform.`,
      es: `, ROMPER presenta a sus clientes las condiciones esenciales para el uso de los servicios ofrecidos en la plataforma.`
    },
    'blockp5_p1': {
      pt: `Ao utilizarem o`,
      en: `By using`,
      es: `Al utilizar`
    },
    'blockp5_p2': {
      pt: `, ou os serviços ofertados pela ROMPER, os usuários se submetem às condições destes termos e condições gerais de uso, bem como, a todos os documentos anexos a estes.`,
      en: `, or the services offered by ROMPER, users are subject to the conditions of these terms and general conditions of use, as well as all documents attached to them.`,
      es: `, o los servicios ofrecidos por ROMPER, los usuarios se someten a las condiciones de estos términos y condiciones generales de uso, así como a todos los documentos adjuntos a los mismos.`
    },
    'blockp5_p2': {
      pt: `, ou os serviços ofertados pela ROMPER, os usuários se submetem às condições destes termos e condições gerais de uso, bem como, a todos os documentos anexos a estes.`,
      en: `, or the services offered by ROMPER, users are subject to the conditions of these terms and general conditions of use, as well as all documents attached to them.`,
      es: `, o los servicios ofrecidos por ROMPER, los usuarios se someten a las condiciones de estos términos y condiciones generales de uso, así como a todos los documentos adjuntos a los mismos.`
    },
    'blockt2': {
        pt: `1. DO OBJETO`,
        en: `1. SUBJECT MATTER`,
        es: `1. OBJETO`
      },
      'blockp6': {
        pt: `A plataforma visa licenciar o uso de seu software, website, aplicativos e demais ativos de propriedade intelectual, fornecendo ferramentas para auxiliar e dinamizar o dia a dia do cliente romper.`,
        en: `The platform aims to license the use of its software, website, applications, and other intellectual property assets, providing tools to assist and streamline the daily activities of the ROMPER client.`,
        es: `La plataforma tiene como objetivo otorgar licencias para el uso de su software, sitio web, aplicaciones y otros activos de propiedad intelectual, proporcionando herramientas para ayudar y agilizar las actividades diarias del cliente ROMPER.`
      },
      'blockp7_p1': {
        pt: `Através do`,
        en: `Through`,
        es: `A través de`
      },
      'blockp7_p2': {
        pt: `é possível que o cliente romper acompanhe o seu processo, bem como, contrate novos serviços ofertados pela ROMPER, quais sejam:`,
        en: `it is possible for the ROMPER client to track their process and also contract new services offered by ROMPER, which are:`,
        es: `es posible que el cliente ROMPER siga su proceso y también contrate nuevos servicios ofrecidos por ROMPER, que son:`
      },
      blockli1: {
        pt: `Acessar o sistema de classificação de marca;`,
        en: `Access the trademark classification system;`,
        es: `Acceder al sistema de clasificación de marcas;`
      },
      blockli2: {
        pt: `Pesquisa de viabilidade de marca;`,
        en: `Trademark feasibility research;`,
        es: `Investigación de viabilidad de marca;`
      },
      blockli3: {
        pt: `Pedido de registro de marca (com especificação pré-aprovada);`,
        en: `Trademark registration request (with pre-approved specification);`,
        es: `Solicitud de registro de marca (con especificación preaprobada);`
      },
      blockli4: {
        pt: `Pedido de registro de marca (com especificação livre);`,
        en: `Trademark registration request (with open specification);`,
        es: `Solicitud de registro de marca (con especificación abierta);`
      },
      blockli5: {
        pt: `Registro de marca para menor de 18 anos;`,
        en: `Trademark registration for individuals under 18 years of age;`,
        es: `Registro de marca para personas menores de 18 años;`
      },
      blockli6: {
        pt: `Registro de marca para estrangeiro;`,
        en: `Trademark registration for foreigners;`,
        es: `Registro de marca para extranjeros;`
      },
      blockli7: {
        pt: `Oposição ao registro de marca;`,
        en: `Trademark registration opposition;`,
        es: `Oposición al registro de marca;`
      },
      blockli8: {
        pt: `Resposta à oposição;`,
        en: `Response to the opposition;`,
        es: `Respuesta a la oposición;`
      },
      blockli9: {
        pt: `Manifestação contra caducidade;`,
        en: `Statement against expiration;`,
        es: `Declaración contra la caducidad;`
      },
      blockli10: {
        pt: `Prorrogação de registro de marca;`,
        en: `Trademark registration extension;`,
        es: `Extensión de registro de marca;`
      },
      blockli11: {
        pt: `Recurso contra indeferimento de marca;`,
        en: `Appeal against trademark rejection;`,
        es: `Recurso contra el rechazo de la marca;`
      },
      blockli12: {
        pt: `Recurso contra nulidade;`,
        en: `Appeal against nullity;`,
        es: `Recurso contra la nulidad;`
      },
      blockli13: {
        pt: `Pedido de nulidade de marca;`,
        en: `Trademark nullity request;`,
        es: `Solicitud de nulidad de marca;`
      },
      blockli14: {
        pt: `Pedido de caducidade da marca;`,
        en: `Trademark expiration request;`,
        es: `Solicitud de caducidad de la marca;`
      },
      blockli15: {
        pt: `Atualização de dados do titular;`,
        en: `Update of owner's data;`,
        es: `Actualización de datos del titular;`
      },
      blockli16: {
        pt: `Transferência de titularidade da marca;`,
        en: `Trademark ownership transfer;`,
        es: `Transferencia de titularidad de la marca;`
      },
      blockli17: {
        pt: `Correção de dados do processo de registro de marca;`,
        en: `Correction of trademark registration process data;`,
        es: `Corrección de datos del proceso de registro de marca;`
      },
      blockli18: {
        pt: `Destituição de procurador;`,
        en: `Removal of attorney;`,
        es: `Remoción del apoderado;`
      },
      blockli19: {
        pt: `Desistência de pedido de registro de marca;`,
        en: `Trademark registration request withdrawal;`,
        es: `Retiro de solicitud de registro de marca;`
      },
      blockli20: {
        pt: `Pedido e emissão da 2ª via do certificado de registro;`,
        en: `Request and issuance of the 2nd copy of the registration certificate;`,
        es: `Solicitud y emisión de la 2ª copia del certificado de registro;`
      },
      blockli21: {
        pt: `Pedido de registro de marca de alto renome;`,
        en: `Highly renowned trademark registration request;`,
        es: `Solicitud de registro de marca de alto renombre;`
      },
      blockli22: {
        pt: `Notificação extrajudicial;`,
        en: `Extrajudicial notification;`,
        es: `Notificación extrajudicial;`
      },
      blockli23: {
        pt: `Resposta à notificação;`,
        en: `Response to the notification;`,
        es: `Respuesta a la notificación;`
      },
      blockli24: {
        pt: `Emissão de GRU;`,
        en: `Issuance of GRU (Government Revenue Collection Form);`,
        es: `Emisión de GRU (Guía de Recolhimento da Unión);`
      },
      blockli25: {
        pt: `Acompanhamento de processo;`,
        en: `Process monitoring;`,
        es: `Seguimiento del proceso;`
      },
      blockli26: {
        pt: `Monitoramento de marca;`,
        en: `Trademark monitoring;`,
        es: `Monitoreo de marca;`
      },    
      blockli27: {
        pt: `Criação de marca figurativa;`,
        en: `Creation of figurative trademark;`,
        es: `Creación de marca figurativa;`
      },
      blockli28: {
        pt: `Análise de caso.`,
        en: `Case analysis.`,
        es: `Análisis de caso.`
      },
      blockt3: {
        pt: `2. DA ACEITAÇÃO`,
        en: `2. ACCEPTANCE`,
        es: `2. ACEPTACIÓN`
      },
      blockp8: {
        pt: `O presente termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo indeterminado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do site ou aplicativo.`,
        en: `This term establishes voluntarily and freely contracted obligations, for an indefinite period, between the platform and individuals or legal entities who are users of the website or application.`,
        es: `Este término establece obligaciones contratadas de manera voluntaria y libre, por tiempo indeterminado, entre la plataforma y personas físicas o jurídicas que son usuarios del sitio web o la aplicación.`
      },
      blockp9: {
        pt: `Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis.`,
        en: `By using the platform, the user fully accepts these rules and undertakes to observe them, under the risk of applicable penalties.`,
        es: `Al utilizar la plataforma, el usuario acepta completamente estas normas y se compromete a cumplirlas, bajo el riesgo de sanciones aplicables.`
      },
      blockp10: {
        pt: `A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de quaisquer serviços fornecidos pela empresa. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los.`,
        en: `Acceptance of this instrument is essential for access and the use of any services provided by the company. If you do not agree with the provisions of this instrument, you should not use them.`,
        es: `La aceptación de este instrumento es esencial para el acceso y el uso de cualquier servicio proporcionado por la empresa. Si no está de acuerdo con las disposiciones de este instrumento, no debe utilizarlos.`
      },
      blockt4: {
        pt: `3. DO ACESSO DOS USUÁRIOS`,
        en: `3. USER ACCESS`,
        es: `3. ACCESO DE LOS USUARIOS`
      },
      blockp11: {
        pt: `Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento.`,
        en: `All technical solutions available to the platform's responsible party will be used to enable access to the service 24 (twenty-four) hours a day, 7 (seven) days a week. However, navigation on the platform or on any of its pages may be interrupted, limited, or suspended for updates, modifications, or any action necessary for its proper operation.`,
        es: `Se utilizarán todas las soluciones técnicas disponibles para el responsable de la plataforma con el fin de permitir el acceso al servicio las 24 horas del día, los 7 días de la semana. Sin embargo, la navegación en la plataforma o en cualquiera de sus páginas puede interrumpirse, limitarse o suspenderse para actualizaciones, modificaciones o cualquier acción necesaria para su correcto funcionamiento.`
      },
      blockt5: {
        pt: `4. DO CADASTRO`,
        en: `4. REGISTRATION`,
        es: `4. REGISTRO`
      },
      blockp12: {
        pt: `O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a depender dos serviços ou produtos escolhidos, o pagamento de determinado valor.`,
        en: `Access to the platform's features will require prior registration and, depending on the chosen services or products, the payment of a specific amount.`,
        es: `El acceso a las funciones de la plataforma requerirá un registro previo y, según los servicios o productos elegidos, el pago de una cantidad específica.`
      },
      blockp13: {
        pt: `Os Serviços estão disponíveis para pessoas físicas e pessoas jurídicas regularmente inscritas nos cadastros de contribuintes federal e estaduais que tenham capacidade legal para contratá-los. Não podem utilizá-los, assim, pessoas que não gozem dessa capacidade, inclusive menores de idade ou pessoas que tenham sido inabilitadas da Plataforma, temporária ou definitivamente. Ficam, desde já, os usuários advertidos das sanções legais cominadas no Código Civil.`,
        en: `The Services are available to individuals and legal entities regularly registered in federal and state taxpayer registries who have the legal capacity to contract them. Individuals who do not have this capacity, including minors or individuals who have been disabled from the Platform, either temporarily or permanently, cannot use them. Users are hereby warned of the legal sanctions provided for in the Civil Code.`,
        es: `Los Servicios están disponibles para personas físicas y personas jurídicas regularmente inscritas en los registros de contribuyentes federales y estatales que tengan la capacidad legal para contratarlos. Las personas que no tengan esta capacidad, incluidos menores de edad o personas que hayan sido deshabilitadas en la Plataforma, ya sea temporal o permanentemente, no pueden utilizarlos. Se advierte a los usuarios de las sanciones legales previstas en el Código Civil.`
      },
      blockp14: {
        pt: `O cadastro deve ser realizado diretamente na Plataforma, mediante o preenchimento dos dados obrigatórios solicitados pela ROMPER e pelo envio dos documentos obrigatórios.`,
        en: `Registration must be done directly on the Platform by filling out the mandatory information requested by ROMPER and submitting the required documents.`,
        es: `El registro debe realizarse directamente en la Plataforma completando la información obligatoria solicitada por ROMPER y enviando los documentos requeridos.`
      },
      blockp15: {
        pt: `Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se compromete com a veracidade dos dados fornecidos, sob as penas da lei.`,
        en: `When registering, the user must provide complete, recent, and valid information, and it is their sole responsibility to keep this information up to date. The user also commits to the accuracy of the provided data, under penalty of law.`,
        es: `Al registrarse, el usuario debe proporcionar información completa, reciente y válida, y es su responsabilidad exclusiva mantener esta información actualizada. El usuario también se compromete a la veracidad de los datos proporcionados, bajo pena de ley.`
      },
      blockp16: {
        pt: `O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.`,
        en: `The user commits to not disclose their registration and/or access data to third parties, taking full responsibility for their use.`,
        es: `El usuario se compromete a no divulgar sus datos de registro y/o acceso a la plataforma a terceros, asumiendo la plena responsabilidad por su uso.`
      },
      blockp17: {
        pt: `Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços e produtos.`,
        en: `By registering, the user expressly declares and guarantees to be fully capable of exercising and enjoying the services and products freely.`,
        es: `Al registrarse, el usuario declara y garantiza expresamente ser plenamente capaz de ejercer y disfrutar libremente de los servicios y productos.`
      },
      blockp18: {
        pt: `O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas comunicações necessárias.`,
        en: `The user must provide a valid email address through which the website will conduct all necessary communications.`,
        es: `El usuario debe proporcionar una dirección de correo electrónico válida a través de la cual el sitio web realizará todas las comunicaciones necesarias.`
      },
      blockp19: {
        pt: `Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.`,
        en: `After confirming the registration, the user will have a personal login and password, which ensures individual access. It is the sole responsibility of the user to maintain this password confidential and secure, preventing unauthorized access to personal information.`,
        es: `Después de confirmar el registro, el usuario tendrá un inicio de sesión y una contraseña personal, que garantiza el acceso individual. Es responsabilidad exclusiva del usuario mantener esta contraseña de manera confidencial y segura, evitando el acceso no autorizado a la información personal.`
      },
      blockp20: {
        pt: `Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.`,
        en: `Any and all activities performed using the password will be the responsibility of the user, who must promptly inform the platform in case of misuse of the password.`,
        es: `Todas las actividades realizadas con el uso de la contraseña serán responsabilidad del usuario, quien deberá informar de inmediato a la plataforma en caso de uso indebido de la contraseña.`
      },
      blockp21: {
        pt: `Caberá ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilize a utilização da plataforma e dos serviços ou produtos.`,
        en: `It is the user's responsibility to ensure that their equipment is compatible with the technical characteristics that enable the use of the platform and its services or products.`,
        es: `Es responsabilidad del usuario asegurarse de que su equipo sea compatible con las características técnicas que permiten el uso de la plataforma y sus servicios o productos.`
      },
      blockp22: {
        pt: `Os usuários pessoas físicas que desejarem, poderão alterar seu cadastro para serem substituídos por uma pessoa jurídica. Para isso, deverão entrar em contato com a ROMPER, devendo enviar todos os dados e informações requisitadas por esta.`,
        en: `Individual users who wish to do so may change their registration to be replaced by a legal entity. To do this, they must contact ROMPER and provide all the data and information requested by ROMPER.`,
        es: `Los usuarios individuales que deseen hacerlo pueden cambiar su registro para ser reemplazados por una entidad legal. Para hacerlo, deben ponerse en contacto con ROMPER y proporcionar todos los datos e información solicitados por ROMPER.`
      },
      blockp23: {
        pt: `O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao site ou aplicativo. O seu descadastramento será realizado o mais rapidamente possível, desde que não sejam verificados débitos em aberto.`,
        en: `The user may request the cancellation of their registration on the website or application at any time. Their deregistration will be carried out as quickly as possible, provided that there are no outstanding debts.`,
        es: `El usuario puede solicitar en cualquier momento la cancelación de su registro en el sitio web o la aplicación. Su desregistro se llevará a cabo lo más rápido posible, siempre que no haya deudas pendientes.`
      },
      blockp24: {
        pt: `Em nenhuma hipótese será permitida a cessão, a venda, o aluguel ou outra forma de transferência da conta. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados por infrações às políticas da ROMPER.`,
        en: `Under no circumstances will the assignment, sale, rental, or other form of transfer of the account be allowed. Additionally, the creation of new registrations by individuals whose original registrations have been canceled due to violations of ROMPER's policies will not be allowed.`,
        es: `En ningún caso se permitirá la cesión, venta, alquiler u otra forma de transferencia de la cuenta. Además, no se permitirá la creación de nuevos registros por parte de personas cuyos registros originales hayan sido cancelados debido a violaciones de las políticas de ROMPER.`
      },
      blockp25: {
        pt: `A ROMPER pode, unilateralmente, excluir o cadastro dos usuários quando verificado que a conduta deste é ou será prejudicial ou ofensiva a empresa, outros usuários, seus funcionários ou a terceiros.`,
        en: `ROMPER may unilaterally delete users' registrations when it is verified that the user's conduct is or will be harmful or offensive to the company, other users, its employees, or third parties.`,
        es: `ROMPER puede eliminar unilateralmente los registros de los usuarios cuando se verifique que la conducta del usuario es o será perjudicial u ofensiva para la empresa, otros usuarios, sus empleados o terceros.`
      },
      blockt6: {
        pt: `5. DOS SERVIÇOS`,
        en: `5. SERVICES`,
        es: `5. SERVICIOS`
      },
      blockp26: {
        pt: `A plataforma poderá disponibilizar para o usuário um conjunto específico de funcionalidades e ferramentas para otimizar o uso dos serviços.`,
        en: `The platform may provide users with a specific set of features and tools to optimize the use of the services.`,
        es: `La plataforma puede proporcionar a los usuarios un conjunto específico de funciones y herramientas para optimizar el uso de los servicios.`
      },
      blockp27: {
        pt: `Na plataforma os serviços oferecidos estão descritos e apresentados com o maior grau de exatidão, contendo informações sobre sua composição e respectivo preço.`,
        en: `On the platform, the services offered are described and presented with the highest degree of accuracy, including information about their composition and respective prices.`,
        es: `En la plataforma, los servicios ofrecidos se describen y presentan con el mayor grado de precisión, incluyendo información sobre su composición y precios respectivos.`
      },
      blockt7: {
        pt: `6. DOS PREÇOS`,
        en: `6. PRICES`,
        es: `6. PRECIOS`
      },
      blockp28: {
        pt: `A plataforma se reserva no direito de reajustar unilateralmente, a qualquer tempo, os valores dos serviços ou produtos sem consulta ou anuência prévia do usuário.`,
        en: `The platform reserves the right to unilaterally readjust the prices of services or products at any time without prior consultation or agreement from the user.`,
        es: `La plataforma se reserva el derecho de ajustar unilateralmente los precios de los servicios o productos en cualquier momento sin consulta previa ni acuerdo del usuario.`
      },
      blockp29: {
        pt: `Os valores aplicados são aqueles que estão em vigor no momento do pedido.`,
        en: `The applied prices are those in effect at the time of the order.`,
        es: `Los precios aplicados son los vigentes en el momento del pedido.`
      },
      blockp30: {
        pt: `Os preços são indicados em reais.`,
        en: `Prices are indicated in dollars.`,
        es: `Los precios se indican en dolares.`
      },
      blockp31: {
        pt: `Na contratação de determinado serviço ou produto, a plataforma poderá solicitar as informações financeiras do usuário, como CPF, endereço de cobrança e dados de cartões. Ao inserir referidos dados o usuário concorda que sejam cobrados, de acordo com a forma de pagamento que venha a ser escolhida, os preços então vigentes e informados quando da contratação. Referidos dados financeiros poderão ser armazenados para facilitar acessos e contratações futuras.`,
        en: `When contracting a specific service or product, the platform may request the user's financial information, such as CPF, billing address, and card details. By entering this data, the user agrees to be charged, according to the chosen payment method, the prices then in effect and informed at the time of contracting. Said financial data may be stored to facilitate future access and contracting.`,
        es: `Al contratar un servicio o producto específico, la plataforma puede solicitar la información financiera del usuario, como el CPF, la dirección de facturación y los datos de la tarjeta. Al ingresar estos datos, el usuario acepta que se le cobren los precios vigentes y informados en el momento de la contratación, de acuerdo con el método de pago elegido. Dichos datos financieros pueden almacenarse para facilitar el acceso y la contratación futura.`
      },
      blockp37: {
        pt: `c) Dispor de dispositivos e equipamentos tecnológicos, serviços de conexão à Internet, com antivírus e firewall habilitados, softwares devidamente atualizados, além da adoção de medidas de segurança mínimas para acesso a Plataforma;`,
        en: `c) Have technological devices and equipment, Internet connection services, with antivirus and firewall enabled, software duly updated, in addition to adopting minimum security measures for accessing the Platform;`,
        es: `c) Contar con dispositivos y equipos tecnológicos, servicios de conexión a Internet, con antivirus y firewall habilitados, software debidamente actualizado, además de adoptar medidas de seguridad mínimas para acceder a la Plataforma;`
      },
      blockp38: {
        pt: `d) Deixar seus sistemas de antispam, filtros similares ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento dos comunicados e materiais da Plataforma, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados;`,
        en: `d) Ensure that your antispam systems, similar filters, or message forwarding settings are adjusted in a way that does not interfere with the receipt of communications and materials from the Platform, with no excuse accepted if you have not accessed any email or electronic message due to the mentioned resources;`,
        es: `d) Asegurarse de que los sistemas antispam, filtros similares o configuraciones de reenvío de mensajes estén ajustados de manera que no interfieran en la recepción de comunicaciones y materiales de la Plataforma, sin excusas aceptadas si no ha accedido a ningún correo electrónico o mensaje electrónico debido a los recursos mencionados;`
      },
      blockp39: {
        pt: `e) As funcionalidades da Plataforma são oferecidas na forma de licença de uso, não conferindo ao Usuário nenhum direito sobre o software utilizado pela ROMPER ou sobre suas estruturas de informática que o sustentam;`,
        en: `e) The functionalities of the Platform are offered in the form of a usage license, granting the User no rights over the software used by ROMPER or its underlying computer structures;`,
        es: `e) Las funcionalidades de la Plataforma se ofrecen en forma de licencia de uso, sin otorgar al Usuario derechos sobre el software utilizado por ROMPER o sus estructuras informáticas subyacentes;`
      },
      blockt10: {
        pt: `8.1. DAS ISENÇÕES E LIMITAÇÕES DE RESPONSABILIDADE DA ROMPER`,
        en: `8.1. ROMPER'S EXEMPTIONS AND LIABILITY LIMITATIONS`,
        es: `8.1. EXENCIONES Y LIMITACIONES DE RESPONSABILIDAD DE ROMPER`
      },
      blockp40: {
        pt: `Os usuários reconhecem e concordam que:`,
        en: `Users acknowledge and agree that:`,
        es: `Los usuarios reconocen y aceptan que:`
      },
      blockp41: {
        pt: `A ROMPER não se responsabiliza por fraudes, nem por qualquer dano causado por Usuários a terceiros;`,
        en: `ROMPER is not responsible for fraud or any damage caused by Users to third parties;`,
        es: `ROMPER no se hace responsable de fraudes ni de ningún daño causado por los Usuarios a terceros;`
      },
      blockp42: {
        pt: `A ROMPER não será responsável por erros ou interrupções no fornecimento de informações por sistemas independentes de prestação de serviços, como os sistemas de pagamento e os servidores, ou por sites integrados gerenciados por terceiros. Tais sistemas e sites integrados somente buscam trazer maior conveniência aos usuários, estando ciente que a ROMPER não tem qualquer influência ou responsabilidade sobre o conteúdo disponibilizado e os serviços oferecidos;`,
        en: `ROMPER will not be responsible for errors or interruptions in the provision of information by independent service delivery systems, such as payment systems and servers, or integrated sites managed by third parties. Such integrated systems and sites are intended solely for the convenience of users, and it is understood that ROMPER has no influence or responsibility over the content provided and the services offered;`,
        es: `ROMPER no será responsable de errores o interrupciones en la provisión de información por parte de sistemas independientes de prestación de servicios, como sistemas de pago y servidores, o por sitios integrados gestionados por terceros. Dichos sistemas y sitios integrados están destinados únicamente para la conveniencia de los usuarios, y se entiende que ROMPER no tiene influencia ni responsabilidad sobre el contenido proporcionado y los servicios ofrecidos;`
      },
      blockp43: {
        pt: `A ROMPER não se responsabiliza por qualquer dano, prejuízo e/ou perda sofridos pelo Usuário em razão de falhas em sua conexão com a internet, com o seu provedor, no sistema operacional ou servidor utilizados pelo Usuário ou Usuário, decorrentes de condutas de terceiros, caso fortuito ou força maior;`,
        en: `ROMPER is not responsible for any damage, loss, and/or loss suffered by the User due to failures in their internet connection, with their provider, in the operating system or server used by the User or User, resulting from the actions of third parties, fortuitous event, or force majeure;`,
        es: `ROMPER no se hace responsable de ningún daño, pérdida y/o pérdida sufridos por el Usuario debido a fallos en su conexión a Internet, con su proveedor, en el sistema operativo o servidor utilizado por el Usuario o Usuario, como resultado de acciones de terceros, caso fortuito o fuerza mayor;`
      },
      blockp44: {
        pt: `A ROMPER não será responsável por qualquer vírus, trojan, malware, spyware ou qualquer software que possa danificar, alterar as configurações ou infiltrar o equipamento do usuário, profissional e demais usuários em decorrência do acesso, da utilização ou da navegação na internet, ou, ainda, como consequência da transferência de quaisquer dados e informações;`,
        en: `ROMPER will not be responsible for any virus, trojan, malware, spyware, or any software that may damage, alter settings, or infiltrate the user's, professional's, and other users' equipment due to internet access, use, or browsing, or as a result of the transfer of any data and information;`,
        es: `ROMPER no será responsable de ningún virus, troyano, malware, spyware o cualquier software que pueda dañar, alterar configuraciones o infiltrarse en el equipo del usuario, profesional y otros usuarios debido al acceso, uso o navegación por Internet, o como resultado de la transferencia de datos e información;`
      },
      blockt11: {
        pt: `9. DAS VEDAÇÕES`,
        en: `9. PROHIBITIONS`,
        es: `9. PROHIBICIONES`
      },
      blockp45: {
        pt: `Fica vedada qualquer tentativa, direta ou indireta, bem-sucedida ou não, de realizar alguma das atividades listadas abaixo. A constatação de tal intento tornará o autor, bem como seus mandantes, defensores, encorajadores e ajudantes, passível das sanções previstas no ordenamento jurídico vigente. Tais atividades incluem, mas não se limitam a:`,
        en: `Any attempt, direct or indirect, successful or not, to carry out any of the activities listed below is prohibited. The discovery of such an attempt will make the author, as well as their principals, defenders, encouragers, and assistants, liable to the penalties provided for in the current legal system. These activities include, but are not limited to:`,
        es: `Se prohíbe cualquier intento, directo o indirecto, exitoso o no, de llevar a cabo alguna de las actividades enumeradas a continuación. El descubrimiento de tal intento hará que el autor, así como sus mandantes, defensores, alentadores y ayudantes, sean responsables de las sanciones previstas en el sistema legal vigente. Estas actividades incluyen, pero no se limitan a:`
      },
      blockp46: {
        pt: `a) Incomodar, agredir, caluniar, injuriar, difamar, perseguir ou assediar qualquer indivíduo;`,
        en: `a) Annoy, assault, slander, insult, defame, pursue, or harass any individual;`,
        es: `a) Molestar, agredir, difamar, insultar, difamar, perseguir o acosar a cualquier individuo;`
      },
      blockp47: {
        pt: `b) Infringir os direitos de qualquer pessoa ou entidade, incluindo, sem limitação, os direitos de propriedade intelectual, privacidade, publicidade ou contratual;`,
        en: `b) Infringe the rights of any person or entity, including, but not limited to, intellectual property, privacy, publicity, or contractual rights;`,
        es: `b) Infringir los derechos de cualquier persona o entidad, incluyendo, pero no limitado a, derechos de propiedad intelectual, privacidad, publicidad o derechos contractuales;`
      },
      blockp48: {
        pt: `c) Copiar, armazenar, ou de qualquer forma acessar qualquer informação contida na plataforma ou serviços para fins que não sejam expressamente autorizados por estes Termos;`,
        en: `c) Copy, store, or in any way access any information contained on the platform or services for purposes not expressly authorized by these Terms;`,
        es: `c) Copiar, almacenar o de cualquier manera acceder a cualquier información contenida en la plataforma o servicios con fines que no estén expresamente autorizados por estos Términos;`
      },
      blockp49: {
        pt: `d) Utilizar a plataforma ou serviços para transmitir, distribuir, publicar ou enviar qualquer informação relativa a qualquer outra pessoa ou entidade, incluindo, sem limitação, fotografias de outras pessoas sem sua permissão, informações de contato pessoal ou de crédito, débito, cartão telefônico ou números de contas;`,
        en: `d) Use the platform or services to transmit, distribute, publish, or send any information regarding any other person or entity, including, but not limited to, photographs of other people without their permission, personal contact or credit information, debit, phone card, or account numbers;`,
        es: `d) Utilizar la plataforma o los servicios para transmitir, distribuir, publicar o enviar cualquier información relacionada con cualquier otra persona o entidad, incluyendo, pero no limitado a, fotografías de otras personas sin su permiso, información de contacto personal o crediticia, tarjetas de débito, tarjetas telefónicas o números de cuenta;`
      },
      blockp50: {
        pt: `e) Utilizar a plataforma ou serviços para qualquer comercial ou para outros fins que não sejam expressamente autorizados por estes Termos;`,
        en: `e) Use the platform or services for any commercial or other purposes not expressly authorized by these Terms;`,
        es: `e) Utilizar la plataforma o los servicios con fines comerciales u otros fines que no estén expresamente autorizados por estos Términos;`
      },
      blockp51: {
        pt: `f) Usar software manual ou automatizado, dispositivos, scripts robôs, ou outros meios ou processos para acessar, “scrape”, “crawl” ou “spider” qualquer parte da plataforma, serviços;`,
        en: `f) Use manual or automated software, devices, robot scripts, or other means or processes to access, "scrape," "crawl," or "spider" any part of the platform, services;`,
        es: `f) Utilizar software manual o automatizado, dispositivos, scripts de robots u otros medios o procesos para acceder, "scrapear", "rastrear" o "arañar" cualquier parte de la plataforma o servicios;`
      },
      blockp52: {
        pt: `g) Modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, licenciar, criar trabalhos derivados, exibir em outra webpage, utilizar em qualquer outro website, transferir ou vender qualquer informação, software, listas de usuários, banco de dados ou outras listas, produtos ou serviços prestados por ou obtidos pela ROMPER, incluindo, mas não limitado a, práticas envolvendo “screen scraping”, “database scraping”, ou qualquer outra atividade com o propósito de obter listas de usuários ou outras informações;`,
        en: `g) Modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works, display on another webpage, use on any other website, transfer, or sell any information, software, user lists, databases, or other lists, products, or services provided by or obtained by ROMPER, including, but not limited to, practices involving "screen scraping," "database scraping," or any other activity for the purpose of obtaining user lists or other information;`,
        es: `g) Modificar, copiar, distribuir, transmitir, mostrar, realizar, reproducir, publicar, licenciar, crear obras derivadas, mostrar en otra página web, usar en cualquier otro sitio web, transferir o vender cualquier información, software, listas de usuarios, bases de datos u otras listas, productos o servicios proporcionados u obtenidos por ROMPER, incluyendo, pero no limitado a, prácticas que involucren "screen scraping", "database scraping" o cualquier otra actividad con el propósito de obtener listas de usuarios u otra información;`
      },
      blockp53: {
        pt: `h) Usar scripts automatizados para coletar informações ou de outra forma interagir com a plataforma ou serviços;`,
        en: `h) Use automated scripts to collect information or otherwise interact with the platform or services;`,
        es: `h) Usar scripts automatizados para recopilar información o interactuar de otra manera con la plataforma o los servicios;`
      },
      blockp54: {
        pt: `i) Acessar, testar, adulterar, interferir ou danificar a plataforma ou qualquer sistema da ROMPER, dos usuários ou de terceiros através do uso de vírus, cancelar bots, cavalos de Tróia, códigos nocivos, flood pings, ataques de negação de serviço, pacote ou falsificação de IP, roteamento forjado ou informações de endereço eletrônico ou métodos similares ou de tecnologia;`,
        en: `i) Access, test, tamper with, interfere with, or damage the platform or any system of ROMPER, users, or third parties through the use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, packet or IP forgery, forged routing or email addresses, or similar technology or methods;`,
        es: `i) Acceder, probar, manipular, interferir o dañar la plataforma o cualquier sistema de ROMPER, usuarios o terceros mediante el uso de virus, bots de cancelación, caballos de Troya, código dañino, flood pings, ataques de denegación de servicio, paquetes o falsificación de IP, enrutamiento o direcciones de correo electrónico falsificados, o tecnología o métodos similares;`
      },
      blockp55: {
        pt: `j) Tentar detectar, varrer, ou testar a vulnerabilidade de qualquer sistema ou rede ROMPER ou violar qualquer segurança ou medidas de autenticação;`,
        en: `j) Attempt to detect, scan, or test the vulnerability of any ROMPER system or network or violate any security or authentication measures;`,
        es: `j) Intentar detectar, escanear o probar la vulnerabilidad de cualquier sistema o red de ROMPER o violar cualquier medida de seguridad o autenticación;`
      },
      blockp56: {
        pt: `k) Evitar, ignorar, remover, desativar, prejudicar, decodificar, ou evitar qualquer medida tecnológica implementada pela ROMPER ou qualquer dos provedores desta ou qualquer outro terceiro para proteger a plataforma, serviços;`,
        en: `k) Bypass, ignore, remove, disable, impair, decode, or circumvent any technological measure implemented by ROMPER or any of its providers or any other third party to protect the platform, services;`,
        es: `k) Eludir, ignorar, eliminar, desactivar, perjudicar, decodificar o eludir cualquier medida tecnológica implementada por ROMPER o cualquiera de sus proveedores o cualquier otro tercero para proteger la plataforma o servicios;`
      },
      blockp57: {
        pt: `l) Forjar qualquer pacote de cabeçalho TCP/IP ou qualquer parte da informação do cabeçalho em qualquer publicação, e-mail ou newsgroup, ou de qualquer forma utilizar a plataforma, serviços ou conteúdos, para enviar fonte de identificação alterada, enganosa ou falsa informação;`,
        en: `l) Forge any TCP/IP header packet or any part of the header information in any publication, email, or newsgroup, or in any way use the platform, services, or content to send altered, misleading, or false source information;`,
        es: `l) Falsificar cualquier paquete de encabezado TCP/IP o cualquier parte de la información de encabezado en cualquier publicación, correo electrónico o grupo de noticias, o de cualquier manera utilizar la plataforma, servicios o contenido para enviar información de origen alterada, engañosa o falsa;`
      },
      blockp58: {
        pt: `m) Sistematicamente recuperar dados ou outro conteúdo da nossa plataforma ou serviços para criar ou compilar, direta ou indiretamente, em downloads únicos ou múltiplos, a coleta, compilação, banco de dados, diretório ou similar, seja por métodos manuais, através da utilização de bots, crawlers, ou spiders, ou de qualquer outra forma;`,
        en: `m) Systematically retrieve data or other content from our platform or services to create or compile, directly or indirectly, in single or multiple downloads, the collection, compilation, database, directory, or similar, whether by manual methods, through the use of bots, crawlers, or spiders, or in any other way;`,
        es: `m) Recuperar sistemáticamente datos u otro contenido de nuestra plataforma o servicios para crear o compilar, directa o indirectamente, en descargas únicas o múltiples, la colección, compilación, base de datos, directorio o similar, ya sea por métodos manuales, a través del uso de bots, crawlers o spiders, o de cualquier otra manera;`
      },
      blockp59: {
        pt: `n) Tentar decifrar, descompilar, desmontar ou fazer engenharia reversa de qualquer software usado, para fornecer a plataforma, serviços ou conteúdos;`,
        en: `n) Attempt to decipher, decompile, disassemble, or reverse engineer any software used to provide the platform, services, or content;`,
        es: `n) Intentar descifrar, descompilar, desmontar o realizar ingeniería inversa de cualquier software utilizado para proporcionar la plataforma, servicios o contenido;`
      },
      blockp60: {
        pt: `o) Personificar qualquer pessoa ou entidade, falsificar ou adulterar a si mesmo ou sua afiliação com qualquer pessoa ou entidade;`,
        en: `o) Impersonate any person or entity, falsify or alter yourself or your affiliation with any person or entity;`,
        es: `o) Suplantar a cualquier persona o entidad, falsificar o alterar su identidad o su afiliación con cualquier persona o entidad;`
      },
      blockt12: {
        pt: `10. DA PROPRIEDADE INTELECTUAL`,
        en: `10. INTELLECTUAL PROPERTY`,
        es: `10. PROPIEDAD INTELECTUAL`
      },
      blockp61: {
        pt: `O presente Termo de Uso concede aos usuários uma licença não exclusiva, não transferível e não sublicenciável, para acessar e fazer uso da plataforma e dos serviços por ela disponibilizados.`,
        en: `These Terms of Use grant users a non-exclusive, non-transferable, and non-sublicensable license to access and use the platform and the services it provides.`,
        es: `Estos Términos de Uso otorgan a los usuarios una licencia no exclusiva, no transferible y no sublicenciable para acceder y utilizar la plataforma y los servicios que proporciona.`
      },
      blockp62: {
        pt: `A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da ROMPER observados os termos da Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software (Lei nº 9.609/98), estão devidamente reservados.`,
        en: `The structure of the website or application, trademarks, logos, trade names, layouts, graphics and interface design, images, illustrations, photographs, presentations, videos, written and audio content, computer programs, databases, transmission files, and any other information and intellectual property rights of ROMPER, subject to the terms of the Industrial Property Law (Law No. 9,279/96), Copyright Law (Law No. 9,610/98), and Software Law (Law No. 9,609/98), are duly reserved.`,
        es: `La estructura del sitio web o la aplicación, marcas comerciales, logotipos, nombres comerciales, diseños de interfaz gráfica, imágenes, ilustraciones, fotografías, presentaciones, videos, contenido escrito y de audio, programas informáticos, bases de datos, archivos de transmisión y cualquier otra información y derechos de propiedad intelectual de ROMPER, sujetos a los términos de la Ley de Propiedad Industrial (Ley N.º 9.279/96), Ley de Derechos de Autor (Ley N.º 9.610/98) y Ley de Software (Ley N.º 9.609/98), están debidamente reservados.`
      },
      blockp63: {
        pt: `O usuário NÃO tem o direito de:`,
        en: `The user does NOT have the right to:`,
        es: `El usuario NO tiene derecho a:`
      },
      blockp64: {
        pt: `a) Sublicenciar os direitos de licença concedidos nesta seção;`,
        en: `a) Sub-license the license rights granted in this section;`,
        es: `a) Sub-licenciar los derechos de licencia otorgados en esta sección;`
      },
      blockp65: {
        pt: `b) Usar, copiar, adaptar, modificar, preparar trabalhos derivados com base, distribuir, licenciar, vender, transferir, exibir publicamente, executar publicamente, transmitir ou explorar a Plataforma, Serviços ou Conteúdos, exceto se expressamente permitido nestes Termos;`,
        en: `b) Use, copy, adapt, modify, prepare derivative works based on, distribute, license, sell, transfer, publicly display, publicly perform, transmit, or otherwise exploit the Platform, Services, or Content, except as expressly permitted in these Terms;`,
        es: `b) Utilizar, copiar, adaptar, modificar, preparar obras derivadas basadas en, distribuir, licenciar, vender, transferir, mostrar públicamente, ejecutar públicamente, transmitir o explotar de otro modo la Plataforma, Servicios o Contenido, excepto según lo permitido expresamente en estos Términos;`
      },
      blockp66: {
        pt: `c) Remover, alterar ou ocultar quaisquer direitos autorais, marca registrada, marca de serviço ou outros direitos de propriedade incorporadas ou acompanhando a Plataforma, Serviços ou Conteúdos.`,
        en: `c) Remove, alter, or conceal any copyright, trademark, service mark, or other proprietary rights incorporated or accompanying the Platform, Services, or Content.`,
        es: `c) Eliminar, alterar u ocultar cualquier derecho de autor, marca registrada, marca de servicio u otros derechos de propiedad incorporados o que acompañen a la Plataforma, Servicios o Contenido.`
      },
      blockp67: {
        pt: `Nenhuma licença ou direitos são concedidos por implicação ou sob quaisquer direitos de propriedade intelectual pertencentes ou controlados pela ROMPER ou de seus licenciadores, exceto para as licenças e direitos expressamente concedidos nestes Termos.`,
        en: `No license or rights are granted by implication or under any intellectual property rights owned or controlled by ROMPER or its licensors, except for the licenses and rights expressly granted in these Terms.`,
        es: `No se otorgan licencias ni derechos por implicación o en virtud de ningún derecho de propiedad intelectual propiedad de ROMPER o controlado por ella o sus licenciantes, excepto las licencias y derechos expresamente otorgados en estos Términos.`
      },
      blockp68: {
        pt: `Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário.`,
        en: `These Terms of Use do not assign or transfer any rights to the user, so access does not generate any intellectual property rights for the user.`,
        es: `Estos Términos de Uso no asignan ni transfieren ningún derecho al usuario, por lo que el acceso no genera ningún derecho de propiedad intelectual para el usuario.`
      },
      blockp69: {
        pt: `O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos de propriedade intelectual da ROMPER, puníveis nos termos da legislação aplicável.`,
        en: `The user's use of the platform is personal, individual, and non-transferable, and any unauthorized use, whether commercial or non-commercial, is prohibited. Such uses will constitute a violation of ROMPER's intellectual property rights, punishable under applicable law.`,
        es: `El uso de la plataforma por parte del usuario es personal, individual e intransferible, y se prohíbe cualquier uso no autorizado, ya sea comercial o no comercial. Dichos usos constituirán una violación de los derechos de propiedad intelectual de ROMPER, sancionable según la ley aplicable.`
      },
      blockp70: {
        pt: `Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos de Uso, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.`,
        en: `Any intrusion, attempt, or activity that violates or contravenes intellectual property laws and/or the prohibitions stipulated in these Terms of Use will render the responsible party subject to the relevant legal actions and sanctions provided herein, and they will also be liable for compensation for any damages caused.`,
        es: `Cualquier intrusión, intento o actividad que viole o contravenga las leyes de propiedad intelectual y/o las prohibiciones estipuladas en estos Términos de Uso harán que la parte responsable esté sujeta a las acciones legales pertinentes y sanciones proporcionadas en este documento, y también serán responsables de la compensación por los daños causados, si los hubiera.`
      },
      blockt13: {
        pt: `11. DAS ALTERAÇÕES`,
        en: `11. CHANGES`,
        es: `11. CAMBIOS`
      },
      blockp71: {
        pt: `Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a qualquer tempo, por parte da ROMPER para adequar ou modificar os serviços, bem como para atender novas exigências legais. As alterações serão veiculadas pelo site ou aplicativo e o usuário poderá optar por aceitar o novo conteúdo ou não.`,
        en: `The items described in this instrument may be changed unilaterally and at any time by ROMPER to adapt or modify the services, as well as to comply with new legal requirements. The changes will be published on the website or application, and the user may choose to accept the new content or not.`,
        es: `Los elementos descritos en el presente instrumento pueden ser modificados unilateralmente y en cualquier momento por ROMPER para adaptar o modificar los servicios, así como para cumplir con nuevos requisitos legales. Los cambios se publicarán en el sitio web o la aplicación y el usuario podrá optar por aceptar o no el nuevo contenido.`
      },
      blockt14: {
        pt: `12. DA POLÍTICA DE PRIVACIDADE`,
        en: `12. PRIVACY POLICY`,
        es: `12. POLÍTICA DE PRIVACIDAD`
      },
      blockp72: {
        pt: `Além do presente Termo, o usuário deverá consentir com as disposições contidas na respectiva Política de Privacidade a ser apresentada a todos os interessados dentro da interface da plataforma.`,
        en: `In addition to these Terms, the user must consent to the provisions contained in the respective Privacy Policy to be presented to all interested parties within the platform's interface.`,
        es: `Además de estos Términos, el usuario debe dar su consentimiento a las disposiciones contenidas en la respectiva Política de Privacidad que se presentará a todas las partes interesadas dentro de la interfaz de la plataforma.`
      },
      blockt15: {
        pt: `13. DO FORO`,
        en: `13. JURISDICTION`,
        es: `13. JURISDICCIÓN`
      },
      blockp73: {
        pt: `Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro. Outrossim, os eventuais litígios deverão ser apresentados no foro da comarca de Cascavel/PR.`,
        en: `For the resolution of disputes arising from this instrument, Brazilian law shall be fully applied. Furthermore, any disputes should be brought before the court of Cascavel/PR.`,
        es: `Para la resolución de disputas derivadas de este instrumento, se aplicará plenamente la ley brasileña. Además, cualquier disputa deberá ser presentada ante el tribunal de Cascavel/PR.`
      },
      blockt8: {
        pt: `7. DO SUPORTE`,
        en: `7. SUPPORT`,
        es: `7. SOPORTE`
    },
    blockt9: {
        pt: `8. DAS RESPONSABILIDADES`,
        en: `8. RESPONSIBILITIES`,
        es: `8. RESPONSABILIDADES`
    }, 
    blockp32: {
        pt: `Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário poderá entrar em contato com o suporte, através do e-mail comercial@romper.com.br OU telefone (45) 3306-6454.`,
        en: `In case of any questions, suggestions, or issues with the use of the platform, the user can contact support via email at comercial@romper.com.br OR phone (45) 3306-6454.`,
        es: `En caso de cualquier duda, sugerencia o problema con el uso de la plataforma, el usuario puede ponerse en contacto con el soporte a través del correo electrónico comercial@romper.com.br O el teléfono (45) 3306-6454.`
    }, 
    blockp33: {
        pt: `Estes serviços de atendimento ao usuário estarão disponíveis nos seguintes dias e horários: ____.`,
        en: `These user support services will be available on the following days and hours: ____.`,
        es: `Estos servicios de atención al usuario estarán disponibles en los siguientes días y horarios: ____.`
    },
    blockp34: {
        pt: `O usuário é responsável e se obriga a:`,
        en: `The user is responsible and undertakes to:`,
        es: `El usuario es responsable y se compromete a:`
    },
    blockp35: {
        pt: `a) Utilizar-se das funcionalidades oferecidas pela Plataforma em estrita conformidade com a legislação vigente e aplicável à atividade exercida, sendo, pois, o único e exclusivo responsável pela licitude e adequação de suas atividades.`,
        en: `a) Use the features offered by the Platform in strict compliance with the current and applicable legislation to the activity carried out, being the sole and exclusive responsible for the legality and suitability of its activities.`,
        es: `a) Utilice las funcionalidades ofrecidas por la Plataforma en estricto cumplimiento de la legislación vigente y aplicable a la actividad realizada, siendo el único y exclusivo responsable de la legalidad y adecuación de sus actividades.`
    },
    blockp36: {
        pt: `b) Reconhecer que todos os acessos realizados por meio de sua Conta de Acesso na Plataforma são interpretados como tendo sido feitos pelo próprio usuário e/ou seus responsáveis legais de forma incontestável e de sua integral responsabilidade;`,
        en: `b) Acknowledge that all accesses made through their Access Account on the Platform are interpreted as having been made by the user and/or their legal representatives indisputably and entirely at their responsibility;`,
        es: `b) Reconocer que todos los accesos realizados a través de su Cuenta de Acceso en la Plataforma se interpretan como si hubieran sido realizados por el propio usuario y/o sus representantes legales de manera indiscutible y bajo su total responsabilidad;`
    }
    
       
};  

const TermosDeUso = () => {
  const { language } = useContext(LocalizeContext);

  return (
    <div className={styles['container']}>
        <Header TipoHeader={1} />
        <div className={styles['conteudo']}>

            <div className={styles['row']}>
                <div className={styles['text-center']}>
                    <h2 className={styles['section_title']}>{termosDeUsoTexts['section_title'][language]}</h2>
                </div>
            </div>

            <div className={styles['row']}>

                <div className={styles['col-12']}>

                    <div className={styles['block']}>
                        <p>{termosDeUsoTexts['blockp1_p1'][language]} <b>sysRomper</b> {termosDeUsoTexts['blockp1_p2'][language]} <b>ROMPER ADMINISTRADORA DE MARCAS EIRELI</b>{termosDeUsoTexts['blockp1_p3'][language]}</p>
                        <p>{termosDeUsoTexts['blockp2'][language]}</p>
                        <p>{termosDeUsoTexts['blockp3'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt1'][language]}</h3>

                        <p>{termosDeUsoTexts['blockp4_p1'][language]} <i>{termosDeUsoTexts['blockp4_p2'][language]}</i>{termosDeUsoTexts['blockp4_p3'][language]}</p>

                        <p>{termosDeUsoTexts['blockp5_p1'][language]} <b>sysRomper</b>{termosDeUsoTexts['blockp5_p2'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt2'][language]}</h3>

                        <p>{termosDeUsoTexts['blockp6'][language]}</p>

                        <p>{termosDeUsoTexts['blockp7_p1'][language]} <b>sysRomper</b> {termosDeUsoTexts['blockp7_p2'][language]}</p>

                        <ol>
                            <li>{termosDeUsoTexts['blockli1'][language]}</li>
                            <li>{termosDeUsoTexts['blockli2'][language]}</li>
                            <li>{termosDeUsoTexts['blockli3'][language]}</li>
                            <li>{termosDeUsoTexts['blockli4'][language]}</li>
                            <li>{termosDeUsoTexts['blockli5'][language]}</li>
                            <li>{termosDeUsoTexts['blockli6'][language]}</li>
                            <li>{termosDeUsoTexts['blockli7'][language]}</li>
                            <li>{termosDeUsoTexts['blockli8'][language]}</li>
                            <li>{termosDeUsoTexts['blockli9'][language]}</li>
                            <li>{termosDeUsoTexts['blockli10'][language]}</li>
                            <li>{termosDeUsoTexts['blockli11'][language]}</li>
                            <li>{termosDeUsoTexts['blockli12'][language]}</li>
                            <li>{termosDeUsoTexts['blockli13'][language]}</li>
                            <li>{termosDeUsoTexts['blockli14'][language]}</li>
                            <li>{termosDeUsoTexts['blockli15'][language]}</li>
                            <li>{termosDeUsoTexts['blockli16'][language]}</li>
                            <li>{termosDeUsoTexts['blockli17'][language]}</li>
                            <li>{termosDeUsoTexts['blockli18'][language]}</li>
                            <li>{termosDeUsoTexts['blockli19'][language]}</li>
                            <li>{termosDeUsoTexts['blockli20'][language]}</li>
                            <li>{termosDeUsoTexts['blockli21'][language]}</li>
                            <li>{termosDeUsoTexts['blockli22'][language]}</li>
                            <li>{termosDeUsoTexts['blockli23'][language]}</li>
                            <li>{termosDeUsoTexts['blockli24'][language]}</li>
                            <li>{termosDeUsoTexts['blockli25'][language]}</li>
                            <li>{termosDeUsoTexts['blockli26'][language]}</li>
                            <li>{termosDeUsoTexts['blockli27'][language]};</li>
                            <li>{termosDeUsoTexts['blockli28'][language]}</li>
                        </ol>

                        <h3>{termosDeUsoTexts['blockt3'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp8'][language]}</p>
                        <p>{termosDeUsoTexts['blockp9'][language]}</p>
                        <p>{termosDeUsoTexts['blockp10'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt4'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp11'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt5'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp12'][language]}</p>
                        <p>{termosDeUsoTexts['blockp13'][language]}</p>
                        <p>{termosDeUsoTexts['blockp14'][language]}</p>
                        <p>{termosDeUsoTexts['blockp15'][language]}</p>
                        <p>{termosDeUsoTexts['blockp16'][language]}</p>
                        <p>{termosDeUsoTexts['blockp17'][language]}</p>
                        <p>{termosDeUsoTexts['blockp18'][language]}</p>
                        <p>{termosDeUsoTexts['blockp19'][language]}</p>
                        <p>{termosDeUsoTexts['blockp20'][language]}</p>
                        <p>{termosDeUsoTexts['blockp21'][language]}</p>
                        <p>{termosDeUsoTexts['blockp22'][language]}</p>
                        <p>{termosDeUsoTexts['blockp23'][language]}</p>
                        <p>{termosDeUsoTexts['blockp24'][language]}</p>
                        <p>{termosDeUsoTexts['blockp25'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt6'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp26'][language]}</p>
                        <p>{termosDeUsoTexts['blockp27'][language]}</p>
                        <h3>{termosDeUsoTexts['blockt7'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp28'][language]}</p>
                        <p>{termosDeUsoTexts['blockp29'][language]}</p>
                        <p>{termosDeUsoTexts['blockp30'][language]}</p>
                        <p>{termosDeUsoTexts['blockp31'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt8'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp32'][language]}</p>
                        <p>{termosDeUsoTexts['blockp33'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt9'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp34'][language]}</p>
                        <p>{termosDeUsoTexts['blockp35'][language]}</p>
                        <p>{termosDeUsoTexts['blockp36'][language]}</p>
                        <p>{termosDeUsoTexts['blockp37'][language]}</p>
                        <p>{termosDeUsoTexts['blockp38'][language]}</p>
                        <p>{termosDeUsoTexts['blockp39'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt10'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp40'][language]}</p>
                        <p>{termosDeUsoTexts['blockp41'][language]}</p>
                        <p>{termosDeUsoTexts['blockp42'][language]}</p>
                        <p>{termosDeUsoTexts['blockp43'][language]}</p>
                        <p>{termosDeUsoTexts['blockp44'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt11'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp44'][language]}</p>
                        <p>{termosDeUsoTexts['blockp45'][language]}</p>
                        <p>{termosDeUsoTexts['blockp46'][language]}</p>
                        <p>{termosDeUsoTexts['blockp47'][language]}</p>
                        <p>{termosDeUsoTexts['blockp48'][language]}</p>
                        <p>{termosDeUsoTexts['blockp49'][language]}</p>
                        <p>{termosDeUsoTexts['blockp50'][language]}</p>
                        <p>{termosDeUsoTexts['blockp51'][language]}</p>
                        <p>{termosDeUsoTexts['blockp52'][language]}</p>
                        <p>{termosDeUsoTexts['blockp53'][language]}</p>
                        <p>{termosDeUsoTexts['blockp54'][language]}</p>
                        <p>{termosDeUsoTexts['blockp55'][language]}</p>
                        <p>{termosDeUsoTexts['blockp56'][language]}</p>
                        <p>{termosDeUsoTexts['blockp57'][language]}</p>
                        <p>{termosDeUsoTexts['blockp58'][language]}</p>
                        <p>{termosDeUsoTexts['blockp59'][language]}</p>
                        <p>{termosDeUsoTexts['blockp60'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt12'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp61'][language]}</p>
                        <p>{termosDeUsoTexts['blockp62'][language]}</p>

                        <p>{termosDeUsoTexts['blockp63'][language]}</p>
                        <p>{termosDeUsoTexts['blockp64'][language]}</p>
                        <p>{termosDeUsoTexts['blockp65'][language]}</p>
                        <p>{termosDeUsoTexts['blockp66'][language]}</p>
                        <p>{termosDeUsoTexts['blockp67'][language]}</p>
                        <p>{termosDeUsoTexts['blockp68'][language]}</p>
                        <p>{termosDeUsoTexts['blockp69'][language]}</p>
                        <p>{termosDeUsoTexts['blockp70'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt13'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp71'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt13'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp72'][language]}</p>

                        <h3>{termosDeUsoTexts['blockt14'][language]}</h3>
                        <p>{termosDeUsoTexts['blockp73'][language]}</p>
                    </div>

                </div>

            </div>

        </div>
        <Footer />
    </div>
  );
};

export default TermosDeUso;
