import React, { useState, useContext } from 'react'
import { LocalizeContext } from '../localizeContext';

import Header from '../components/header'
import Footer from '../components/footer'
import styles from './compliance.module.css'

const complianceTexts = {
  'titulo': {
    pt: 'Compromisso e ética',
    en: 'Commitment and Ethics',
    es: 'Compromiso y Ética'
  },
  'titulo01': {
    pt: 'Nosso propósito',
    en: 'Our Purpose',
    es: 'Nuestro Propósito'
  },
  'titulo02': {
    pt: 'Responsabilidade social e ambiental',
    en: 'Social and Environmental Responsibility',
    es: 'Responsabilidad Social y Ambiental'
  },
  'titulo03': {
    pt: 'Código de conduta e ética',
    en: 'Code of Conduct and Ethics',
    es: 'Código de Conducta y Ética'
  },
  'titulo04': {
    pt: 'Relacionamentos pessoais',
    en: 'Personal Relationships',
    es: 'Relaciones Personales'
  },
  'titulo05': {
    pt: 'Sigilo de informações',
    en: 'Confidentiality of Information',
    es: 'Confidencialidad de la Información'
  },
  'titulo06': {
    pt: 'Concorrência e o mercado',
    en: 'Competition and the Market',
    es: 'Competencia y el Mercado'
  },
  'titulo07': {
    pt: 'Relação com nossos clientes',
    en: 'Relationship with Our Customers',
    es: 'Relación con Nuestros Clientes'
  },
  'titulo08': {
    pt: 'Colabore',
    en: 'Collaborate',
    es: 'Colabore'
  },
  'titulo09': {
    pt: 'Compromisso contínuo com a ética e a conformidade',
    en: 'Ongoing Commitment to Ethics and Compliance',
    es: 'Compromiso Continuo con la Ética y el Cumplimiento'
  },
  'text01': {
    pt: 'Como presidente da Romper, gostaria de reforçar o nosso compromisso com a integridade e a ética nos negócios. Estamos dedicados a manter um ambiente de trabalho seguro e respeitoso para todos os nossos colaboradores e parceiros de negócios. Para isso, temos um Código de Conduta e Ética que orienta a conduta de todos os nossos colaboradores e estabelece diretrizes claras para o cumprimento das leis e regulamentos aplicáveis. Juntos, podemos construir uma empresa mais forte e íntegra, baseada em valores sólidos e coerentes com a nossa missão de proporcionar soluções de qualidade para nossos clientes.',
    en: 'As the president of Romper, I would like to reinforce our commitment to integrity and ethics in business. We are dedicated to maintaining a safe and respectful work environment for all our employees and business partners. For this, we have a Code of Conduct and Ethics that guides the behavior of all our employees and establishes clear guidelines for compliance with applicable laws and regulations. Together, we can build a stronger and more integral company, based on solid values and consistent with our mission to provide quality solutions to our customers.',
    es: 'Como presidente de Romper, me gustaría reforzar nuestro compromiso con la integridad y la ética en los negocios. Estamos dedicados a mantener un ambiente de trabajo seguro y respetuoso para todos nuestros empleados y socios comerciales. Para ello, tenemos un Código de Conducta y Ética que guía el comportamiento de todos nuestros empleados y establece directrices claras para el cumplimiento de las leyes y regulaciones aplicables. Juntos, podemos construir una empresa más fuerte e íntegra, basada en valores sólidos y coherentes con nuestra misión de proporcionar soluciones de calidad a nuestros clientes.'
  },
  'text02': {
    pt: 'O Grupo Romper é formado por profissionais apaixonados em criar soluções inovadoras para transformar o mercado e a sociedade. Nosso propósito é gerar valor e bem-estar para todos os envolvidos em nossos negócios, desde nossos colaboradores e fornecedores até nossos clientes e a comunidade como um todo. Buscamos atender às necessidades de nossos clientes com excelência e transparência, mantendo a ética e a integridade em todas as nossas operações. Nosso objetivo é construir um futuro melhor, onde a inovação, a sustentabilidade e o respeito às pessoas e ao meio ambiente estejam no centro de nossas atividades. Acreditamos que com comprometimento e dedicação, podemos contribuir para a construção de um mundo mais justo e próspero.',
    en: 'The Romper Group is made up of professionals passionate about creating innovative solutions to transform the market and society. Our purpose is to generate value and well-being for everyone involved in our business, from our employees and suppliers to our customers and the community as a whole. We strive to meet the needs of our customers with excellence and transparency, maintaining ethics and integrity in all our operations. Our goal is to build a better future where innovation, sustainability, and respect for people and the environment are at the center of our activities. We believe that with commitment and dedication, we can contribute to building a fairer and more prosperous world.',
    es: 'El Grupo Romper está formado por profesionales apasionados por crear soluciones innovadoras para transformar el mercado y la sociedad. Nuestro propósito es generar valor y bienestar para todos los involucrados en nuestro negocio, desde nuestros empleados y proveedores hasta nuestros clientes y la comunidad en general. Nos esforzamos por satisfacer las necesidades de nuestros clientes con excelencia y transparencia, manteniendo la ética y la integridad en todas nuestras operaciones. Nuestro objetivo es construir un futuro mejor donde la innovación, la sostenibilidad y el respeto a las personas y al medio ambiente estén en el centro de nuestras actividades. Creemos que con compromiso y dedicación, podemos contribuir a la construcción de un mundo más justo y próspero.'
  },
  'text03': {
    pt: 'Somos apaixonados pelo Brasil e pelas pessoas que habitam este maravilhoso país. Acreditamos que a responsabilidade social e ambiental são fundamentais para o desenvolvimento sustentável e bem-estar da sociedade. Estamos comprometidos em fazer a nossa parte e contribuir positivamente para a sociedade, promovendo ações que impactem positivamente as pessoas e o meio ambiente. É com muito orgulho que nos esforçamos diariamente para construir um futuro melhor para as gerações futuras. Essa paixão pelo Brasil nos move e nos inspira a sempre buscar soluções inovadoras e criativas para os desafios do nosso tempo, mantendo sempre em mente a preservação do meio ambiente e o bem-estar das pessoas.',
    en: 'We are passionate about Brazil and the people who inhabit this wonderful country. We believe that social and environmental responsibility are fundamental for sustainable development and the well-being of society. We are committed to doing our part and contributing positively to society, promoting actions that positively impact people and the environment. It is with great pride that we strive daily to build a better future for future generations. This passion for Brazil moves and inspires us to always seek innovative and creative solutions for the challenges of our time, always keeping in mind the preservation of the environment and the well-being of people.',
    es: 'Somos apasionados por Brasil y por las personas que habitan este maravilloso país. Creemos que la responsabilidad social y ambiental son fundamentales para el desarrollo sostenible y el bienestar de la sociedad. Estamos comprometidos a hacer nuestra parte y contribuir positivamente a la sociedad, promoviendo acciones que impacten positivamente a las personas y al medio ambiente. Es con gran orgullo que nos esforzamos diariamente para construir un mejor futuro para las generaciones futuras. Esta pasión por Brasil nos mueve e inspira a buscar siempre soluciones innovadoras y creativas para los desafíos de nuestro tiempo, manteniendo siempre en mente la preservación del medio ambiente y el bienestar de las personas.'
  },
  'text04': {
    pt: 'O Código de Conduta e Ética do Grupo Romper é um guia para a conduta profissional e pessoal de todos os colaboradores da empresa, com o objetivo de promover a integridade, a transparência e a ética em todas as atividades. Acreditamos que a reputação e a imagem da empresa são construídas a partir das ações diárias de nossos colaboradores, e por isso temos o compromisso de atuar de forma íntegra em todos os nossos relacionamentos. O Código de Conduta e Ética é uma ferramenta importante para ajudar a identificar e lidar com situações de conflito ou que possam comprometer a nossa integridade, além de nortear nossas decisões e ações no dia a dia. O cumprimento deste código é obrigatório para todos os colaboradores, e esperamos que cada um deles atue como um exemplo de conduta ética e profissional. Acreditamos que, juntos, podemos criar um ambiente de trabalho saudável e ético, que contribua para o desenvolvimento sustentável do nosso país e para a construção de um futuro melhor para todos.',
    en: "The Code of Conduct and Ethics of Romper Group is a guide for the professional and personal conduct of all the company's employees, aimed at promoting integrity, transparency, and ethics in all activities. We believe that the reputation and image of the company are built from the daily actions of our employees, and therefore we are committed to acting with integrity in all our relationships. The Code of Conduct and Ethics is an important tool to help identify and deal with conflict situations or those that may compromise our integrity, as well as to guide our decisions and actions in daily life. Compliance with this code is mandatory for all employees, and we expect each of them to act as an example of ethical and professional conduct. We believe that, together, we can create a healthy and ethical work environment that contributes to the sustainable development of our country and to building a better future for everyone.",
    es: 'El Código de Conducta y Ética del Grupo Romper es una guía para la conducta profesional y personal de todos los empleados de la empresa, con el objetivo de promover la integridad, la transparencia y la ética en todas las actividades. Creemos que la reputación y la imagen de la empresa se construyen a partir de las acciones diarias de nuestros empleados, y por eso estamos comprometidos a actuar con integridad en todas nuestras relaciones. El Código de Conducta y Ética es una herramienta importante para ayudar a identificar y tratar situaciones de conflicto o que puedan comprometer nuestra integridad, además de orientar nuestras decisiones y acciones en la vida cotidiana. El cumplimiento de este código es obligatorio para todos los empleados, y esperamos que cada uno de ellos actúe como un ejemplo de conducta ética y profesional. Creemos que, juntos, podemos crear un ambiente de trabajo saludable y ético que contribuya al desarrollo sostenible de nuestro país y a la construcción de un futuro mejor para todos.'
  },
  'text55': {
    pt: 'Relacionamentos interpessoais saudáveis são fundamentais para a convivência em equipe. Em nosso ambiente de trabalho, valorizamos o respeito, a empatia e a sensibilidade em relação aos colegas. É importante lembrar que o ambiente descontraído não é sinônimo de falta de profissionalismo, por isso pedimos que tenham sensibilidade e bom senso. Não admitimos qualquer forma de preconceito ou discriminação. Também proibimos o uso de álcool e drogas em nossas instalações. Como colaboradores, temos a responsabilidade de agir como líderes, dando exemplos e demonstrando comprometimento com os princípios éticos da empresa. O compartilhamento de informações deve ser autorizado pelos envolvidos e confirmado como verídico. Não toleramos fofocas, boatos ou qualquer comportamento que possa causar constrangimento. Além disso, zelamos pelos nossos bens e recursos, utilizando-os apenas para os fins a que se destinam e mantendo-os sempre em bom estado. Proteger nossa estratégia é fundamental para crescermos juntos, por isso, mantemos em sigilo nossos negócios e operações. Visitantes são bem-vindos, mas é importante que circulem acompanhados de nossos colaboradores. Nosso monitoramento de computadores é uma forma de garantir a segurança da informação e proteger nossa empresa.',
    en: "Healthy interpersonal relationships are fundamental for teamwork. In our work environment, we value respect, empathy, and sensitivity towards colleagues. It's important to remember that a relaxed atmosphere is not synonymous with a lack of professionalism, so we ask for sensitivity and good sense. We do not admit any form of prejudice or discrimination. We also prohibit the use of alcohol and drugs on our premises. As employees, we have the responsibility to act as leaders, setting examples and demonstrating commitment to the ethical principles of the company. The sharing of information must be authorized by those involved and confirmed as true. We do not tolerate gossip, rumors, or any behavior that may cause embarrassment. In addition, we take care of our assets and resources, using them only for their intended purposes and always keeping them in good condition. Protecting our strategy is essential for growing together, so we keep our business and operations confidential. Visitors are welcome, but it is important that they are accompanied by our employees. Our computer monitoring is a way to ensure information security and protect our company.",
    es: 'Las relaciones interpersonales saludables son fundamentales para el trabajo en equipo. En nuestro entorno laboral, valoramos el respeto, la empatía y la sensibilidad hacia los colegas. Es importante recordar que un ambiente relajado no es sinónimo de falta de profesionalismo, por lo que pedimos sensibilidad y buen juicio. No admitimos ninguna forma de prejuicio o discriminación. También prohibimos el uso de alcohol y drogas en nuestras instalaciones. Como empleados, tenemos la responsabilidad de actuar como líderes, dando ejemplos y demostrando compromiso con los principios éticos de la empresa. El intercambio de información debe ser autorizado por los involucrados y confirmado como verdadero. No toleramos chismes, rumores o cualquier comportamiento que pueda causar vergüenza. Además, cuidamos nuestros bienes y recursos, usándolos solo para los fines para los que están destinados y manteniéndolos siempre en buen estado. Proteger nuestra estrategia es esencial para crecer juntos, por lo que mantenemos confidencialidad en nuestros negocios y operaciones. Los visitantes son bienvenidos, pero es importante que estén acompañados por nuestros empleados. Nuestro monitoreo de computadoras es una forma de garantizar la seguridad de la información y proteger nuestra empresa.'
  },
  'text06': {
    pt: 'Proteger as informações confidenciais e sigilosas é de extrema importância para o Grupo Romper. Como parte do nosso compromisso com a segurança da informação, é fundamental que todos os colaboradores atuem com diligência e responsabilidade ao lidar com informações sensíveis. Para garantir essa proteção, disponibilizamos mecanismos de segurança e orientações específicas sobre o manuseio de dados, senhas e informações privilegiadas. Além disso, seguimos todas as orientações previstas pela Lei Geral de Proteção de Dados (LGPD), que assegura o sigilo das informações pessoais de nossos clientes, parceiros e colaboradores. É importante lembrar que qualquer abuso ou uso indevido dessas informações é inaceitável e pode comprometer a confiança depositada em nós. Por isso, devemos manter sigilo sobre nossas informações e evitar conversas e comentários sobre a plataforma e clientes em locais públicos. Juntos, podemos garantir a proteção dos dados e a segurança de nossos negócios.',
    en: "Protecting confidential and sensitive information is of utmost importance to the Romper Group. As part of our commitment to information security, it is essential that all employees act with diligence and responsibility when handling sensitive information. To ensure this protection, we provide security mechanisms and specific guidelines on handling data, passwords, and privileged information. Moreover, we follow all the guidelines set by the General Data Protection Law (GDPR), which ensures the confidentiality of personal information of our clients, partners, and employees. It's important to remember that any abuse or misuse of this information is unacceptable and can compromise the trust placed in us. Therefore, we must maintain confidentiality about our information and avoid discussions and comments about the platform and clients in public places. Together, we can ensure the protection of data and the security of our business.",
    es: 'Proteger la información confidencial y sensible es de suma importancia para el Grupo Romper. Como parte de nuestro compromiso con la seguridad de la información, es esencial que todos los empleados actúen con diligencia y responsabilidad al manejar información sensible. Para garantizar esta protección, proporcionamos mecanismos de seguridad y directrices específicas sobre el manejo de datos, contraseñas e información privilegiada. Además, seguimos todas las directrices establecidas por la Ley General de Protección de Datos (GDPR), que asegura la confidencialidad de la información personal de nuestros clientes, socios y empleados. Es importante recordar que cualquier abuso o mal uso de esta información es inaceptable y puede comprometer la confianza depositada en nosotros. Por lo tanto, debemos mantener la confidencialidad sobre nuestra información y evitar discusiones y comentarios sobre la plataforma y los clientes en lugares públicos. Juntos, podemos garantizar la protección de los datos y la seguridad de nuestro negocio.'
  },
  'text16': {
    pt: 'No Grupo Romper, acreditamos que a competição é um fator fundamental para a liberdade de escolha dos clientes e usuários. No entanto, é imprescindível que essa competição se dê de maneira ética e responsável, evitando-se práticas que possam prejudicar a dinâmica competitiva do mercado. Assim, rejeitamos firmemente qualquer tipo de concorrência desleal que busque vantagens competitivas por meio de meios antiéticos ou ilegais. Também não toleramos a formação de cartéis, que prejudicam os compradores de bens ou serviços e criam um ambiente de jogo de cartas marcadas. Ademais, não permitimos práticas unilaterais que possam prejudicar a dinâmica competitiva em mercados nos quais temos relevante participação. Sendo assim, agimos em conformidade com os princípios da livre concorrência e respeitamos a reputação e opiniões dos nossos concorrentes.',
    en: 'At Romper Group, we believe that competition is a fundamental factor for the freedom of choice of customers and users. However, it is imperative that this competition is conducted in an ethical and responsible manner, avoiding practices that may harm the competitive dynamics of the market. Therefore, we firmly reject any form of unfair competition that seeks competitive advantages through unethical or illegal means. We also do not tolerate the formation of cartels, which harm buyers of goods or services and create an environment of rigged competition. Moreover, we do not allow unilateral practices that may harm the competitive dynamics in markets where we have significant participation. Thus, we act in accordance with the principles of free competition and respect the reputation and opinions of our competitors.',
    es: 'En el Grupo Romper, creemos que la competencia es un factor fundamental para la libertad de elección de los clientes y usuarios. Sin embargo, es imprescindible que esta competencia se lleve a cabo de manera ética y responsable, evitando prácticas que puedan dañar la dinámica competitiva del mercado. Por lo tanto, rechazamos firmemente cualquier forma de competencia desleal que busque ventajas competitivas a través de medios no éticos o ilegales. Tampoco toleramos la formación de cárteles, que perjudican a los compradores de bienes o servicios y crean un ambiente de competencia amañada. Además, no permitimos prácticas unilaterales que puedan dañar la dinámica competitiva en mercados donde tenemos una participación significativa. Así, actuamos de acuerdo con los principios de la libre competencia y respetamos la reputación y opiniones de nuestros competidores.'
  },
  'text18': {
    pt: 'O cuidado e transparência com nossos clientes são valores fundamentais para o Grupo Romper. Acreditamos que a confiança dos nossos clientes é a base do nosso sucesso e para isso, estamos comprometidos em oferecer soluções inovadoras e respeitar os mais altos padrões éticos e legais. Somos uma empresa despojada e moderna, que busca sempre estar à frente das tendências e inovar em nossos serviços, sem jamais deixar de lado a seriedade e a transparência. Temos um compromisso inabalável com a integridade e a transparência em todas as nossas relações comerciais, prezando pela clareza e pelo respeito nas informações que compartilhamos com nossos clientes. Com o Grupo Romper, nossos clientes podem confiar em uma marca forte e comprometida com a excelência em serviços, sempre seguindo as melhores práticas do mercado.',
    en: 'Care and transparency with our customers are fundamental values for the Romper Group. We believe that the trust of our customers is the foundation of our success, and to this end, we are committed to offering innovative solutions and respecting the highest ethical and legal standards. We are a modern and dynamic company, always striving to stay ahead of trends and innovate in our services, without ever losing sight of seriousness and transparency. We have an unwavering commitment to integrity and transparency in all our business relationships, valuing clarity and respect in the information we share with our customers. With the Romper Group, our customers can trust in a strong brand committed to service excellence, always following the best market practices.',
    es: 'El cuidado y la transparencia con nuestros clientes son valores fundamentales para el Grupo Romper. Creemos que la confianza de nuestros clientes es la base de nuestro éxito, y para ello, estamos comprometidos a ofrecer soluciones innovadoras y respetar los más altos estándares éticos y legales. Somos una empresa moderna y dinámica, siempre esforzándonos por estar a la vanguardia de las tendencias e innovar en nuestros servicios, sin perder nunca de vista la seriedad y la transparencia. Tenemos un compromiso inquebrantable con la integridad y la transparencia en todas nuestras relaciones comerciales, valorando la claridad y el respeto en la información que compartimos con nuestros clientes. Con el Grupo Romper, nuestros clientes pueden confiar en una marca fuerte comprometida con la excelencia en el servicio, siguiendo siempre las mejores prácticas del mercado.'
  },
  'text20': {
    pt:`Compreendemos a importância de cuidar da nossa imagem perante os
    nossos clientes e o mercado em geral. Por isso, buscamos sempre
    ser transparentes em nossas ações e comunicações. Nossa presença
    nas redes sociais é leve e próxima, mas sem perder a seriedade e
    o profissionalismo. Caso você tenha alguma dúvida ou precise de
    ajuda, nossa equipe está sempre pronta para atendê-lo de forma
    clara e objetiva. E lembre-se: toda informação compartilhada nas
    redes sociais deve seguir as mesmas diretrizes de
    confidencialidade que seguimos em nossas operações internas.`,
    en:`We understand the importance of taking care of our image in front of our clients and the market in general. That's why we always strive to be transparent in our actions and communications. Our presence on social media is friendly and approachable, but without losing seriousness and professionalism. If you have any questions or need assistance, our team is always ready to assist you clearly and objectively. And remember: all information shared on social media should follow the same confidentiality guidelines that we follow in our internal operations.`,
    es:`Entendemos la importancia de cuidar nuestra imagen ante nuestros clientes y el mercado en general. Por eso, siempre buscamos ser transparentes en nuestras acciones y comunicaciones. Nuestra presencia en las redes sociales es ligera y cercana, pero sin perder la seriedad y el profesionalismo. Si tienes alguna pregunta o necesitas ayuda, nuestro equipo siempre está listo para atenderte de manera clara y objetiva. Y recuerda: toda la información compartida en las redes sociales debe seguir las mismas pautas de confidencialidad que seguimos en nuestras operaciones internas.`
  },
  'text100': {
    pt: 'Dever de colaboração e gestão ética Em nosso compromisso com a integridade e transparência, contamos com sua colaboração para mantermos um ambiente de trabalho saudável e ético. Caso tenha dúvidas ou presencie alguma violação ao nosso Código de Conduta ou à legislação vigente, é seu dever reportar o fato ao Canal de Ética. Lembre-se de que a reputação do Grupo Romper depende da integridade de cada um de nós e que comportamentos antiéticos podem afetar negativamente o ambiente de trabalho e a moral dos funcionários. Sua comunicação imediata pode impedir que um problema se torne maior e prejudique a empresa e seus colegas. Acreditamos que a colaboração de todos é fundamental para que possamos construir uma empresa sólida e confiável. É importante lembrar que a reputação da Romper como empresa íntegra é de responsabilidade de todos, e cada um de nós tem o dever de agir com ética e denunciar qualquer comportamento antiético ou violação ao nosso Código de Conduta. Para isso, disponibilizamos o telefone do nosso canal para denúncias, telefone 3306-6454, o e-mail sac@romper.com.br e o site romper.com.br/compliance. Garantimos que qualquer denúncia será tratada com confidencialidade e que não haverá retaliação contra quem fizer uma denúncia de boa-fé. Juntos, podemos construir uma empresa mais forte e íntegra, baseada em valores sólidos e coerentes com a nossa missão de proporcionar soluções de qualidade para nossos clientes.',
    en: "Duty of collaboration and ethical management In our commitment to integrity and transparency, we rely on your collaboration to maintain a healthy and ethical work environment. If you have any doubts or witness any violation of our Code of Conduct or current legislation, it is your duty to report the fact to the Ethics Channel. Remember that the reputation of the Romper Group depends on the integrity of each one of us, and that unethical behaviors can negatively affect the work environment and the morale of employees. Your immediate communication can prevent a problem from becoming bigger and harming the company and your colleagues. We believe that everyone's collaboration is fundamental to building a solid and reliable company. It is important to remember that Romper's reputation as an ethical company is everyone's responsibility, and each one of us has the duty to act ethically and report any unethical behavior or violation of our Code of Conduct. For this, we provide the phone number of our reporting channel, +55 (47) 3306-6454, the email sac@romper.com.br, and the website romper.com.br/compliance. We guarantee that any report will be treated confidentially and that there will be no retaliation against anyone who makes a report in good faith. Together, we can build a stronger and more integral company, based on solid values and consistent with our mission to provide quality solutions to our customers.",
    es: 'Deber de colaboración y gestión ética En nuestro compromiso con la integridad y transparencia, contamos con su colaboración para mantener un ambiente de trabajo saludable y ético. Si tiene alguna duda o presencia alguna violación de nuestro Código de Conducta o de la legislación vigente, es su deber reportar el hecho al Canal de Ética. Recuerde que la reputación del Grupo Romper depende de la integridad de cada uno de nosotros, y que los comportamientos no éticos pueden afectar negativamente el ambiente de trabajo y la moral de los empleados. Su comunicación inmediata puede prevenir que un problema se haga mayor y perjudique a la empresa y a sus colegas. Creemos que la colaboración de todos es fundamental para construir una empresa sólida y confiable. Es importante recordar que la reputación de Romper como empresa íntegra es responsabilidad de todos, y cada uno de nosotros tiene el deber de actuar éticamente y denunciar cualquier comportamiento no ético o violación de nuestro Código de Conducta. Para ello, proporcionamos el número de teléfono de nuestro canal de denuncias, 3306-6454, el correo electrónico sac@romper.com.br y el sitio web romper.com.br/compliance. Garantizamos que cualquier denuncia será tratada de manera confidencial y que no habrá represalias contra quien haga una denuncia de buena fe. Juntos, podemos construir una empresa más fuerte e íntegra, basada en valores sólidos y coherentes con nuestra misión de proporcionar soluciones de calidad a nuestros clientes.'
  },
  'text28': {
    pt: 'Lembramos que o compromisso com a integridade e a ética é fundamental para a construção de uma empresa sólida e de sucesso. Por isso, é essencial que todos os colaboradores e parceiros do Grupo Romper estejam alinhados com nossos valores e condutas estabelecidas neste documento de compliance. Qualquer violação a essas normas será investigada e pode resultar em medidas disciplinares, incluindo a rescisão do contrato de trabalho ou parceria, além de possíveis sanções legais. Acreditamos que, juntos, podemos garantir um ambiente de trabalho ético e respeitoso, contribuindo para o fortalecimento da nossa empresa e para o desenvolvimento da sociedade como um todo.',
    en: 'We remind everyone that commitment to integrity and ethics is fundamental to building a solid and successful company. Therefore, it is essential that all employees and partners of the Romper Group align with our values and the conduct established in this compliance document. Any violation of these norms will be investigated and may result in disciplinary measures, including termination of employment or partnership contracts, as well as possible legal sanctions. We believe that, together, we can ensure an ethical and respectful work environment, contributing to the strengthening of our company and the development of society as a whole.',
    es: 'Recordamos a todos que el compromiso con la integridad y la ética es fundamental para construir una empresa sólida y exitosa. Por lo tanto, es esencial que todos los empleados y socios del Grupo Romper se alineen con nuestros valores y la conducta establecida en este documento de cumplimiento. Cualquier violación de estas normas será investigada y puede resultar en medidas disciplinarias, incluida la terminación de contratos de empleo o asociación, así como posibles sanciones legales. Creemos que, juntos, podemos garantizar un ambiente de trabajo ético y respetuoso, contribuyendo al fortalecimiento de nuestra empresa y al desarrollo de la sociedad en su conjunto.'
  },
  'text29': {
    pt: 'Nós, do Grupo Romper, somos apaixonados pelo que fazemos e pela inovação que trazemos ao Brasil. Acreditamos que a ética e a responsabilidade são a base para uma empresa de sucesso. Contamos com todos nosso time para manter a integridade e a coerência em todas as nossas ações, para que possamos continuar a trazer soluções inovadoras e contribuir para o crescimento do nosso país. Juntos, seguimos firmes nessa missão!',
    en: 'We, at Romper Group, are passionate about what we do and the innovation we bring to Brazil. We believe that ethics and responsibility are the foundation for a successful company. We count on our entire team to maintain integrity and coherence in all our actions so that we can continue to bring innovative solutions and contribute to the growth of our country. Together, we remain steadfast in this mission!',
    es: 'Nosotros, en el Grupo Romper, somos apasionados por lo que hacemos y por la innovación que traemos a Brasil. Creemos que la ética y la responsabilidad son la base para una empresa exitosa. Contamos con todo nuestro equipo para mantener la integridad y la coherencia en todas nuestras acciones, para que podamos continuar trayendo soluciones innovadoras y contribuir al crecimiento de nuestro país. ¡Juntos, seguimos firmes en esta misión!'
  },
  'verMais': {
    pt: 'Ver mais',
    en: 'See more',
    es: 'Ver más'
  },
  'verMenos': {
    pt: 'Ver menos',
    en: 'See less',
    es: 'Ver menos'
  }
};

const Compliance = (props) => {
  const { language } = useContext(LocalizeContext);
  const [expand,setExpand] = useState([]);

  const updateExpand = (n) => {
      
    if(expand.includes(n)) {
      const newExpand = expand.filter(elem => elem !== n);
      setExpand(newExpand);
    } else {
      const newExpand = [...expand,n];
      setExpand(newExpand)
    }

  }

  return (
    <div className={styles['container']}>
      <Header TipoHeader={1} sticky={true}></Header>
      <div className={styles['conteudo']}>
        <div className={styles['compromisso-etica']}>
          <div className={styles['img-mobile']}>
            <img
              alt="image"
              src="/compliance/ba95d56e-c37f-4ce8-a73a-5729c042f21c-500h.png"
              className={styles['img']}
            />
          </div>
          <div className={styles['infos']}>
            <span className={styles['titulo']}>
              <span>{complianceTexts['titulo'][language]}</span>
            </span>
            <span className={styles['text01']}>
              {complianceTexts['text01'][language]}
            </span>
          </div>
          <div className={styles['img-desk']}>
            <img
              alt="image"
              src="/compliance/ba95d56e-c37f-4ce8-a73a-5729c042f21c-500h.png"
              className={styles['img01']}
            />
          </div>
        </div>
        <div className={styles['proposito']}>
          <div className={styles['img02']}>
            <img
              alt="image"
              src="/compliance/bf262100-bbe3-4945-8a9c-5d2cac0fb027-1100w.png"
              className={styles['img03']}
            />
          </div>
          <div className={styles['infos01']}>
            <span className={styles['titulo01']}>{complianceTexts['titulo01'][language]}</span>
            <span className={styles['text02']}>
            {complianceTexts['text02'][language]}
            </span>
          </div>
        </div>
        <div className={styles['responsa-social-ambiental']}>
          <div className={styles['img-mobile1']}>
            <img
              alt="image"
              src="/compliance/79c5066c-56bf-43d6-a770-d480d374f06c-1100w.png"
              className={styles['img04']}
            />
          </div>
          <div className={styles['infos02']}>
            <span className={styles['titulo02']}>
            {complianceTexts['titulo02'][language]}
            </span>
            <span className={styles['text03']}>
            {complianceTexts['text03'][language]}
            </span>
          </div>
          <div className={styles['img-desk1']}>
            <img
              alt="image"
              src="/compliance/79c5066c-56bf-43d6-a770-d480d374f06c-1100w.png"
              className={styles['img05']}
            />
          </div>
        </div>
        <div className={styles['cod-conduta-etica']}>
          <div className={styles['img06']}>
            <img
              alt="image"
              src="/compliance/12fdce27-2871-4c02-b803-71275250cc33-500h.png"
              className={styles['img07']}
            />
          </div>
          <div className={styles['infos03']}>
            <span className={styles['titulo03']}>
            {complianceTexts['titulo03'][language]}
            </span>
            <span className={`${styles['text04']} ${expand.includes('cod-conduta-etica') ? styles['expand'] : ''}`}>
            {complianceTexts['text04'][language]}
            </span>
            <button className={styles['botao-ver-mais-menos']} onClick={() => updateExpand('cod-conduta-etica')}>
              <span className={styles['text05']}>
                <span>{expand.includes('cod-conduta-etica') ? complianceTexts['verMenos'][language] : complianceTexts['verMais'][language]}</span>
              </span>
            </button>
          </div>
        </div>
        <div className={styles['relacionamentos-pessoais']}>
          <div className={styles['img-mobile2']}>
            <img
              alt="image"
              src="/compliance/3cea380b-44d0-4ab0-ba2a-543a4a41a5e1-1100w.png"
              className={styles['img08']}
            />
          </div>
          <div className={styles['infos04']}>
            <span className={styles['titulo04']}>{complianceTexts['titulo04'][language]}</span>
            <span className={`${styles['text04']} ${expand.includes('relacionamentos-pessoais') ? styles['expand'] : ''} ${styles['text55']}`}>
            {complianceTexts['text55'][language]}
            </span>
            <button className={styles['botao-ver-mais-menos1']} onClick={() => updateExpand('relacionamentos-pessoais')}>
              <span className={styles['text09']}>
              <span>{expand.includes('relacionamentos-pessoais') ? complianceTexts['verMenos'][language] : complianceTexts['verMais'][language]}</span>
                <br></br>
              </span>
            </button>
          </div>
          <div className={styles['img-desk2']}>
            <img
              alt="image"
              src="/compliance/3cea380b-44d0-4ab0-ba2a-543a4a41a5e1-1100w.png"
              className={styles['img09']}
            />
          </div>
        </div>
        <div className={styles['sigilo-infos']}>
          <div className={styles['img10']}>
            <img
              alt="image"
              src="/compliance/5f2775f9-945b-4b68-848f-ab8e2ce43832-1100w.png"
              className={styles['img11']}
            />
          </div>
          <div className={styles['infos05']}>
            <span className={styles['titulo05']}>{complianceTexts['titulo05'][language]}</span>
            <span className={`${styles['text04']} ${expand.includes('sigilo-infos') ? styles['expand'] : ''} ${styles['text06']}`}>
            {complianceTexts['text06'][language]}
            </span>
            <button className={styles['botao-ver-mais-menos2']} onClick={() => updateExpand('sigilo-infos')}>
              <span className={styles['text13']}>
              <span>{expand.includes('sigilo-infos') ? complianceTexts['verMenos'][language] : complianceTexts['verMais'][language]}</span>
                <br></br>
              </span>
            </button>
          </div>
        </div>
        <div className={styles['concorrencia-mercado']}>
          <div className={styles['img-mobile3']}>
            <img
              alt="image"
              src="/compliance/9bafd82c-f567-4183-a28d-8d7bd6739673-1100w.png"
              className={styles['img12']}
            />
          </div>
          <div className={styles['infos06']}>
            <span className={styles['titulo06']}>{complianceTexts['titulo06'][language]}</span>
            <span className={styles['text16']}>
            {complianceTexts['text16'][language]}
            </span>
          </div>
          <div className={styles['img-desk3']}>
            <img
              alt="image"
              src="/compliance/9bafd82c-f567-4183-a28d-8d7bd6739673-1100w.png"
              className={styles['img13']}
            />
          </div>
        </div>
        <div className={styles['relacao-clientes']}>
          <div className={styles['img14']}>
            <img
              alt="image"
              src="/compliance/6c52b454-a22c-4875-a792-ca31bbc6e1aa-500h.png"
              className={styles['img15']}
            />
          </div>
          <div className={styles['infos07']}>
            <span className={styles['titulo07']}>
            {complianceTexts['titulo07'][language]}
            </span>
            <span className={`${styles['text04']} ${expand.includes('relacao-clientes') ? styles['expand'] : ''}`}>
              <span className={styles['text18']}>
              {complianceTexts['text18'][language]}
              </span>
              <br className={styles['text19']}></br>
              <span className={styles['text20']}>
              {complianceTexts['text20'][language]}
              </span>
            </span>
            <button className={styles['botao-ver-mais-menos3']} onClick={() => updateExpand('relacao-clientes')}>
              <span className={styles['text21']}>
              <span>{expand.includes('relacao-clientes') ? complianceTexts['verMenos'][language] : complianceTexts['verMais'][language]}</span>
                <br></br>
              </span>
            </button>
          </div>
        </div>
        <div className={styles['colabore']}>
          <div className={styles['img-mobile4']}>
            <img
              alt="image"
              src="/compliance/4e4f45f7-4f3f-4b2d-a75a-752f42bad696-1100w.png"
              className={styles['img16']}
            />
          </div>
          <div className={styles['infos08']}>
            <span className={styles['titulo08']}>{complianceTexts['titulo08'][language]}</span>
            <span className={`${styles['text04']} ${expand.includes('colabore') ? styles['expand'] : ''} ${styles['text100']}`}>
            {complianceTexts['text100'][language]}
            </span>
            <button className={styles['botao-ver-mais-menos3']} onClick={() => updateExpand('colabore')}>
              <span className={styles['text21']}>
              <span>{expand.includes('colabore') ? complianceTexts['verMenos'][language] : complianceTexts['verMais'][language]}</span>
                <br></br>
              </span>
            </button>
          </div>
          <div className={styles['img-desk4']}>
            <img
              alt="image"
              src="/compliance/4e4f45f7-4f3f-4b2d-a75a-752f42bad696-1100w.png"
              className={styles['img17']}
            />
          </div>
        </div>
        <div className={styles['compromisso-etica-conformidade']}>
          <div className={styles['img18']}>
            <img
              alt="image"
              src="/compliance/a424b2d2-11b3-4c02-9eea-aa27097861fd-1100w.png"
              className={styles['img19']}
            />
          </div>
          <div className={styles['infos09']}>
            <span className={styles['titulo09']}>
            {complianceTexts['titulo09'][language]}
            </span>
            <span className={styles['text28']}>
            {complianceTexts['text28'][language]}
            </span>
          </div>
        </div>
        <div className={styles['container1']}>
          <div className={styles['card']}>
            <img
              alt="image"
              src="/compliance/8101573b-4f08-4c2a-87a6-182a66fa8a30-1100w.png"
              className={styles['image']}
            />
            <span className={styles['text29']}>
            {complianceTexts['text29'][language]}
            </span>
          </div>
        </div>
        <div className={styles['espaco-branco']}></div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Compliance
