import React, { useContext } from 'react'
import { LocalizeContext } from '../localizeContext';

import Header from '../components/header'
import Footer from '../components/footer'
import styles from './canais-atendimento.module.css'

const atendimentoTexts = {
  'titulo': {
    pt: 'Canais de atendimento',
    en: 'Customer Service Channels',
    es: 'Canales de Atención al Cliente'
  },
  'titulo1': {
    pt: 'Comercial',
    en: 'Commercial',
    es: 'Comercial'
  },
  'text2': {
    pt: 'comercial@romper.com.br',
    en: 'comercial@romper.com.br',
    es: 'comercial@romper.com.br'
  },
  'titulo2': {
    pt: 'Publicidade',
    en: 'Advertising',
    es: 'Publicidad'
  },
  'text5': {
    pt: 'publicidade@romper.com.br',
    en: 'publicidade@romper.com.br',
    es: 'publicidade@romper.com.br'
  },
  'titulo3': {
    pt: 'Financeiro',
    en: 'Financial',
    es: 'Financiero'
  },
  'text6': {
    pt: 'financeiro@romper.com.br',
    en: 'financeiro@romper.com.br',
    es: 'financeiro@romper.com.br'
  }
};

const CanaisAtendimento = (props) => {
  const { language } = useContext(LocalizeContext);
  

  return (
    <div className={styles['container']}>
      <Header TipoHeader={1} sticky={true}></Header>
      <div className={styles['container1']}>
        <span className={styles['titulo']}>{atendimentoTexts['titulo'][language]}</span>
        <div className={styles['cards']}>
          <div className={styles['comercial']}>
            <img
              alt="image"
              src="/iconcomercial.svg"
              className={styles['icon']}
            />
            <div className={styles['texts']}>
              <span className={styles['titulo1']}>
                <span>{atendimentoTexts['titulo1'][language]}</span>
                <br></br>
              </span>
              <span className={styles['text2']}>{atendimentoTexts['text2'][language]}</span>
            </div>
          </div>
          <div className={styles['publicidade']}>
            <img
              alt="image"
              src="/iconpublicidade.svg"
              className={styles['icon1']}
            />
            <div className={styles['texts1']}>
              <span className={styles['titulo2']}>
                <span>{atendimentoTexts['titulo2'][language]}</span>
                <br></br>
              </span>
              <span className={styles['text5']}>{atendimentoTexts['text5'][language]}</span>
            </div>
          </div>
          <div className={styles['financeiro']}>
            <img
              alt="image"
              src="/iconfinanceiro.svg"
              className={styles['icon2']}
            />
            <div className={styles['texts2']}>
              <span className={styles['titulo3']}>{atendimentoTexts['titulo3'][language]}</span>
              <span className={styles['text6']}>{atendimentoTexts['text6'][language]}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default CanaisAtendimento