  import React, { useEffect, useState, useRef, useContext } from 'react'
  import { Link } from 'react-router-dom'
  import Header from '../components/header'
  import Footer from '../components/footer'
  import NavegacaoMarcas from '../components/navegacao-marcas'
  import styles from './home.module.css'
  import { LocalizeContext } from '../localizeContext';

  const routesLang = {
    'canais-atendimento': {
      pt:'/canais-atendimento',
      es:'/canales-de-atencion',
      en:'/customer-service-channels'
    },
    'sobre-nos': {
      pt:'/sobre-nos',
      es:'/sobre-nosotros',
      en:'/about-us'
    },
    'termos-de-uso': {
      pt:'/termos-de-uso',
      es:'/terminos-de-uso',
      en:'/terms-of-use'
    },
    'politica-de-privacidade': {
      pt:'/politica-de-privacidade',
      es:'/politica-de-privacidad',
      en:'/privacy-policy'
    },
    'romper-mkt-avancado': {
      pt:'/romper-mkt-avancado',
      es:'/romper-marketing-avanzado',
      en:'/romper-advanced-marketing'
    },
    'compliance': {
      pt:'/compliance',
      es:'/cumplimiento',
      en:'/compliance'
    },
  }

  const homeTexts = {
    'titulo': {
      pt: 'Conheça algumas de nossas marcas',
      en: 'Discover some of our brands',
      es: 'Descubre algunas de nuestras marcas'
    },
    'text': {
      pt: 'Rompendo barreiras e abrindo caminhos',
      en: 'Breaking barriers and paving the way',
      es: 'Rompiendo barreras y abriendo caminos'
    },
    'text04': {
      pt: 'Registre sua marca de forma rápida e precisa com o apoio da tecnologia de IA do aplicativo Registro Popular.',
      en: 'Register your brand quickly and accurately with the support of the AI technology of the Registro Popular app.',
      es: 'Registre su marca de forma rápida y precisa con el apoyo de la tecnología de IA de la aplicación Registro Popular.'
    },
    'text06': {
      pt: 'Ir para o site',
      en: 'Go to the website',
      es: 'Ir al sitio web'
    },
    'text08': {
      pt: 'Com o DireitoAutoral.com, você registra suas criações de forma rápida e segura em blockchain, sem burocracia. Experimente!',
      en: 'With DireitoAutoral.com, you can register your creations quickly and securely on the blockchain, without bureaucracy. Try it!',
      es: 'Con DireitoAutoral.com, puedes registrar tus creaciones de forma rápida y segura en blockchain, sin burocracia. ¡Pruébalo!'
    },
    'text10': {
      pt: 'Ir para o site',
      en: 'Go to the website',
      es: 'Ir al sitio web'
    },
    'text12': {
      pt: 'Destaque-se no mercado fitness brasileiro e conecte-se a clientes que procuram treinos personalizados, seja online ou presencial.',
      en: 'Stand out in the Brazilian fitness market and connect with clients looking for personalized training, whether online or in-person.',
      es: 'Destácate en el mercado fitness brasileño y conéctate con clientes que buscan entrenamientos personalizados, ya sea en línea o presenciales.'
    },
    'text14': {
      pt: 'Ir para o site',
      en: 'Go to the website',
      es: 'Ir al sitio web'
    },
    'text16': {
      pt: 'Mídia social e educativa pioneira, dedicada a conectar educadores e alunos. Aqui, o ensino ganha destaque, com a venda de cursos e materiais exclusivos para o aprendizado.',
      en: 'A pioneering educational social media, dedicated to connecting educators and students. Here, education is highlighted, with the sale of courses and exclusive materials for learning.',
      es: 'Medio social y educativo pionero, dedicado a conectar educadores y estudiantes. Aquí, la enseñanza destaca, con la venta de cursos y materiales exclusivos para el aprendizaje.'
    },
    'text18': {
      pt: 'Ir para o site',
      en: 'Go to the website',
      es: 'Ir al sitio web'
    },
    'text20': {
      pt: 'Desenvolvimento de marca, site e aplicativo para empresas de grande porte. Conte com a expertise da Romper Marketing e alcance o sucesso que você busca.',
      en: 'Brand, website, and app development for large companies. Count on the expertise of Romper Marketing to achieve the success you seek.',
      es: 'Desarrollo de marca, sitio web y aplicación para empresas grandes. Confíe en la experiencia de Romper Marketing y alcance el éxito que busca.'
    },
    'text21': {
      pt: 'Ir para o site',
      en: 'Go to the website',
      es: 'Ir al sitio web'
    },
    'text23': {
      pt: 'Avance com tecnologia no comércio de chopp: aplicativo que agiliza vendas, controla barris, equipamentos e garante proteção contra fraudes, garantindo tranquilidade operacional.',
      en: 'Advance with technology in the beer trade: an app that streamlines sales, controls barrels, equipment, and ensures protection against fraud, guaranteeing operational peace of mind.',
      es: 'Avance con tecnología en el comercio de cerveza: una aplicación que agiliza las ventas, controla barriles, equipos y garantiza protección contra fraudes, garantizando tranquilidad operativa.'
      },
    'text24': {
      pt: 'Ir para o site',
      en: 'Go to the website',
      es: 'Ir al sitio web'
      },
    'text26': {
      pt: 'Podlocar conecta criativos a estúdios de música, vídeo e fotografia. Simplificamos a busca e promovemos estúdios, impulsionando talentos e negócios.',
      en: 'Podlocar connects creatives to music, video, and photography studios. We simplify the search and promote studios, boosting talents and businesses.',
      es: 'Podlocar conecta a creativos con estudios de música, video y fotografía. Simplificamos la búsqueda y promocionamos estudios, impulsando talentos y negocios.'
      },
    'text27': {
      pt: 'Ir para o site',
      en: 'Go to the website',
      es: 'Ir al sitio web'
      }
  };  
  

  const Home = (props) => {
    const { language } = useContext(LocalizeContext);
    const [headerType,setHeaderType] = useState(2);
    const bannerRef = useRef(null);
    const segundaDobraRef = useRef(null);
    const registroPopularRef = useRef(null);
    const direitoAutoralRef = useRef(null);
    const diskChoppRef = useRef(null);
    const romperMktAvancadoRef = useRef(null);
    const personalTrainerRef = useRef(null);
    const podlocarRef = useRef(null);
    const trekBoxRef = useRef(null);

    const marcas = {
      registroPopular: "https://registropopular.com.br/",
      trekBox:"https://trekbox.com.br/",
      direitoAutoral:"https://direitoautoral.com/",
      personalTrainer:"https://personaltrainer.com.br/",
      diskChopp: "https://diskchopp.com.br/"
    }
    
    const scrollSegundaDobra = () => {
      segundaDobraRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
      const handleScroll = () => {
        if (!bannerRef.current) return;
  
        const ffh = bannerRef.current.offsetHeight;
        const scrollPosition = window.scrollY;
        if ((scrollPosition + 71.79) > ffh) {
          setHeaderType(1);
        } else {
          setHeaderType(2);
        }
      };
      
      handleScroll()
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <div className={styles['container']}>
        <NavegacaoMarcas 
        bannerRef={bannerRef}
        registroPopularRef={registroPopularRef}
        direitoAutoralRef={direitoAutoralRef}
        diskChoppRef={diskChoppRef}
        romperMktAvancadoRef={romperMktAvancadoRef}
        personalTrainerRef={personalTrainerRef}
        podlocarRef={podlocarRef}
        trekBoxRef={trekBoxRef}       
        />
        <div className={styles['banner']} ref={bannerRef}>
        <Header TipoHeader={headerType} fixed={true}></Header>
          <video
            style={ { objectFit:'cover', objectPosition:'center' } }
            src="/background_1.mp4"
            loop
            autoPlay
            muted
            className={styles['video']}
          ></video>
          <div className={styles['container1']}>
            <span className={styles['text']}>
              <span>{homeTexts['text'][language]}</span>
              <br></br>
            </span>
            <img alt="image" src="/iconplay.svg" className={styles['iconplay']} />
          </div>
          <img
            alt="image"
            src="/iconarrowdown.svg"
            className={styles['iconarrowdown']}
            onClick={scrollSegundaDobra}
          />
        </div>
        <div ref={segundaDobraRef} className={styles['conteudo']}>
          <span className={styles['titulo']}>
            <span>{homeTexts['titulo'][language]}</span>
          </span>
          <div className={styles['marcas']}>
            <div ref={registroPopularRef} className={styles['registro-popular']}>
              <img
                alt="IMAGE617591"
                src="/marcas/imgregistropopular-600w.png"
                className={styles['img-mobile']}
              />
              <div className={styles['infos']}>
                <img
                  alt="image"
                  src="/marcas/logoregistropopular.svg"
                  className={styles['logo-desk']}
                />
                <img
                  alt="image"
                  src="/marcas/logoregpopmobile.svg"
                  className={styles['logo-mobile']}
                />
                <span className={styles['text04']}>
                  <span>
                  {homeTexts['text04'][language]}
                  </span>
                </span>
                <button className={styles['botao-site']} onClick={() => window.open(marcas.registroPopular)}>
                  <span className={styles['text06']}>
                    <span>{homeTexts['text06'][language]}</span>
                  </span>
                  <img
                    alt="image"
                    src="/iconarrow.svg"
                    className={styles['iconarrow']}
                  />
                </button>
              </div>
              <img
                alt="IMAGE617591"
                src="/marcas/imgregistropopular-600w.png"
                className={styles['img-desk']}
              />
            </div>
            <div ref={direitoAutoralRef} className={styles['direito-autoral']}>
              <img
                alt="IMAGE617591"
                src="/marcas/imgdireitoautoral-600h.png"
                className={styles['img-mobile1']}
              />
              <div className={styles['infos1']}>
                <img
                  alt="image"
                  src="/marcas/logodireitoautoral.svg"
                  className={styles['logo']}
                />
                <span className={styles['text08']}>
                  <span>
                  {homeTexts['text08'][language]}
                  </span>
                </span>
                <button className={styles['botao-site1']} onClick={() => window.open(marcas.direitoAutoral)}>
                  <span className={styles['text10']}>
                    <span>{homeTexts['text10'][language]}</span>
                  </span>
                  <img
                    alt="image"
                    src="/iconarrow.svg"
                    className={styles['iconarrow1']}
                  />
                </button>
              </div>
              <img
                alt="IMAGE617591"
                src="/marcas/imgdireitoautoral-600h.png"
                className={styles['img-desk1']}
              />
            </div>
            <div ref={personalTrainerRef} className={styles['personal-trainer']}>
              <img
                alt="IMAGE617591"
                src="/marcas/imgpersonaltrainer-600h.png"
                className={styles['img-mobile2']}
              />
              <div className={styles['infos2']}>
                <img
                  alt="image"
                  src="/marcas/logopersonaltrainer.svg"
                  className={styles['logo1']}
                />
                <span className={styles['text12']}>
                  <span>
                  {homeTexts['text12'][language]}
                  </span>
                </span>
                <button className={styles['botao-site2']} onClick={() => window.open(marcas.personalTrainer)}>
                  <span className={styles['text14']}>
                    <span>{homeTexts['text14'][language]}</span>
                  </span>
                  <img
                    alt="image"
                    src="/iconarrow.svg"
                    className={styles['iconarrow2']}
                  />
                </button>
              </div>
              <img
                alt="IMAGE617591"
                src="/marcas/imgpersonaltrainer-600h.png"
                className={styles['img-desk2']}
              />
            </div>
            <div ref={trekBoxRef} className={styles['trek-box']}>
              <img
                alt="IMAGE617591"
                src="/marcas/imgtrekbox-600h.png"
                className={styles['img-mobile3']}
              />
              <div className={styles['infos3']}>
                <img
                  alt="image"
                  src="/marcas/logotrekbox.svg"
                  className={styles['logo2']}
                />
                <span className={styles['text16']}>
                  <span>
                  {homeTexts['text16'][language]}
                  </span>
                </span>
                <button className={styles['botao-site3']} onClick={() => window.open(marcas.trekBox)}>
                  <span className={styles['text18']}>
                    <span>{homeTexts['text18'][language]}</span>
                  </span>
                  <img
                    alt="image"
                    src="/iconarrow.svg"
                    className={styles['iconarrow3']}
                  />
                </button>
              </div>
              <img
                alt="IMAGE617591"
                src="/marcas/imgtrekbox-600h.png"
                className={styles['img-desk3']}
              />
            </div>
            <div ref={romperMktAvancadoRef} className={styles['romper-mkt-avancado']}>
              <img
                alt="IMAGE617591"
                src="/marcas/imgrompermktavancado-600h.png"
                className={styles['img-mobile4']}
              />
              <div className={styles['infos4']}>
                <img
                  alt="image"
                  src="/marcas/logorompermktavancado.svg"
                  className={styles['logo3']}
                />
                <span className={styles['text20']}>
                {homeTexts['text20'][language]}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link to={routesLang['romper-mkt-avancado'][language]}>
                <button className={styles['botao-site4']}>
                  <span className={styles['text21']}>
                    <span>{homeTexts['text21'][language]}</span>
                  </span>
                  <img
                    alt="image"
                    src="/iconarrow.svg"
                    className={styles['iconarrow4']}
                  />
                </button>
                </Link>
              </div>
              <img
                alt="IMAGE617591"
                src="/marcas/imgrompermktavancado-600h.png"
                className={styles['img-desk4']}
              />
            </div>
            <div ref={diskChoppRef} className={styles['disk-chopp']}>
              <img
                alt="IMAGE617591"
                src="/marcas/imgdiskchopp-600h.png"
                className={styles['img-mobile5']}
              />
              <div className={styles['infos5']}>
                <img
                  alt="image"
                  src="/marcas/logodiskchopp.svg"
                  className={styles['logo4']}
                />
                <span className={styles['text23']}>
                {homeTexts['text23'][language]}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <button className={styles['botao-site5']} onClick={() => window.open(marcas.diskChopp)}>
                  <span className={styles['text24']}>
                    <span>{homeTexts['text24'][language]}</span>
                  </span>
                  <img
                    alt="image"
                    src="/iconarrow.svg"
                    className={styles['iconarrow5']}
                  />
                </button>
              </div>
              <img
                alt="IMAGE617591"
                src="/marcas/imgdiskchopp-600h.png"
                className={styles['img-desk5']}
              />
            </div>
            <div ref={podlocarRef} className={styles['podlocar']}>
              <img
                alt="IMAGE617591"
                src="/marcas/imgpodlocar-600h.png"
                className={styles['img-mobile6']}
              />
              <div className={styles['infos6']}>
                <img
                  alt="image"
                  src="/marcas/logopodlocar.svg"
                  className={styles['logo5']}
                />
                <span className={styles['text26']}>
                {homeTexts['text26'][language]}
                </span>
                <button className={styles['botao-site6']}>
                  <span className={styles['text27']}>
                    <span>{homeTexts['text27'][language]}</span>
                  </span>
                  <img
                    alt="image"
                    src="/iconarrow.svg"
                    className={styles['iconarrow6']}
                  />
                </button>
              </div>
              <img
                alt="IMAGE617591"
                src="/marcas/imgpodlocar-600h.png"
                className={styles['img-desk6']}
              />
            </div>
          </div>
          <div className={styles['espaco-branco']}></div>
          
        </div>
        <Footer></Footer>
      </div>
    )
  }

  export default Home