import React, { createContext, useState, useEffect } from 'react';

export const LocalizeContext = createContext();

export const LocalizeProvider = ({ children }) => {
  const [language, setLanguage] = useState('pt');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    const defaultLanguage = navigator.language.split('-')[0];
    setLanguage(storedLanguage || defaultLanguage);
    if (!storedLanguage) {
      localStorage.setItem('language', defaultLanguage);
    }
  }, []);

  const contextValue = {
    language,
    setLanguage: (lang) => {
      setLanguage(lang);
      localStorage.setItem('language', lang);
    }
  };

  return (
    <LocalizeContext.Provider value={contextValue}>
      {children}
    </LocalizeContext.Provider>
  );
};