import React, { useState, useContext, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../components/header'
import Footer from '../components/footer'
import projectStyles from '../style.module.css'
import styles from './romper-mkt-avancado.module.css'
import { LocalizeContext } from '../localizeContext';

const mktAvancadoTexts = {
  'text': {
    pt: 'Orquestrando o extraordinário em cada marca',
    en:'Orchestrating the extraordinary in every brand',
    es:'Orquestando lo extraordinario en cada marca'
  },
  'text01': {
    pt: 'Bem-vindo à vanguarda do marketing empresarial.',
    en:'Welcome to the forefront of corporate marketing.',
    es:'Bienvenido a la vanguardia del marketing empresarial.'
  },
  'text129': {
    pt: `Na Romper Marketing Avançado, não apenas criamos soluções de
                marketing - redefinimos o sucesso de sua empresa.`,
    en:`At Romper Advanced Marketing, we don't just create marketing solutions - we redefine your company's success.`,
    es:`En Romper Marketing Avanzado, no solo creamos soluciones de marketing, sino que redefinimos el éxito de su empresa.`
  },
  'text130': {
    pt: `Nosso foco está em implementar estratégias inovadoras e robustas
                para marcas que não se contentam com o comum. Se você representa
                uma empresa de médio a grande porte, pronta para investir em
                publicidade e marketing de alto nível, você está no lugar certo.`,
    en:`Our focus is on implementing innovative and robust strategies for brands that don't settle for the ordinary. If you represent a medium to large-sized company ready to invest in high-level advertising and marketing, you're in the right place.`,
    es:`Nuestro enfoque está en implementar estrategias innovadoras y sólidas para marcas que no se conforman con lo común. Si representa a una empresa de tamaño mediano a grande lista para invertir en publicidad y marketing de alto nivel, está en el lugar correcto.`
  },
  'text07': {
    pt: `Descubra o potencial do seu negócio`,
    en:'Discover your business potential',
    es:'Descubre el potencial de tu negocio'
  },
  'text08': {
    pt: `Estratégias avançadas para resultados excepcionais`,
    en:'Advanced strategies for exceptional results',
    es:'Estrategias avanzadas para resultados excepcionales'
  },
  'text09': {
    pt: `Alavancando a ciência do marketing para maximizar o crescimento`,
    en:'Leveraging the science of marketing to maximize growth',
    es:'Aprovechando la ciencia del marketing para maximizar el crecimiento'
  },
  'text10': {
    pt: `Nossa abordagem na Romper Marketing Avançado transcende o
            convencional. Utilizamos análises de dados preditivas, inteligência
            de mercado e técnicas de neuromarketing para entender profundamente
            o comportamento do consumidor. Cada estratégia é meticulosamente
            criada e adaptada para garantir que sua marca não apenas participe,
            mas domine o mercado. Do marketing de conteúdo otimizado para SEO
            até campanhas PPC (Pay-Per-Click) altamente segmentadas, nossas
            soluções são projetadas para uma performance de elite.`,
    en:`Our approach at Romper Advanced Marketing transcends the conventional. We use predictive data analysis, market intelligence, and neuromarketing techniques to deeply understand consumer behavior. Each strategy is meticulously crafted and tailored to ensure that your brand not only participates but dominates the market. From SEO-optimized content marketing to highly targeted PPC (Pay-Per-Click) campaigns, our solutions are designed for elite performance.`,
    es:`Nuestro enfoque en Romper Marketing Avanzado trasciende lo convencional. Utilizamos análisis de datos predictivos, inteligencia de mercado y técnicas de neuromarketing para comprender profundamente el comportamiento del consumidor. Cada estrategia se crea meticulosamente y se adapta para asegurarse de que su marca no solo participe, sino que domine el mercado. Desde el marketing de contenidos optimizado para SEO hasta campañas de PPC (Pago Por Clic) altamente segmentadas, nuestras soluciones están diseñadas para un rendimiento de élite.`
  },
  'text11': {
    pt: `Veja como nossas estratégias funcionam`,
    en:'See how our strategies work',
    es:'Mira cómo funcionan nuestras estrategias'
  },
  'text12': {
    pt: `Integração de tecnologia e marketing para transformação digital`,
    en:'Integration of technology and marketing for digital transformation',
    es:'Integración de tecnología y marketing para la transformación digital'
  },
  'text15': {
    pt: `Construindo infraestruturas de marketing inovadoras`,
    en:'Building innovative marketing infrastructures',
    es:'Construyendo infraestructuras de marketing innovadoras'
  },
  'text16': {
    pt: `Na Romper Marketing Avançado, não nos limitamos a campanhas
              publicitárias convencionais. Nós integramos soluções tecnológicas
              avançadas, como desenvolvimento de aplicativos personalizados e
              websites otimizados para conversão, que funcionam como ferramentas
              de engajamento e vendas. Nossa equipe especializada em UX/UI
              desenha experiências digitais que capturam e mantêm a atenção do
              público-alvo, enquanto sistemas analíticos robustos fornecem
              insights valiosos para otimização contínua. Estamos na vanguarda
              da transformação digital, implementando automação de marketing e
              CRM (Customer Relationship Management) para maximizar a eficiência
              e o impacto de cada interação com o cliente.`,
    en:`At Romper Advanced Marketing, we don't limit ourselves to conventional advertising campaigns. We integrate advanced technological solutions, such as custom app development and conversion-optimized websites, that serve as engagement and sales tools. Our UX/UI specialist team designs digital experiences that capture and hold the target audience's attention, while robust analytical systems provide valuable insights for continuous optimization. We are at the forefront of digital transformation, implementing marketing automation and CRM (Customer Relationship Management) to maximize efficiency and the impact of every customer interaction.`,
    es:`En Romper Marketing Avanzado, no nos limitamos a campañas publicitarias convencionales. Integramos soluciones tecnológicas avanzadas, como el desarrollo de aplicaciones personalizadas y sitios web optimizados para la conversión, que funcionan como herramientas de compromiso y ventas. Nuestro equipo especializado en UX/UI diseña experiencias digitales que capturan y mantienen la atención del público objetivo, mientras que los sistemas analíticos sólidos proporcionan información valiosa para la optimización continua. Estamos a la vanguardia de la transformación digital, implementando la automatización del marketing y el CRM (Gestión de Relaciones con el Cliente) para maximizar la eficiencia y el impacto de cada interacción con el cliente.`
  },
  'text17': {
    pt: `O caminho para o sucesso exponencial`,
    en:'The path to exponential success',
    es:'El camino hacia el éxito exponencial'
  },
  'text18': {
    pt: `Alcance Além do Convencional, Rumo a Resultados Revolucionários`,
    en:'Reach Beyond the Conventional, Toward Revolutionary Results',
    es:'Alcanza Más Allá de lo Convencional, Hacia Resultados Revolucionarios'
  },
  'text19': {
    pt: `A Romper Marketing Avançado é mais do que uma agência de
              marketing; somos seus parceiros estratégicos no caminho para o
              sucesso. Com nossa abordagem exclusiva, focada em grandes contas e
              estratégias avançadas, estamos comprometidos em levar sua empresa
              a novos patamares. Nossas soluções não são para todos; elas são
              cuidadosamente criadas para empresas que compreendem o valor do
              marketing inovador e estão prontas para investir no crescimento
              exponencial. Se sua empresa está pronta para transformar o mercado
              e liderar a inovação, a Romper Marketing Avançado é a escolha
              certa.`,
    en:`Romper Advanced Marketing is more than a marketing agency; we are your strategic partners on the path to success. With our unique approach, focused on major accounts and advanced strategies, we are committed to taking your company to new heights. Our solutions are not for everyone; they are carefully crafted for companies that understand the value of innovative marketing and are ready to invest in exponential growth. If your company is ready to transform the market and lead in innovation, Romper Advanced Marketing is the right choice.`,
    es:`Romper Marketing Avanzado es más que una agencia de marketing; somos sus socios estratégicos en el camino hacia el éxito. Con nuestro enfoque único, centrado en las cuentas principales y estrategias avanzadas, estamos comprometidos en llevar su empresa a nuevas alturas. Nuestras soluciones no son para todos; están cuidadosamente diseñadas para empresas que comprenden el valor del marketing innovador y están listas para invertir en un crecimiento exponencial. Si su empresa está lista para transformar el mercado y liderar en innovación, Romper Marketing Avanzado es la elección correcta.`
  },
  'text20': {
    pt: `Agende uma consulta`,
    en:'Schedule a consultation',
    es:'Programa una consulta'
  },
  'text132': {
    pt: `Na Romper Marketing Avançado, a excelência e a inovação andam de
              mãos dadas.`,
    en:'At Romper Advanced Marketing, excellence and innovation go hand in hand.',
    es:'En Romper Marketing Avanzado, la excelencia y la innovación van de la mano.'
  },
  'text133': {
    pt: `Junte-se a nós e veja sua empresa romper barreiras e estabelecer
              novos padrões no mercado`,
    en:'Join us and see your company break barriers and set new standards in the market',
    es:'Únete a nosotros y ve cómo tu empresa rompe barreras y establece nuevos estándares en el mercado'
  },
  'text25': {
    pt: `Formulário de contato`,
    en:'Contact form',
    es:'Formulario de contacto'
  },
  'text26': {
    pt: `Informações básicas`,
    en:'Basic information',
    es:'Información básica'
  },
  'label': {
    pt: `Nome do contato`,
    en:'Contact name',
    es:'Nombre de contacto'
  },
  'label01': {
    pt: `E-mail corporativo`,
    en:'Corporate email',
    es:'Correo electrónico corporativo'
  },
  'label02': {
    pt: `Cargo na empresa`,
    en:'Position in the company',
    es:'Cargo en la empresa'
  },
  'label03': {
    pt: `Telefone para contato`,
    en:'Contact phone',
    es:'Teléfono de contacto'
  },
  'text27': {
    pt: `Informações da empresa`,
    en:'Company information',
    es:'Información de la empresa'
  },
  'label04': {
    pt: `Nome da empresa`,
    en:'Company name',
    es:'Nombre de la empresa'
  },
  'label05': {
    pt: `Setor de atuação`,
    en:'Industry sector',
    es:'Sector de la industria'
  },
  'selecioneUmaOpcao': {
    pt: `-- Selecione uma opção --`,
    en:'-- Select an option --',
    es:'-- Seleccione una opción --'
  },
  'Tecnologia': {
    pt: `Tecnologia`,
    en:'Technology',
    es:'Tecnología'
  },
  'Saúde': {
    pt: `Saúde`,
    en:'Health',
    es:'Salud'
  },
  'Finanças': {
    pt: `Finanças`,
    en:'Finance',
    es:'Finanzas'
  },
  'Educação': {
    pt: `Educação`,
    en:'Education',
    es:'Educación'
  },
  'Outros': {
    pt: `Outros`,
    en:'Others',
    es:'Otros'
  },
  'label06': {
    pt: `Faturamento anual estimado`,
    en: `Estimated annual revenue`,
    es: `Ingresos anuales estimados`
  },
  'menos5Milhoes': {
    pt: `Menos de R$5 milhões`,
    en: `Less than U$5 million`,
    es: `Menos de U$5 millones`
  },
  '5a50milhoes': {
    pt: `R$5-50 milhões`,
    en: `U$5-50 million`,
    es: `U$5-50 millones`
  },
  '50a200milhoes': {
    pt: `R$50-200 milhões`,
    en: `U$50-200 million`,
    es: `U$50-200 millones`
  },
  'acima200milhoes': {
    pt: `Acima de R$200 milhões`,
    en: `Above U$200 million`,
    es: `Más de U$200 millones`
  },
  'label07': {
    pt: `Número de funcionários`,
    en: `Number of employees`,
    es: `Número de empleados`
  },
  'label08': {
    pt: `A empresa possui website e/ou mídias sociais?`,
    en: `Does the company have a website and/or social media?`,
    es: `¿La empresa tiene un sitio web y/o redes sociales?`
  },
  'text31': {
    pt: `Sim`,
    en: `Yes`,
    es: `Sí`
  },
  'text32': {
    pt: `Não`,
    en: `No`,
    es: `No`
  },
  'text33': {
    pt: `Informações sobre o projeto`,
    en: `Information about the project`,
    es: `Información sobre el proyecto`
  },
  'label09': {
    pt: `Descrição do projeto`,
    en: `Project description`,
    es: `Descripción del proyecto`
  },
  'label10': {
    pt: `Objetivos principais`,
    en: `Main objectives`,
    es: `Objetivos principales`
  },
  'label11': {
    pt: `Orçamento mensal disponível para marketing`,
    en: `Monthly budget available for marketing`,
    es: `Presupuesto mensual disponible para marketing`
  },
  '500mila1milhao': {
    pt: `500001-1 milhão`,
    en: `500001-1 million`,
    es: `500001-1 millón`
  },
  'acima1milhao': {
    pt: `Acima de 1 milhão`,
    en: `Above 1 million`,
    es: `Más de 1 millón`
  },
  'label12': {
    pt: `Prazo previsto para início do projeto de implementação`,
    en: `Estimated start date for implementation project`,
    es: `Fecha de inicio estimada para el proyecto de implementación`
  },
  'text38': {
    pt: `Experiência anterior com marketing`,
    en: `Previous experience with marketing`,
    es: `Experiencia previa con marketing`
  },
  'label13': {
    pt: `Já realizaram projetos de marketing digital antes?`,
    en: `Have you carried out digital marketing projects before?`,
    es: `¿Han realizado proyectos de marketing digital antes?`
  },
  'text39': {
    pt: `Sim`,
    en: `Yes`,
    es: `Sí`
  },
  'text40': {
    pt: `Não`,
    en: `No`,
    es: `No`
  },
  'text41': {
    pt: `Expectativas e metas`,
    en: `Expectations and goals`,
    es: `Expectativas y metas`
  },
  'label14': {
    pt: `Quais são suas expectativas com relação ao trabalho da Romper?`,
    en: `What are your expectations regarding Romper's work?`,
    es: `¿Cuáles son sus expectativas con respecto al trabajo de Romper?`
  },
  'label15': {
    pt: `Metas de curto e longo prazo`,
    en: `Short-term and long-term goals`,
    es: `Metas a corto y largo plazo`
  },
  'text42': {
    pt: `Confirmo que todas as informações fornecidas são verdadeiras e
              refletem a realidade da minha empresa`,
    en: `I confirm that all the information provided is true and reflects the reality of my company`,
    es: `Confirmo que toda la información proporcionada es verdadera y refleja la realidad de mi empresa`
  },
  'text43': {
    pt: `Enviar`,
    en: `Submit`,
    es: `Enviar`
  },
};

const errorMessages = {
  'pt': {
    contactName: "Por favor, preencha o nome do contato.",
    validEmail: "Por favor, insira um e-mail corporativo válido.",
    validPhone: "Por favor, insira um número de telefone válido.",
    position:"Por favor, insira o seu cargo na empresa.",
    companyName: "Por favor, preencha o nome da empresa.",
    industrySector: "Por favor, selecione o setor de atuação da empresa.",
    employeeNumber: "Por favor, selecione o número de funcionários.",
    annualRevenue: "Por favor, selecione o faturamento anual.",
    socialMediaLinks: "Por favor, forneça os links das redes sociais.",
    projectDescription: "Por favor, descreva o projeto.",
    mainObjectives: "Por favor, insira os objetivos principais.",
    marketingBudget: "Por favor, selecione o orçamento mensal disponível para marketing.",
    startDate:"Por favor, insira qual prazo estimado para implementação do projeto.",
    marketingExperienceDescription:"Por favor, descreva brevemente sua experiência anterior com marketing digital.",
    expectations: "Por favor, forneça suas expectativas.",
    goals: "Por favor, forneça as metas de curto e longo prazo.",
    infoConfirmation: "Por favor, confirme que as informações fornecidas são verdadeiras."
  },
  'en': {
    contactName: "Please fill in the contact name.",
    validEmail: "Please enter a valid corporate email.",
    validPhone: "Please enter a valid phone number.",
    position: "Please enter your position in the company.",
    companyName: "Please fill in the company name.",
    industrySector: "Please select the industry sector.",
    employeeNumber: "Please select the number of employees.",
    annualRevenue: "Please select the annual revenue.",
    socialMediaLinks: "Please provide social media links.",
    projectDescription: "Please describe the project.",
    mainObjectives: "Please enter the main objectives.",
    marketingBudget: "Please select the available monthly marketing budget.",
    startDate:"Please enter the estimated project implementation date.",
    marketingExperienceDescription:"Please briefly describe your previous experience with digital marketing.",
    expectations: "Please provide your expectations.",
    goals: "Please provide short-term and long-term goals.",
    infoConfirmation: "Please confirm that all the provided information is true."
  },
  'es': {
    contactName: "Por favor, rellene el nombre del contacto.",
    validEmail: "Por favor, introduzca un correo electrónico corporativo válido.",
    validPhone: "Por favor, introduzca un número de teléfono válido.",
    position: "Por favor, introduzca su cargo en la empresa.",
    companyName: "Por favor, rellene el nombre de la empresa.",
    industrySector: "Por favor, seleccione el sector industrial.",
    employeeNumber: "Por favor, seleccione el número de empleados.",
    annualRevenue: "Por favor, seleccione los ingresos anuales.",
    socialMediaLinks: "Por favor, proporcione enlaces a las redes sociales.",
    projectDescription: "Por favor, describa el proyecto.",
    mainObjectives: "Por favor, introduzca los objetivos principales.",
    marketingBudget: "Por favor, seleccione el presupuesto mensual disponible para marketing.",
    startDate:"Por favor, introduzca la fecha estimada de implementación del proyecto.",
    marketingExperienceDescription:"Por favor, describa brevemente su experiencia previa en marketing digital.",
    expectations: "Por favor, proporcione sus expectativas.",
    goals: "Por favor, proporcione metas a corto y largo plazo.",
    infoConfirmation: "Por favor, confirme que toda la información proporcionada es verdadera."
  }
};

const successMessages = {
  'pt': {
    submitForm:"Formulário enviado com sucesso, entraremos em contato em até 48 horas."
  },
  'en': {
    submitForm:"Form successfully submitted, we will contact you within 48 hours."
  },
  'es': {
    submitForm:"Formulario enviado con éxito, nos pondremos en contacto en un plazo de 48 horas."
  }
}


const RomperMktAvancado = (props) => {
  const { language } = useContext(LocalizeContext);
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    nome:'',
    cargo:'',
    email:'',
    telefone:'',
    nome_empresa:'',
    setor_atuacao:'',
    numero_funcionarios:'',
    faturamento_anual:'',
    temRedesSociais:'',
    linksRedes:'',
    descricaoProjeto:'',
    objetivosPrincipais:'',
    orcamentoMensalDisponivel:'',
    prazoImplementacao:'',
    expMktDigital:'',
    descricaoExpMktDigital:'',
    expectativas:'',
    metas:'',
    infoVerdadeiras:false
  })

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const validateForm = () => {
    let isValid = true;
    
    if (!formData.nome.trim()) {
      toast.error(errorMessages[language].contactName);
      isValid = false;
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      toast.error(errorMessages[language].validEmail);
      isValid = false;
      return;
    }

    if (!formData.cargo.trim()) {
      toast.error(errorMessages[language].position);
      isValid = false;
      return;
    }

    if (!formData.telefone.trim()) {
      toast.error(errorMessages[language].validPhone);
      isValid = false;
      return;
    }

    if (!formData.nome_empresa.trim()) {
      toast.error(errorMessages[language].companyName);
      isValid = false;
      return;
    }
    
    if (!formData.setor_atuacao.trim()) {
      toast.error(errorMessages[language].industrySector);
      isValid = false;
      return;
    }
    
    if (!formData.numero_funcionarios.trim()) {
      toast.error(errorMessages[language].employeeNumber);
      isValid = false;
      return;
    }

    if (!formData.faturamento_anual.trim()) {
      toast.error(errorMessages[language].annualRevenue);
      isValid = false;
      return;
    }
    
    if (formData.temRedesSociais === mktAvancadoTexts['text31'][language] && !formData.linksRedes.trim()) {
      toast.error(errorMessages[language].socialMediaLinks);
      isValid = false;
      return;
    }
    
    if (!formData.descricaoProjeto.trim()) {
      toast.error(errorMessages[language].projectDescription);
      isValid = false;
      return;
    }
    
    if (!formData.objetivosPrincipais.trim()) {
      toast.error(errorMessages[language].mainObjectives);
      isValid = false;
      return;
    }
    
    if (!formData.orcamentoMensalDisponivel.trim()) {
      toast.error(errorMessages[language].marketingBudget);
      isValid = false;
      return;
    }

    if (!formData.prazoImplementacao.trim()) {
      toast.error(errorMessages[language].startDate);
      isValid = false;
      return;
    }

    if (formData.expMktDigital === mktAvancadoTexts['text39'][language] && !formData.descricaoExpMktDigital.trim()) {
      toast.error(errorMessages[language].marketingExperienceDescription);
      isValid = false;
      return;
    }
    
    if (!formData.expectativas.trim()) {
      toast.error(errorMessages[language].expectations);
      isValid = false;
      return;
    }
  
    if (!formData.metas.trim()) {
      toast.error(errorMessages[language].goals);
      isValid = false;
      return;
    }
  
    if (!formData.infoVerdadeiras) {
      toast.error(errorMessages[language].infoConfirmation);
      isValid = false;
      return;
    }
  
  
    return isValid;
  };
  
  const handleSubmit = async(event) => {
    event.preventDefault();
    

    if (!validateForm()) {
      return;
    }

    const fData = new FormData();
    fData.append('nome', formData.nome);
    fData.append('cargo', formData.cargo);
    fData.append('email', formData.email);
    fData.append('telefone', formData.telefone);
    fData.append('nome_empresa', formData.nome_empresa);
    fData.append('setor_atuacao', formData.setor_atuacao);
    fData.append('numero_funcionarios', formData.numero_funcionarios);
    fData.append('faturamento_anual', formData.faturamento_anual);
    fData.append('temRedesSociais', formData.temRedesSociais);
    fData.append('linksRedes', formData.linksRedes);
    fData.append('descricaoProjeto', formData.descricaoProjeto);
    fData.append('objetivosPrincipais', formData.objetivosPrincipais);
    fData.append('orcamentoMensalDisponivel', formData.orcamentoMensalDisponivel);
    fData.append('prazoImplementacao', formData.prazoImplementacao);
    fData.append('expMktDigital', formData.expMktDigital);
    fData.append('descricaoExpMktDigital', formData.descricaoExpMktDigital);
    fData.append('expectativas', formData.expectativas);
    fData.append('metas', formData.metas);

    try {
      const response = await fetch('/api/sendEmail.php', {
        method: 'POST',
        body: fData
      });

      
    } catch (error) {
      console.log(error)
    }
    
    setFormData({
      nome:'',
      cargo:'',
      email:'',
      telefone:'',
      nome_empresa:'',
      setor_atuacao:'',
      numero_funcionarios:'',
      faturamento_anual:'',
      temRedesSociais:'',
      linksRedes:'',
      descricaoProjeto:'',
      objetivosPrincipais:'',
      orcamentoMensalDisponivel:'',
      prazoImplementacao:'',
      expMktDigital:'',
      descricaoExpMktDigital:'',
      expectativas:'',
      metas:'',
      infoVerdadeiras:false
    })

    toast.success(successMessages[language].submitForm)

  };

  useEffect(() => {
    const yesText = mktAvancadoTexts['text31'][language];
  
    if (formData.temRedesSociais !== yesText && formData.linksRedes) {
      setFormData({
        ...formData,
        linksRedes: ''
      });
    }
  }, [formData.temRedesSociais, language]);
  
  useEffect(() => {
    const yesText = mktAvancadoTexts['text39'][language];
  
    if (formData.expMktDigital !== yesText && formData.descricaoExpMktDigital) {
      setFormData({
        ...formData,
        descricaoExpMktDigital: ''
      });
    }
  }, [formData.expMktDigital, language]);
  

  return (
    <div className={styles['container']}>
      <div className={styles['banner']}>
        <Header TipoHeader={3}></Header>
        <div className={styles['container01']}>
          <div className={styles['animacao-mobile']}>
            <img
              alt="image"
              src="/animacoes/innovation-animate%5B1%5D.svg"
              className={styles['image']}
            />
          </div>
          <div className={styles['container02']}>
            <span className={styles['text']}>
              {mktAvancadoTexts['text'][language]}
            </span>
            <span className={styles['text01']}>
              <span>{mktAvancadoTexts['text01'][language]}</span>
              <br></br>
              <span className={styles['text129']}>
              {mktAvancadoTexts['text129'][language]}
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span className={styles['text130']}>
              {mktAvancadoTexts['text130'][language]}
              </span>
            </span>
            <button className={styles['botao-potencial-negocio']} onClick={scrollToForm}>
              <span className={styles['text07']}>
              {mktAvancadoTexts['text07'][language]}
              </span>
            </button>
          </div>
          <div className={styles['animacao-desk']}>
            <img
              alt="image"
              src="/animacoes/innovation-animate%5B1%5D.svg"
              className={styles['image1']}
            />
          </div>
        </div>
      </div>
      <div className={styles['container03']}>
        <div className={styles['container04']}>
          <span className={styles['text08']}>
          {mktAvancadoTexts['text08'][language]}
          </span>
          <span className={styles['text09']}>
          {mktAvancadoTexts['text09'][language]}
          </span>
          <span className={styles['text10']}>
          {mktAvancadoTexts['text10'][language]}
          </span>
          <img
            alt="image"
            src="/animacoes/data-extraction-animate%5B1%5D.svg"
            className={styles['animacao']}
          />
        </div>
        <button className={styles['botao-estrategias']} onClick={scrollToForm}>
          <span className={styles['text11']}>
          {mktAvancadoTexts['text11'][language]}
          </span>
        </button>
      </div>
      <div className={styles['container05']}>
        <span className={styles['text12']}>
          <span>
          {mktAvancadoTexts['text12'][language]}
          </span>
          <br></br>
        </span>
      </div>
      <div className={styles['container06']}>
        <div className={styles['container07']}>
          <div className={styles['animacao-mobile1']}>
            <img
              alt="image"
              src="/animacoes/data-report-animate%5B1%5D.svg"
              className={styles['img']}
            />
          </div>
          <div className={styles['container08']}>
            <span className={styles['text15']}>
            {mktAvancadoTexts['text15'][language]}
            </span>
            <span className={styles['text16']}>
            {mktAvancadoTexts['text16'][language]}
            </span>
          </div>
          <div className={styles['animacao-desk1']}>
            <img
              alt="image"
              src="/animacoes/data-report-animate%5B1%5D.svg"
              className={styles['img1']}
            />
          </div>
        </div>
      </div>
      <div className={styles['container09']}>
        <div className={styles['container10']}>
          <div className={styles['animacao1']}>
            <img
              alt="image"
              src="/animacoes/meeting-animate%5B1%5D.svg"
              className={styles['img2']}
            />
          </div>
          <div className={styles['container11']}>
            <span className={styles['text17']}>
            {mktAvancadoTexts['text17'][language]}
            </span>
            <span className={styles['text18']}>
            {mktAvancadoTexts['text18'][language]}
            </span>
            <span className={styles['text19']}>
            {mktAvancadoTexts['text19'][language]}
            </span>
          </div>
        </div>
        <button className={styles['botao-agendar-consulta']} onClick={scrollToForm}>
          <span className={styles['text20']}>{mktAvancadoTexts['text20'][language]}</span>
        </button>
        <div className={styles['container12']}>
          <span className={styles['text21']}>
            <span className={styles['text132']}>
            {mktAvancadoTexts['text132'][language]}
            </span>
            <br></br>
            <span className={styles['text133']}>
            {mktAvancadoTexts['text133'][language]}
            </span>
          </span>
        </div>
        <div className={styles['divisor']}></div>
        <span ref={formRef} className={styles['text25']}>{mktAvancadoTexts['text25'][language]}</span>
        <div className={styles['formulario']}>
          <div className={styles['informacoes-basicas']}>
            <span className={styles['text26']}>{mktAvancadoTexts['text26'][language]}</span>
            <div className={styles['inputs']}>
              <div className={styles['row']}>
                <div className={styles['nome-contato']}>
                  <span className={styles['label']}>{mktAvancadoTexts['label'][language]}</span>
                  <input
                    type="text"
                    className={styles['textinput']}
                    value={formData.nome}
                    onChange={(e) => setFormData({...formData, nome: e.target.value})}
                  />
                </div>
                <div className={styles['email-corporativo']}>
                  <span className={styles['label01']}>{mktAvancadoTexts['label01'][language]}</span>
                  <input
                    type="text"
                    className={styles['textinput1']}
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                  />
                </div>
              </div>
              <div className={styles['row1']}>
                <div className={styles['cargo']}>
                  <span className={styles['label02']}>{mktAvancadoTexts['label02'][language]}</span>
                  <input
                    type="text"
                    className={styles['textinput2']}
                    value={formData.cargo}
                    onChange={(e) => setFormData({...formData, cargo: e.target.value})}
                  />
                </div>
                <div className={styles['telefone-contato']}>
                  <span className={styles['label03']}>
                  {mktAvancadoTexts['label03'][language]}
                  </span>
                  <input
                    type="text"
                    className={styles['textinput3']}
                    value={formData.telefone}
                    onChange={(e) => setFormData({...formData, telefone: e.target.value})}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles['divisor1']}></div>
          <div className={styles['informacoes-empresa']}>
            <span className={styles['text27']}>{mktAvancadoTexts['text27'][language]}</span>
            <div className={styles['inputs1']}>
              <div className={styles['row2']}>
                <div className={styles['nome-empresa']}>
                  <span className={styles['label04']}>{mktAvancadoTexts['label04'][language]}</span>
                  <input
                    type="text"
                    className={styles['textinput4']}
                    value={formData.nome_empresa}
                    onChange={(e) => setFormData({...formData, nome_empresa: e.target.value})}
                  />
                </div>
                <div className={styles['setor-atuacao']}>
                  <span className={styles['label05']}>{mktAvancadoTexts['label05'][language]}</span>
                  <div className={styles['custom-select']}>
                    <select className={styles['dropdown']}
                    value={formData.setor_atuacao || null}
                    onChange={(e) => setFormData({...formData, setor_atuacao: e.target.value})}
                    >
                    <option disabled selected>{mktAvancadoTexts['selecioneUmaOpcao'][language]}</option>
                    <option value={mktAvancadoTexts['Tecnologia'][language]}>{mktAvancadoTexts['Tecnologia'][language]}</option>
                    <option value={mktAvancadoTexts['Saúde'][language]}>{mktAvancadoTexts['Saúde'][language]}</option>
                    <option value={mktAvancadoTexts['Finanças'][language]}>{mktAvancadoTexts['Finanças'][language]}</option>
                    <option value={mktAvancadoTexts['Educação'][language]}>{mktAvancadoTexts['Educação'][language]}</option>
                    <option value={mktAvancadoTexts['Outros'][language]}>{mktAvancadoTexts['Outros'][language]}</option>
                    </select>
                    <img
                        alt="image"
                        src="/arrowdropdown.svg"
                        className={styles['custom-icon']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['row3']}>
                <div className={styles['faturamento-anual']}>
                  <span className={styles['label06']}>
                  {mktAvancadoTexts['label06'][language]}
                  </span>
                  <div className={styles['custom-select']}>
                    <select className={styles['dropdown']}
                    value={formData.faturamento_anual || null}
                    onChange={(e) => setFormData({...formData, faturamento_anual: e.target.value})}                    
                    >
                    <option disabled selected>{mktAvancadoTexts['selecioneUmaOpcao'][language]}</option>
                    <option value={mktAvancadoTexts['menos5Milhoes'][language]}>{mktAvancadoTexts['menos5Milhoes'][language]}</option>
                    <option value={mktAvancadoTexts['5a50milhoes'][language]}>{mktAvancadoTexts['5a50milhoes'][language]}</option>
                    <option value={mktAvancadoTexts['50a200milhoes'][language]}>{mktAvancadoTexts['50a200milhoes'][language]}</option>
                    <option value={mktAvancadoTexts['acima200milhoes'][language]}>{mktAvancadoTexts['acima200milhoes'][language]}</option>
                    </select>
                    <img
                        alt="image"
                        src="/arrowdropdown.svg"
                        className={styles['custom-icon']}
                    />
                  </div>
                </div>
                <div className={styles['numero-funcionarios']}>
                  <span className={styles['label07']}>
                  {mktAvancadoTexts['label07'][language]}
                  </span>
                  <div className={styles['custom-select']}>
                    <select className={styles['dropdown']}
                    value={formData.numero_funcionarios || null}
                    onChange={(e) => setFormData({...formData, numero_funcionarios: e.target.value})}
                    >
                    <option disabled selected>{mktAvancadoTexts['selecioneUmaOpcao'][language]}</option>
                    <option name="1-10">1-10</option>
                    <option name="11-30">11-30</option>
                    <option name="31-100">31-100</option>
                    <option name="101-500">101-500</option>
                    <option name="501+">501+</option>

                    </select>
                    <img
                        alt="image"
                        src="/arrowdropdown.svg"
                        className={styles['custom-icon']}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['row4']}>
                <div className={styles['midias-sociais']}>
                  <span className={styles['label08']}>
                  {mktAvancadoTexts['label08'][language]}
                  </span>
                  <div className={styles['radio']}>
                    <div className={styles['radio-sim']} onClick={() => setFormData({...formData, temRedesSociais: mktAvancadoTexts['text31'][language]})}>
                      <span className={styles['text31']}>{mktAvancadoTexts['text31'][language]}</span>
                      <div className={styles['button-radio']}>
                        <div className={styles[`selecionado${formData.temRedesSociais !== mktAvancadoTexts['text31'][language] ? '1' : ''}`]}></div>
                      </div>
                    </div>
                    <div className={styles['radio-nao']} onClick={() => setFormData({...formData, temRedesSociais: mktAvancadoTexts['text32'][language]})}>
                      <span className={styles['text32']}>{mktAvancadoTexts['text32'][language]}</span>
                      <div className={styles['button-radio1']}>
                        <div className={styles[`selecionado${formData.temRedesSociais !== mktAvancadoTexts['text32'][language] ? '1' : ''}`]}></div>
                      </div>
                    </div>
                  </div>
                  {formData.temRedesSociais === mktAvancadoTexts['text31'][language] && (
                    <textarea
                    className={` ${styles['textarea']} ${projectStyles['textarea']} `}
                    value={formData.linksRedes}
                    onChange={(e) => setFormData({...formData, linksRedes: e.target.value})}
                  ></textarea>
                  )}
                </div>
                <div className={styles['espaco-branco']}></div>
              </div>
            </div>
          </div>
          <div className={styles['divisor2']}></div>
          <div className={styles['informacoes-projetos']}>
            <span className={styles['text33']}>
            {mktAvancadoTexts['text33'][language]}
            </span>
            <div className={styles['inputs2']}>
              <div className={styles['descricao-projeto']}>
                <span className={styles['label09']}>
                  <span>{mktAvancadoTexts['label09'][language]}</span>
                  <br></br>
                </span>
                <textarea
                  type="text"
                  className={styles['textinput5']}
                  value={formData.descricaoProjeto}
                  onChange={(e) => setFormData({...formData, descricaoProjeto: e.target.value})}
                />
              </div>
              <div className={styles['objetivos-principais']}>
                <span className={styles['label10']}>{mktAvancadoTexts['label10'][language]}</span>
                <textarea
                  type="text"
                  className={styles['textinput6']}
                  value={formData.objetivosPrincipais}
                  onChange={(e) => setFormData({...formData, objetivosPrincipais: e.target.value})}
                />
              </div>
              <div className={styles['orcamento-mensal-mkt']}>
                <span className={styles['label11']}>
                {mktAvancadoTexts['label11'][language]}
                </span>
                <div className={styles['custom-select']}>
                    <select className={styles['dropdown']}
                    value={formData.orcamentoMensalDisponivel || null}
                    onChange={(e) => setFormData({...formData, orcamentoMensalDisponivel: e.target.value})}
                    >
                      <option disabled selected>{mktAvancadoTexts['selecioneUmaOpcao'][language]}</option>
                      <option value="0-5000">0-5000</option>
                      <option value="5001-15.000">5001-15000</option>
                      <option value="15001-50000">15001-50000</option>
                      <option value="50001-100000">50001-100000</option>
                      <option value="100001-500000">100001-500000</option>
                      <option value={mktAvancadoTexts['500mila1milhao'][language]}>{mktAvancadoTexts['500mila1milhao'][language]}</option>
                      <option value={mktAvancadoTexts['acima1milhao'][language]}>{mktAvancadoTexts['acima1milhao'][language]}</option>
                    </select>
                    <img
                        alt="image"
                        src="/arrowdropdown.svg"
                        className={styles['custom-icon']}
                    />
                  </div>
              </div>
              <div className={styles['prazo-inicio']}>
                <span className={styles['label12']}>
                {mktAvancadoTexts['label12'][language]}
                </span>
                <input
                    type="text"
                    className={styles['textinput4']}
                    value={formData.prazoImplementacao}
                    onChange={(e) => setFormData({...formData, prazoImplementacao: e.target.value})}
                  />
              </div>
            </div>
          </div>
          <div className={styles['divisor3']}></div>
          <div className={styles['experiencia-mkt']}>
            <span className={styles['text38']}>
            {mktAvancadoTexts['text38'][language]}
            </span>
            <div className={styles['projetos-anteriores']}>
              <span className={styles['label13']}>
              {mktAvancadoTexts['label13'][language]}
              </span>
              <div className={styles['radio1']}>
                <div className={styles['radio-sim1']} onClick={() => setFormData({...formData, expMktDigital: mktAvancadoTexts['text39'][language]})}>
                  <span className={styles['text39']}>{mktAvancadoTexts['text39'][language]}</span>
                  <div className={styles['button-radio2']}>
                    <div className={styles[`selecionado${formData.expMktDigital !== mktAvancadoTexts['text39'][language] ? 'N' : ''}`]}></div>
                  </div>
                </div>
                <div className={styles['radio-nao1']} onClick={() => setFormData({...formData, expMktDigital: mktAvancadoTexts['text40'][language]})}>
                  <span className={styles['text40']}>{mktAvancadoTexts['text40'][language]}</span>
                  <div className={styles['button-radio3']}>
                  <div className={styles[`selecionado${formData.expMktDigital !== mktAvancadoTexts['text40'][language] ? 'N' : ''}`]}></div>
                  </div>
                </div>
              </div>
              {formData.expMktDigital === mktAvancadoTexts['text39'][language] && (
                <textarea
                className={` ${styles['textarea1']} ${projectStyles['textarea']} `}
                value={formData.descricaoExpMktDigital}
                onChange={(e) => setFormData({...formData, descricaoExpMktDigital: e.target.value})}
              ></textarea>
              )}
            </div>
          </div>
          <div className={styles['divisor4']}></div>
          <div className={styles['expectativas-e-metas']}>
            <span className={styles['text41']}>{mktAvancadoTexts['text41'][language]}</span>
            <div className={styles['inputs3']}>
              <div className={styles['expectativas']}>
                <span className={styles['label14']}>
                {mktAvancadoTexts['label14'][language]}
                </span>
                <textarea
                  type="text"
                  className={styles['textinput7']}
                  value={formData.expectativas}
                  onChange={(e) => setFormData({...formData, expectativas: e.target.value})}
                />
              </div>
              <div className={styles['metas']}>
                <span className={styles['label15']}>
                {mktAvancadoTexts['label15'][language]}
                </span>
                <textarea
                  type="text"
                  className={styles['textinput8']}
                  value={formData.metas}
                  onChange={(e) => setFormData({...formData, metas: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className={styles['confirmacao-infos-verdadeiras']}>
            <div className={styles['button-radio4']} onClick={() => setFormData({...formData, infoVerdadeiras: !formData.infoVerdadeiras})}>
              <div className={styles[`${formData.infoVerdadeiras ? 'selecionado' : ''}`]}></div>
            </div>
            <span className={styles['text42']}>
            {mktAvancadoTexts['text42'][language]}
            </span>
          </div>
          <button className={styles['botao-agendar-consulta1']} onClick={handleSubmit}>
            <span className={styles['text43']}>{mktAvancadoTexts['text43'][language]}</span>
          </button>
        </div>
        <div className={styles['espaco-branco1']}></div>
      </div>
      <Footer></Footer>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  )
}

export default RomperMktAvancado