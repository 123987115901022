import React, { useState, useEffect, useRef, useContext } from 'react'

import Header from '../components/header'
import Footer from '../components/footer'
import styles from './sobre-nos.module.css'
import { LocalizeContext } from '../localizeContext';
import TermosDeUso from './termos-de-uso';

const sobreNosTexts = {
  'titulo':{
    pt:'Grupo Romper',
    en:'Romper Group',
    es:'Grupo Romper'
    },
  'text':{
    pt:`O Grupo Romper é uma empresa inovadora e diversificada, que
    acredita na transformação do mundo por meio da criatividade e da
    tecnologia. Nós somos apaixonados por desafiar o status quo e
    criar soluções que fazem a diferença na vida das pessoas. Fundado
    há mais de uma década por um jovem empreendedor, o Grupo Romper
    cresceu para se tornar um líder em diversas áreas, desde a
    propriedade intelectual até aplicativos e mídias sociais. Com uma
    equipe talentosa e altamente capacitada, estamos sempre prontos
    para enfrentar os desafios do mercado e nos adaptar às mudanças do
    mundo. No Grupo Romper, acreditamos que a inovação é a chave para
    o sucesso e estamos comprometidos em tornar o mundo um lugar
    melhor através da tecnologia e da criatividade.`,
    en:`The Romper Group is an innovative and diversified company that
    believes in transforming the world through creativity and
    technology. We are passionate about challenging the status quo and
    creating solutions that make a difference in people's lives.
    Founded over a decade ago by a young entrepreneur, the Romper
    Group has grown to become a leader in various fields, from
    intellectual property to apps and social media. With a talented
    and highly skilled team, we are always ready to face market
    challenges and adapt to changes in the world. At Romper Group, we
    believe that innovation is the key to success, and we are committed
    to making the world a better place through technology and
    creativity.`,
    es:`El Grupo Romper es una empresa innovadora y diversificada que
    cree en la transformación del mundo a través de la creatividad y la
    tecnología. Nos apasiona desafiar el status quo y crear soluciones
    que marquen la diferencia en la vida de las personas. Fundado hace
    más de una década por un joven emprendedor, el Grupo Romper ha
    crecido hasta convertirse en líder en diversos campos, desde la
    propiedad intelectual hasta aplicaciones y redes sociales. Con un
    equipo talentoso y altamente calificado, siempre estamos listos
    para enfrentar los desafíos del mercado y adaptarnos a los cambios
    del mundo. En el Grupo Romper, creemos que la innovación es la
    clave del éxito y estamos comprometidos en hacer del mundo un
    lugar mejor a través de la tecnología y la creatividad.`  
  },
  'titulo1':{
    pt:'Fundador',
    en:'Founder',
    es:'Fundador'  
  },
  'text02':{
    pt:`A história do Grupo Romper começa há mais de uma década, quando
    um jovem empreendedor chamado Marlon Rihayem decidiu dar vida ao
    seu sonho. Na época, Marlon trabalhava como funcionário público,
    mas sentia que sua verdadeira paixão estava no empreendedorismo.
    Com muita coragem e determinação, ele decidiu deixar o emprego
    estável e fundar sua própria empresa de publicidade, a Romper
    Publicidade.`,
    en:`The history of the Romper Group began over a decade ago when a
    young entrepreneur named Marlon Rihayem decided to bring his dream
    to life. At the time, Marlon was working as a civil servant, but he
    felt that his true passion lay in entrepreneurship. With great
    courage and determination, he decided to leave his stable job and
    found his own advertising company, Romper Advertising.`,
    es:`La historia del Grupo Romper comenzó hace más de una década
    cuando un joven emprendedor llamado Marlon Rihayem decidió dar
    vida a su sueño. En ese momento, Marlon trabajaba como funcionario
    público, pero sentía que su verdadera pasión estaba en el
    emprendimiento. Con gran valentía y determinación, decidió dejar su
    trabajo estable y fundar su propia empresa de publicidad, Romper
    Publicidad.`  
  },
  'text04':{
    pt:`Ao longo dos anos, a empresa cresceu e diversificou sua atuação,
    expandindo para novos setores como propriedade intelectual,
    desenvolvimento de aplicativos e educação. Com o tempo, a
    empresa passou a se chamar simplesmente Grupo Romper, refletindo
    a ampla gama de serviços que oferece.`,
    en:`Over the years, the company has grown and diversified its
    operations, expanding into new sectors such as intellectual
    property, app development, and education. Over time, the company
    came to be known simply as the Romper Group, reflecting the wide
    range of services it offers.`,
    es:`A lo largo de los años, la empresa ha crecido y diversificado
    sus operaciones, expandiéndose a nuevos sectores como la propiedad
    intelectual, el desarrollo de aplicaciones y la educación. Con el
    tiempo, la empresa pasó a conocerse simplemente como el Grupo
    Romper, reflejando la amplia gama de servicios que ofrece.`
  },
  'text06':{
    pt:`Hoje, o Grupo Romper é reconhecido como um dos principais
    players do mercado em que atua, graças ao comprometimento de sua
    equipe e à visão empreendedora de seu fundador. A história do
    Grupo Romper é marcada por desafios superados e conquistas
    alcançadas, e continuamos a buscar novas formas de inovar e
    transformar o mundo ao nosso redor.`,
    en:`Today, the Romper Group is recognized as one of the key players
    in the market in which it operates, thanks to the commitment of its
    team and the entrepreneurial vision of its founder. The history of
    the Romper Group is marked by challenges overcome and achievements
    reached, and we continue to seek new ways to innovate and transform
    the world around us.`,
    es:`Hoy en día, el Grupo Romper es reconocido como uno de los
    actores clave en el mercado en el que opera, gracias al compromiso
    de su equipo y la visión emprendedora de su fundador. La historia
    del Grupo Romper está marcada por desafíos superados y logros
    alcanzados, y continuamos buscando nuevas formas de innovar y
    transformar el mundo que nos rodea.`  
  },
  'titulo2':{
    pt:`Inovação`,
    en:'Innovation',
    es:'Innovación'
    },
  'text10':{
    pt:`O Grupo Romper é uma empresa inovadora e que está sempre se
    atualizando para oferecer as melhores soluções para seus clientes.
    Somos uma empresa flexível e aberta às mudanças do mercado, o que
    nos permite surfar nas ondas da inovação. Acreditamos que a
    constante evolução é a chave para o sucesso e por isso investimos
    constantemente em tecnologia e em uma equipe altamente capacitada.
    Estamos sempre prontos para encarar novos desafios e para
    desenvolver soluções que possam agregar valor para a vida das
    pessoas.`,
    en:`Romper Group is an innovative company that is always updating
    itself to offer the best solutions to its clients. We are a
    flexible company that is open to market changes, allowing us to
    ride the waves of innovation. We believe that constant evolution is
    the key to success, and that's why we invest continuously in
    technology and a highly skilled team. We are always ready to face
    new challenges and develop solutions that can add value to people's
    lives.`,
    es:`El Grupo Romper es una empresa innovadora que siempre se está
    actualizando para ofrecer las mejores soluciones a sus clientes.
    Somos una empresa flexible y abierta a los cambios del mercado, lo
    que nos permite navegar las olas de la innovación. Creemos que la
    evolución constante es la clave del éxito, por eso invertimos
    constantemente en tecnología y en un equipo altamente capacitado.
    Siempre estamos listos para enfrentar nuevos desafíos y desarrollar
    soluciones que puedan agregar valor a la vida de las personas.`
    },
  'titulo3':{
    pt:'Missão',
    en:'Mission',
    es:'Misión'
    },
  'text11':{
    pt:`Transformar o mundo por meio da tecnologia e da criatividade,
    gerando impacto positivo na vida das pessoas.`,
    en:`To transform the world through technology and creativity,
    generating a positive impact in people's lives.`,
    es:`Transformar el mundo a través de la tecnología y la creatividad,
    generando un impacto positivo en la vida de las personas.`
    },
  'titulo4':{
    pt:'Visão',
    en:'Vision',
    es:'Visión'
    },
  'text12':{
    pt:`Ser uma empresa líder em inovação e tecnologia, reconhecida
    por suas soluções criativas e por seu compromisso em melhorar
    a vida das pessoas`,
    en:`To be a leading company in innovation and technology, recognized
    for its creative solutions and commitment to improving people's
    lives.`,
    es:`Ser una empresa líder en innovación y tecnología, reconocida por
    sus soluciones creativas y su compromiso de mejorar la vida de las
    personas.`
    },
  'titulo5':{
    pt:'Valores',
    en:'Values',
    es:'Valores'
    },
  'text16':{
    pt:`Comprometimento, paixão pela inovação, trabalho em equipe,
    respeito à diversidade e responsabilidade social.`,
    en:`Commitment, passion for innovation, teamwork, respect for
    diversity, and social responsibility.`,
    es:`Compromiso, pasión por la innovación, trabajo en equipo, respeto
    a la diversidad y responsabilidad social.`
    },
  'text19':{
    pt:`No que diz respeito ao desempenho, ao compromisso, ao
    esforço, à dedicação, não existe meio termo. Ou você faz uma coisa
    bem feita, ou não faz.`,
    en:`When it comes to performance, commitment, effort, dedication,
    there is no middle ground. Either you do something well, or you
    don't.`,
    es:`En lo que respecta al rendimiento, el compromiso, el esfuerzo,
    la dedicación, no hay término medio. O haces algo bien, o no lo
    haces.`
    }
}

const SobreNos = (props) => {
  const { language } = useContext(LocalizeContext);

  const [headerType,setHeaderType] = useState(2);
  const bannerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!bannerRef.current) return;

      const ffh = bannerRef.current.offsetHeight;
      const scrollPosition = window.scrollY;
      if ((scrollPosition + 71.79) > ffh) {
        setHeaderType(1);
      } else {
        setHeaderType(2);
      }
    };
    
    handleScroll()
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={styles['container']}>
      <Header fixed={true} TipoHeader={headerType}></Header>
      <div ref={bannerRef} className={styles['banner']}></div>
      <div className={styles['conteudo']}>
        <div className={styles['grupo-romper']}>
          <div className={styles['img-mobile']}>
            <img
              alt="image"
              src="/imggruporomper-1500w.png"
              className={styles['img']}
            />
          </div>
          <div className={styles['infos']}>
            <span className={styles['titulo']}>{TermosDeUso}</span>
            <span className={styles['text']}>
              {sobreNosTexts['text'][language]}
            </span>
          </div>
          <div className={styles['img-desk']}>
            <img
              alt="image"
              src="/imggruporomper-1500w.png"
              className={styles['img1']}
            />
          </div>
        </div>
        <div className={styles['fundador']}>
          <div className={styles['img2']}>
            <img
              alt="image"
              src="/imgfundador-400h.png"
              className={styles['img3']}
            />
          </div>
          <div className={styles['infos1']}>
            <span className={styles['titulo1']}>{sobreNosTexts['titulo1'][language]}</span>
            <span className={styles['text01']}>
              <span className={styles['text02']}>
              {sobreNosTexts['text02'][language]}
              </span>
              <br className={styles['text03']}></br>
              <span className={styles['text04']}>
              {sobreNosTexts['text04'][language]}
              </span>
              <br className={styles['text05']}></br>
              <span className={styles['text06']}>
              {sobreNosTexts['text06'][language]}
              </span>
            </span>
          </div>
        </div>
        <div className={styles['inovacao']}>
          <div className={styles['img-mobile1']}>
            <img
              alt="image"
              src="/compliance/8101573b-4f08-4c2a-87a6-182a66fa8a30-500h.svg"
              className={styles['img4']}
            />
          </div>
          <div className={styles['infos2']}>
            <span className={styles['titulo2']}>{sobreNosTexts['titulo2'][language]}</span>
            <span className={styles['text10']}>
            {sobreNosTexts['text10'][language]}
            </span>
          </div>
          <div className={styles['img-desk1']}>
            <img
              alt="image"
              src="/compliance/8101573b-4f08-4c2a-87a6-182a66fa8a30-500h.svg"
              className={styles['img5']}
            />
          </div>
        </div>
        <div className={styles['container1']}>
          <div className={styles['cards']}>
            <div className={styles['missao']}>
              <div className={styles['icon']}>
                <img
                  alt="image"
                  src="/iconmissao.svg"
                  className={styles['image']}
                />
              </div>
              <span className={styles['titulo3']}>{sobreNosTexts['titulo3'][language]}</span>
              <span className={styles['text11']}>
              {sobreNosTexts['text11'][language]}
              </span>
            </div>
            <div className={styles['visao']}>
              <div className={styles['icon1']}>
                <img
                  alt="image"
                  src="/iconvisao.svg"
                  className={styles['image1']}
                />
              </div>
              <span className={styles['titulo4']}>{sobreNosTexts['titulo4'][language]}</span>
              <span className={styles['text12']}>
                <span>
                {sobreNosTexts['text12'][language]}
                </span>
                <br className={styles['text14']}></br>
                <br className={styles['text15']}></br>
              </span>
            </div>
            <div className={styles['valores']}>
              <div className={styles['icon2']}>
                <img
                  alt="image"
                  src="/iconvalores.svg"
                  className={styles['image2']}
                />
              </div>
              <span className={styles['titulo5']}>{sobreNosTexts['titulo5'][language]}</span>
              <span className={styles['text16']}>
                <span>
                {sobreNosTexts['text16'][language]}
                </span>
                <br className={styles['text18']}></br>
              </span>
            </div>
          </div>
          <div className={styles['frase']}>
            <span className={styles['text19']}>
              &quot;{sobreNosTexts['text19'][language]}&quot;
            </span>
            <span className={styles['text20']}>Ayrton Senna</span>
          </div>
        </div>
        <div className={styles['espaco-branco']}></div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default SobreNos