import React, { useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.module.css'
import Compliance from './views/compliance'
import RomperMktAvancado from './views/romper-mkt-avancado'
import CanaisAtendimento from './views/canais-atendimento'
import Home from './views/home'
import SobreNos from './views/sobre-nos'
import TermosDeUso from './views/termos-de-uso'
import PoliticaDePrivacidade from './views/politica-privacidade'
import VoltarTopo from './components/voltar-topo'
import ScrollToTop from './components/scroll-to-top'
import { LocalizeProvider } from './localizeContext';
import { LocalizeContext } from './localizeContext';

const withScrollToTop = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return <WrappedComponent {...props} />;
  };
};

const ComplianceWithScroll = withScrollToTop(Compliance);
const RomperMktAvancadoWithScroll = withScrollToTop(RomperMktAvancado);
const CanaisAtendimentoWithScroll = withScrollToTop(CanaisAtendimento);
const HomeWithScroll = withScrollToTop(Home);
const SobreNosWithScroll = withScrollToTop(SobreNos);
const TermosUsoWithScroll = withScrollToTop(TermosDeUso);
const PoliticaPrivacidadeWithScroll = withScrollToTop(PoliticaDePrivacidade);

const routesLang = {
  'canais-atendimento': {
    pt:'/canais-atendimento',
    es:'/canales-de-atencion',
    en:'/customer-service-channels'
  },
  'sobre-nos': {
    pt:'/sobre-nos',
    es:'/sobre-nosotros',
    en:'/about-us'
  },
  'termos-de-uso': {
    pt:'/termos-de-uso',
    es:'/terminos-de-uso',
    en:'/terms-of-use'
  },
  'politica-de-privacidade': {
    pt:'/politica-de-privacidade',
    es:'/politica-de-privacidad',
    en:'/privacy-policy'
  },
  'romper-mkt-avancado': {
    pt:'/romper-mkt-avancado',
    es:'/romper-marketing-avanzado',
    en:'/romper-advanced-marketing'
  },
  'compliance': {
    pt:'/compliance',
    es:'/cumplimiento',
    en:'/compliance'
  },
}

const App = () => {
  const { language } = useContext(LocalizeContext);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);
  
  return (
    <Router>
      <ScrollToTop />
      <VoltarTopo />
      <Switch>
        <Route component={ComplianceWithScroll} exact path={routesLang['compliance'][language]} />
        <Route
          component={RomperMktAvancadoWithScroll}
          exact
          path={routesLang['romper-mkt-avancado'][language]}
        />
        <Route component={CanaisAtendimentoWithScroll} exact path={routesLang['canais-atendimento'][language]} />
        <Route component={HomeWithScroll} exact path="/" />
        <Route component={SobreNosWithScroll} exact path={routesLang['sobre-nos'][language]} />
        <Route component={TermosUsoWithScroll} exact path={routesLang['termos-de-uso'][language]} />
        <Route component={PoliticaPrivacidadeWithScroll} exact path={routesLang['politica-de-privacidade'][language]} />
      </Switch>
    </Router>
  )
}

ReactDOM.render(
  <LocalizeProvider>
    <App />
  </LocalizeProvider>,
  document.getElementById('app')
);