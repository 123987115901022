import React, { useState, useEffect, useRef } from 'react'

import styles from './navegacao-marcas.module.css'

const NavegacaoMarcas = (props) => {
  
  const [DireitoAutoralHovered, setDireitoAutoralHovered] = useState(false)
  const [DiskChoppHovered, setDiskChoppHovered] = useState(false)
  const [RomperMktAvancadoHovered, setRomperMktAvancadoHovered] =
    useState(false)
  const [PersonalTrainerHovered, setPersonalTrainerHovered] = useState(false)
  const [PodlocarHovered, setPodlocarHovered] = useState(false)
  const [RegistroPopularHovered, setRegistroPopularHovered] = useState(false)
  const [TrekBoxHovered, setTrekBoxHovered] = useState(false)
  const [dark,setDark] = useState([])

  const navRef = useRef(null);

  const scrollToMarca = (ref) => {
    if (ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 100;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
        if (!props.bannerRef.current) return;
  
        const ffh = props.bannerRef.current.offsetHeight - 30;
        const children = navRef.current.children;
        const darkList = Array.from(children).map((elem, i) => {
          const scrollDistance = window.pageYOffset || document.documentElement.scrollTop;
          const elemDistance = elem.getBoundingClientRect().top;
          let elemId;
          switch(i) {
            case 0:
            elemId = 'registro-popular'
            break;
    
            case 1:
            elemId = 'direitos-autorais'
            break;

            case 2:
            elemId = 'personal-trainer'
            break;

            case 3:
            elemId = 'trek-box'
            break;

            case 4:
            elemId = 'romper-mkt'
            break;

            case 5:
            elemId = 'disk-chopp'
            break;

            case 6:
            elemId = 'podlocar'
            break;
          }

          if(scrollDistance + elemDistance > ffh) {
            return elemId;
          }
        });
        setDark(darkList)
    };
    
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={` ${styles['container']} `}>
      <div ref={navRef} className={styles['navegacao-marcas-dark']}>
          <div
            onMouseEnter={() => setRegistroPopularHovered(true)}
            onMouseLeave={() => setRegistroPopularHovered(false)}
            onClick={() => scrollToMarca(props?.registroPopularRef)}
            className={styles['botao-registro-popular']}
          >
            {dark.includes("registro-popular") ? (
              <img
              alt="image"
              src="/marcas/icondarkregistropopular.svg"
              className={styles['icon07']}
            />
            ) : (
              <img
              alt="image"
              src="/marcas/iconregistropopular.svg"
              className={styles['icon']}
            />
            )} 
          </div>
          <div
            onMouseEnter={() => setDireitoAutoralHovered(true)}
            onMouseLeave={() => setDireitoAutoralHovered(false)}
            onClick={() => scrollToMarca(props?.direitoAutoralRef)}
            className={styles['botao-direito-autoral']}
          >
            {dark.includes("direitos-autorais") ? (
              <img
              alt="image"
              src="/marcas/icondarkdireitosautorais.svg"
              className={styles['icon08']}
            />
            ) : (
              <img
              alt="image"
              src="/marcas/icondireitosautorais.svg"
              className={styles['icon01']}
            />
            )}
          </div>
          <div
            onMouseEnter={() => setPersonalTrainerHovered(true)}
            onMouseLeave={() => setPersonalTrainerHovered(false)}
            onClick={() => scrollToMarca(props?.personalTrainerRef)}
            className={styles['botao-personal-trainer']}
          >
            {dark.includes("personal-trainer") ? (
              <img
              alt="image"
              src="/marcas/icondarkpersonaltrainer.svg"
              className={styles['icon09']}
            />
            ) : (
              <img
              alt="image"
              src="/marcas/iconpersonaltrainer.svg"
              className={styles['icon02']}
            />
            )}
          </div>
          <div
            onMouseEnter={() => setTrekBoxHovered(true)}
            onMouseLeave={() => setTrekBoxHovered(false)}
            onClick={() => scrollToMarca(props?.trekBoxRef)}
            className={styles['botao-trek-box']}
          >
            {dark.includes("trek-box") ? (
              <img
              alt="image"
              src="/marcas/icondarktrekbox.svg"
              className={styles['icon10']}
            />
            ) : (
              <img
              alt="image"
              src="/marcas/icontrekbox.svg"
              className={styles['icon03']}
            />
            )}
          </div>
          <div
            onMouseEnter={() => setRomperMktAvancadoHovered(true)}
            onMouseLeave={() => setRomperMktAvancadoHovered(false)}
            onClick={() => scrollToMarca(props?.romperMktAvancadoRef)}
            className={styles['botao-romper-mkt-avancado']}
          >
            {dark.includes("romper-mkt") ? (
              <img
              alt="image"
              src="/marcas/icondarkrompermkt.svg"
              className={styles['icon11']}
            />
            ) : (
              <img
              alt="image"
              src="/marcas/iconrompermkt.svg"
              className={styles['icon04']}
            />
            )}
          </div>
          <div
            onMouseEnter={() => setDiskChoppHovered(true)}
            onMouseLeave={() => setDiskChoppHovered(false)}
            onClick={() => scrollToMarca(props?.diskChoppRef)}
            className={styles['botao-disk-chopp']}
          >
            {dark.includes('disk-chopp') ? (
              <img
              alt="image"
              src="/marcas/icondarkdiskchopp.svg"
              className={styles['icon12']}
            />
            ) : (
              <img
              alt="image"
              src="/marcas/icondiskchopp.svg"
              className={styles['icon05']}
            />
            )}   
          </div>
          <div
            onMouseEnter={() => setPodlocarHovered(true)}
            onMouseLeave={() => setPodlocarHovered(false)}
            onClick={() => scrollToMarca(props?.podlocarRef)}
            className={styles['botao-podlocar']}
          >
            {dark.includes("podlocar") ? (
              <img
              alt="image"
              src="/marcas/icondarkpodlocar.svg"
              className={styles['icon13']}
            />
            ) :(
              <img
              alt="image"
              src="/marcas/iconpodlocar.svg"
              className={styles['icon06']}
            />
            )}
          </div>
        </div>
      {RegistroPopularHovered && (
        <div className={styles['registro-popular-hover']}>
          <span style={ dark.includes('registro-popular') ? { color: '#2D3E50' } : {} } className={styles['text']}>
            <span>Registro Popular</span>
            <br></br>
          </span>
        </div>
      )}
      {DireitoAutoralHovered && (
        <div className={styles['direito-autoral-hover']}>
          <span style={ dark.includes('direitos-autorais') ? { color: '#2D3E50' } : {} } className={styles['text03']}>
            <span>direitoautoral.com</span>
            <br></br>
          </span>
        </div>
      )}
      {PersonalTrainerHovered && (
        <div className={styles['personal-trainer-hover']}>
          <span style={ dark.includes('personal-trainer') ? { color: '#2D3E50' } : {} } className={styles['text06']}>Personaltrainer.com.br</span>
        </div>
      )}
      {TrekBoxHovered && (
        <div className={styles['trek-box-hover']}>
          <span style={ dark.includes('trek-box') ? { color: '#2D3E50' } : {} } className={styles['text07']}>
            <span>Trekbox</span>
            <br></br>
          </span>
        </div>
      )}
      {RomperMktAvancadoHovered && (
        <div className={styles['romper-mkt-avancado-hover']}>
          <span style={ dark.includes('romper-mkt') ? { color: '#2D3E50' } : {} } className={styles['text10']}>
            <span>Romper Marketing Avançado</span>
            <br></br>
          </span>
        </div>
      )}
      {DiskChoppHovered && (
        <div className={styles['disk-chopp-hover']}>
          <span style={ dark.includes('disk-chopp') ? { color: '#2D3E50' } : {} } className={styles['text13']}>Disk Chopp</span>
        </div>
      )}
      {PodlocarHovered && (
        <div className={styles['podlocar-hover']}>
          <span style={ dark.includes('podlocar') ? { color: '#2D3E50' } : {} } className={styles['text14']}>Podlocar</span>
        </div>
      )}
    </div>
  )
}

export default NavegacaoMarcas