import React, { useEffect, useState, useContext } from 'react'
import { LocalizeContext } from '../localizeContext';

import styles from './voltar-topo.module.css'

const voltarAoTopoTexts = {
  'text29': {
    pt: `Voltar ao topo`,
    en: `Back to top`,
    es: `Volver arriba`
  }
};

const VoltarTopo = (props) => {
    const { language, setLanguage } = useContext(LocalizeContext);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const checkScrollTop = () => {
          if (!showButton && window.pageYOffset > 750){
            setShowButton(true);
          } else if (showButton && window.pageYOffset <= 750){
            setShowButton(false);
          }
        };
      
        window.addEventListener('scroll', checkScrollTop);
        return () => {
          window.removeEventListener('scroll', checkScrollTop);
        };
      }, [showButton]);
      

    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };

  return (
    <div>
        {showButton && (
            <button style={{cursor:'pointer'}} className={styles['botao-voltar-topo']} onClick={scrollToTop}>
            <span className={styles['text29']}>
              <span>{voltarAoTopoTexts['text29'][language]}</span>
            </span>
            <img
              alt="image"
              src="/arrowtop.svg"
              className={styles['iconarrowtop']}
            />
          </button>
        )}
    </div>
  )
}

export default VoltarTopo